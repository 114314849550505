<template>
  <div>
    <a-card>
      <a-button type="primary" @click="add()">新增版本升级</a-button>
      <!-- 版本升级列表 -->
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="dataList"
        :rowKey="record => record.version_id"
        tableLayout="fixed"
        :pagination="{
          current: searchParams.page,
          pageSize: searchParams.limit,
          total: total,
          showLessItems: true,
          showQuickJumper: true,
          showTotal: (total, range) => `总计 ${total} 条`,
          onChange: onPageChange,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          onShowSizeChange: onPageChange,
        }">
        <template slot="action" slot-scope="text, record">
          <a @click="edit(record.version_id)" style="margin-right: 10px">编辑</a>
          <a-popconfirm title="确定删除该版本升级吗?" ok-text="是" cancel-text="否" @confirm="confirm(record.version_id)">
            <a class="error-color">删除</a>
          </a-popconfirm>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { versionList, versionDel } from "@/views/api/system";
import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
      form: {
        status: 0,
      },
      modalText: "",
      searchParams: {
        limit: 10
      },
      columns: [
        {
          title: "版本ID",
          dataIndex: "version_id",
          width: 80,
        },
        {
          title: "外部版本号",
          dataIndex: "version_code",
          width: 160,
        },
        {
          title: "内部版本号",
          dataIndex: "version",
          width: 120,
        },
        {
          title: "升级内容",
          dataIndex: "update_desc",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          width: 120,
        },
        {
          title: "强制升级",
          dataIndex: "force_update",
          customRender: text => {
            return text == 1 ? "是" : "否";
          },
          width: 100,
        },
        {
          title: "状态",
          dataIndex: "status",
          customRender: text => {
            return text == 1 ? "正常" : "禁用";
          },
          width: 80,
        },
        {
          title: "操作",
          scopedSlots: {
            customRender: "action",
          },
          width: 120,
        },
      ],
    };
  },
  computed: {},
  methods: {
    getList() {
      this.loading = true;
      versionList(this.searchParams).then(res => {
        this.dataList = res.data.list;
        this.total = res.data.count;
        this.loading = false;
      });
    },

    // 删除
    confirm(version_id) {
      versionDel({ version_id }).then(res => {
        if (res.code == 200) {
          this.$message.success("删除成功");
          this.getList();
        }
      });
    },

    add() {
      this.$router.push({
        path: `/addVersion`,
      });
    },
    // 添加编辑跳转
    edit(version_id) {
      this.$router.push({
        path: `/addVersion`,
        query: { version_id: version_id },
      });
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="less" scoped></style>
