<template>
  <div>
    <a-card>
      <a-row style="margin-bottom: 10px">
        商户信息:
        <a-input placeholder="请输入商户id/商户名称" v-model="searchParams.seller_keywords" style="width: 220px; margin-right: 20px" @change="$forceUpdate()"></a-input>

        门店信息:
        <a-input placeholder="请输入门店id/门店名称" v-model="searchParams.store_keywords" style="width: 220px; margin-right: 20px"></a-input>

        订单状态:
        <a-select allowClear placeholder="请选择订单状态" v-model="searchParams.pay_status" style="width: 100px; margin-right: 20px">
          <a-select-option :value="1">已支付</a-select-option>
          <a-select-option :value="0">未支付</a-select-option>
        </a-select>

        <a-select allowClear placeholder="请选择" v-model="order_type" style="width: 100px">
          <a-select-option :value="1">续费单号</a-select-option>
          <a-select-option :value="2">支付流水号</a-select-option>
        </a-select>
        <a-input placeholder="请输入" v-model="order_id" style="width: 220px; margin-right: 20px"></a-input>
        <br />

        销售类型:
        <a-select allowClear placeholder="请选择销售类型" v-model="searchParams.sale_type" style="width: 180px; margin: 10px 10px 0 0">
          <a-select-option :value="1">云果直销</a-select-option>
          <a-select-option :value="2">代理商直销</a-select-option>
        </a-select>

        <a-select allowClear v-if="searchParams.sale_type == 2" placeholder="请选择" mode="multiple" v-model="searchParams.agent_ids" style="margin: 0 20px 20px 0; width: 150px">
          <a-select-option v-for="item in agent_ids_list" :key="item.agent_id" :value="item.agent_id"> {{ item.agent_name }} </a-select-option>
        </a-select>

        支付时间:
        <a-select v-model="ranges" :allowClear="false" style="width: 100px; display: inline-block" placeholder="请选择" @change="rangeChange">
          <a-select-option :value="1"> 今天 </a-select-option>
          <a-select-option :value="2"> 昨天 </a-select-option>
          <a-select-option :value="4"> 近七天 </a-select-option>
          <a-select-option :value="5"> 本周 </a-select-option>
          <a-select-option :value="6"> 上周 </a-select-option>
          <a-select-option :value="7"> 本月 </a-select-option>
          <a-select-option :value="8"> 上月 </a-select-option>
          <a-select-option :value="-2"> 自定义 </a-select-option>
        </a-select>
        <a-date-picker :locale="locale" :allowClear="false" v-model="start_time" placeholder="请选择" @change="onStartChange" style="width: 130px" />
        <span style="width: 10px; margin: 0 4px">-</span>
        <a-date-picker :locale="locale" :allowClear="false" v-model="end_time" placeholder="请选择" @change="onEndChange" style="width: 130px; margin-right: 20px" />

        <a-button @click="getList(true)" style="margin-left: 10px">查询</a-button>
      </a-row>

      <a-button @click="exportList" type="primary" style="margin-bottom: 10px">导出</a-button>

      <!-- <a-table
        style="padding: 0"
        :loading="loading"
        size="small"
        :columns="columns"
        :data-source="dataList"
        :rowKey="record => record.path_id"
        :pagination="{
          current: searchParams.page,
          pageSize: searchParams.limit,
          total: total,
          showLessItems: true,
          showQuickJumper: true,
          showTotal: (total, range) => `总计 ${total} 条`,
          onChange: onPageChange,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          onShowSizeChange: onPageChange,
        }">
        <template slot="action" slot-scope="text, record">
          <a v-if="record.pay_status == 0" @click="edit(record)" style="margin-right: 10px">修改</a>
        </template>
      </a-table>

      <div style="font-size: 18px; font-weight: 500">所有页合计: {{ total_amount }}</div> -->

      <!-- 列表 -->
      <vxe-table size="small" class="mytable-footer" show-header-overflow border :data="dataList" show-footer :footer-method="footerMethod" :column-config="{ resizable: true }" auto-resize>
        <vxe-column type="seq" title="序号" width="60"></vxe-column>
        <vxe-column v-for="(item, i) in columns" :key="i" :field="item.dataIndex" :title="item.title" :width="item.width ? item.width : '120'">
          <template #default="{ row }">
            <div v-if="item.dataIndex == 'agent_id'">{{ row[item.dataIndex] == 0 ? "云果直销" : `代理商直销/${row.agent_name}` }}</div>
            <div v-else-if="item.dataIndex == 'store_id' || item.dataIndex == 'store_name' || item.dataIndex == 'expire_time'">
              <a v-if="row.store_infos.length > 1" @click="showDalog(row.store_infos, row.seller_id, row.seller_name)">查看更多</a>
              <div v-else-if="row.store_infos[0]">
                <span v-if="item.dataIndex == 'store_id'">{{ row.store_infos[0].store_id }}</span>
                <span v-if="item.dataIndex == 'store_name'">{{ row.store_infos[0].store_name }}</span>
                <span v-if="item.dataIndex == 'expire_time'">{{ row.store_infos[0].expire_time }}</span>
              </div>
            </div>
            <div v-else-if="item.dataIndex == 'action'"><a v-if="row.pay_status == 0" @click="edit(row)" style="margin-right: 10px">修改</a></div>
            <div v-else-if="item.dataIndex == 'pay_source'">{{ row[item.dataIndex] == 1 ? "boss后台" : row[item.dataIndex] == 2 ? "管理后台" : "pos收银" }}</div>
            <div v-else-if="item.dataIndex == 'pay_status'">{{ row[item.dataIndex] == 1 ? "已支付" : "未支付" }}</div>
            <div v-else-if="item.dataIndex == 'operator_name'">
              {{ row.operator_name }}
              <span v-if="row.operator_unique_id">({{ row.operator_unique_id }})</span>
            </div>
            <div v-else>{{ row[item.dataIndex] }}</div>
          </template>
        </vxe-column>
      </vxe-table>

      <vxe-pager
        :current-page.sync="searchParams.page"
        :page-size.sync="searchParams.limit"
        :total="total"
        @page-change="onPageChange"
        :layouts="['PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
      </vxe-pager>
    </a-card>

    <a-modal v-model="visible" :title="`${modalText}续费`" @ok="handleOk" @cancel="handleCancel">
      <a-form-model ref="ruleForm" :form="form" :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16, offset: 1 }">
        <a-form-model-item label="续费门店">
          {{ store_num }}
        </a-form-model-item>

        <a-form-model-item label="续费时间" v-if="renewal_model == 1">
          <a-select placeholder="请选择续费时间" v-model="form.renew_time">
            <a-select-option :value="12">12个月</a-select-option>
            <a-select-option :value="24">24个月</a-select-option>
            <a-select-option :value="36">36个月</a-select-option>
            <a-select-option :value="60">60个月</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="门店续费期限统一截止时间" v-if="renewal_model == 2">
          <a-date-picker v-model="form.uniform_date" />
        </a-form-model-item>

        <a-form-model-item label="续费金额" prop="name">
          <a-input placeholder="请输入续费金额" v-model="form.renew_money" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal v-model="list_visible" title="查看信息" width="60%" @ok="list_visible = false">
      <vxe-table size="small" show-header-overflow border :data="detail_list">
        <vxe-column field="store_id" title="门店ID"> </vxe-column>
        <vxe-column field="store_name" title="门店名称"> </vxe-column>
        <vxe-column field="seller_id" title="商户ID"> </vxe-column>
        <vxe-column field="seller_name" title="商户名称"> </vxe-column>
        <vxe-column field="create_time" title="门店创建时间"> </vxe-column>
        <vxe-column field="expire_time" title="续费到期时间"> </vxe-column>
      </vxe-table>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import zhCn from "ant-design-vue/es/date-picker/locale/zh_CN";
import "moment/dist/locale/zh-cn";
import { SellerRenewlist, SellerRenewedit, agentlist, SellerRenewexportList } from "@/views/api/seller";
import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins],
  data() {
    return {
      locale: zhCn,
      ranges: -2,
      start_time: null,
      end_time: null,
      list_visible: false,
      detail_list: [],
      form: {},
      modalText: "",
      columns: [
        {
          title: "续费单号",
          dataIndex: "order_id",
          width: 200,
        },
        {
          title: "商户ID",
          dataIndex: "seller_id",
        },
        {
          title: "商户名称",
          dataIndex: "seller_name",
          width: 180,
        },
        {
          title: "门店ID",
          dataIndex: "store_id",
        },
        {
          title: "门店名称",
          dataIndex: "store_name",
        },
        {
          title: "销售类型",
          dataIndex: "agent_id",
          width: 200
        },
        {
          title: "续费门店数量",
          dataIndex: "store_num",
        },
        {
          title: "订单续费金额",
          dataIndex: "amount",
        },
        {
          title: "订单状态",
          dataIndex: "pay_status",
        },
        {
          title: "订单创建时间",
          dataIndex: "create_time",
          width: 160,
        },
        {
          title: "续费到期时间",
          dataIndex: "expire_time",
          width: 160,
        },
        {
          title: "支付方式",
          dataIndex: "pay_type_name",
          width: 160,
        },

        {
          title: "收银机器",
          dataIndex: "machine_id",
        },
        
        {
          title: "支付来源",
          dataIndex: "pay_source",
        },
        {
          title: "第三方订单号",
          dataIndex: "trade_no",
        },
        {
          title: "支付时间",
          dataIndex: "pay_time",
          width: 160,
        },
        {
          title: "操作人",
          dataIndex: "operator_name",
          width: 180,
        },
        {
          title: "操作时间",
          dataIndex: "operate_time",
          width: 160,
        },
        {
          title: "操作",
          dataIndex: "action",
        },
      ],

      selectedRowKeys: [],
      selectedRows: [],
      seller: {},

      store: [],
      store_id_list: [],
      pay_type_list: [],

      seller_id: "",
      store_id: '',
      store_num: "",
      renewal_model: "",
      agent_ids_list: [],
      total_amount: "",
      order_type: 1,
      order_id: "",

      foot_data: [],
    };
  },

  created() {
    this.seller_id = this.$route.query.seller_id;
    this.store_id = this.$route.query.store_id;
    
    if (this.$route.query.seller_id) {
      this.searchParams.seller_keywords = JSON.parse(JSON.stringify(this.$route.query.seller_id));
      this.searchParams.store_keywords = JSON.parse(JSON.stringify(this.$route.query.store_id));
    }
    this.getList();
    this.agentlist();
  },

  methods: {
    getList(is_search) {
      this.loading = true;
      if (is_search) this.searchParams.page = 1;
      if (this.order_type == 2) this.searchParams.trade_no = this.order_id;
      if (this.order_type == 1) this.searchParams.order_id = this.order_id;
      SellerRenewlist(this.searchParams).then(res => {
        this.dataList = res.data.list;
        this.total = res.data.count;
        this.total_amount = res.data.total.amount;
        this.loading = false;
      });
    },

    footerMethod() {
      this.foot_data = ["汇总", "", "", "", "", "", "", "", this.total_amount];
      return [this.foot_data];
    },

    showDalog(list, seller_id, seller_name) {
      this.list_visible = true;
      list.forEach(item => {
        item.seller_id = seller_id;
        item.seller_name = seller_name;
      });
      this.detail_list = list;
    },

    onPageChange({ currentPage, pageSize }) {
      this.searchParams.page = currentPage;
      this.searchParams.limit = pageSize;
      this.getList();
    },

    agentlist() {
      if (this.agent_ids_list.length != 0) return;
      agentlist({ page: 0, limit: 0 }).then(res => {
        this.agent_ids_list = res.data.list;
        this.loading = false;
      });
    },

    // 确认
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) return this.addEdit();
        return false;
      });
    },

    // 添加编辑
    addEdit() {
      SellerRenewedit(this.form).then(res => this.handleSuccess(1, res));
    },

    // 操作成功
    handleSuccess(flag, res) {
      if (res.code == 200) {
        this.$message.success(`编辑成功`);
        this.visible = false;
        this.getList();
      }
    },

    // 弹框关闭
    handleCancel() {
      this.form = {};
      this.visible = false;
    },

    // 续费数据
    renewData() {},

    // 新增续费
    edit(row) {
      this.form = {};
      const { amount, renew_time, order_id } = row;
      this.form = { renew_money: amount, renew_time, order_id };
      this.store_num = row.store_num;
      this.renewal_model = row.seller_renewal_model;
      this.visible = true;
    },

    // 导出
    exportList() {
      SellerRenewexportList(this.searchParams).then(result => {
        if (result.code == 200) window.open(result.data.download_url);
      });
    },

    back() {
      this.$router.go(-1);
    },

    onStartChange(date, dateString) {
      this.start_time = moment(dateString);
      this.sendTime();
      this.ranges = -2;
    },

    onEndChange(date, dateString) {
      this.end_time = moment(dateString);
      this.sendTime();
      this.ranges = -2;
    },

    onChangetime(date, dateString) {
      this.start_time = moment(dateString[0]);
      this.end_time = moment(dateString[1]);
      this.sendTime();
    },

    sendTime() {
      if (moment(this.start_time).unix() > moment(this.end_time).unix()) return this.$message.error("开始时间不能大于结束时间");
      this.searchParams.start_time = moment(this.start_time).unix();
      this.searchParams.end_time = moment(this.end_time).unix();
      this.getList();
    },

    rangeChange() {
      if (this.ranges == -2) {
        this.start_time = undefined;
        this.end_time = undefined;
      } else {
        this.onChangetime("", this.$getdayTime(this.ranges));
      }
    },
  },
};
</script>
<style lang="less" scoped>

</style>
