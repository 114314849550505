<template>
  <div>
    <a-modal v-model="visible" title="选择机器" @ok="handleOk" :ok-button-props="{ props: { disabled: loading } }" width="70%">
      <div class="a-form-bg">
        <a-form layout="horizontal">
          <a-row>
            <a-select placeholder="请选择" v-model="seller_type">
              <a-select-option value="seller_id">商户ID</a-select-option>
              <a-select-option value="seller_name">商户名称</a-select-option>
            </a-select>
            <a-input placeholder="请输入" v-model="seller_value" style="width: 160px; margin-right: 20px"></a-input>

            <a-select placeholder="请选择" v-model="store_type">
              <a-select-option value="store_id">门店ID</a-select-option>
              <a-select-option value="store_name">门店名称</a-select-option>
            </a-select>
            <a-input placeholder="请输入" v-model="store_value" style="width: 160px; margin-right: 20px"></a-input>

            <a-select placeholder="请选择" v-model="machine_type">
              <a-select-option value="machine_id">机器ID</a-select-option>
            </a-select>
            <a-input placeholder="请输入" v-model="machine_value" style="width: 160px; margin-right: 20px"></a-input>

            <a-button @click="getList(true)" @keyup.enter.native="getList(true)" htmlType="submit">查询</a-button>
            <a style="margin-left: 8px" @click="clearParams">重置筛选条件</a>
          </a-row>
        </a-form>
      </div>

      <!-- 列表区域 -->
      <a-table
        size="small"
        style="margin-top: 10px"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onChange, type: 'checkbox' }"
        :loading="loading"
        :columns="columns"
        :data-source="data_list"
        :rowKey="record => record.auto_id"
        :pagination="{
          current: search_params.page,
          pageSize: search_params.limit,
          total: total,
          showLessItems: true,
          showQuickJumper: true,
          showTotal: (total, range) => `总计 ${total} 条`,
          onChange: onPageChange,
        }">
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { VersionGetMachine } from "@/views/api/system";
import moment from "moment";
const columns = [
  {
    title: "序号",
    dataIndex: "auto_id",
  },
  {
    title: "商户名称",
    dataIndex: "seller_name",
  },
  {
    title: "商户ID",
    dataIndex: "seller_id",
  },
  {
    title: "门店名称",
    dataIndex: "store_name",
  },
  {
    title: "门店ID",
    dataIndex: "store_id",
  },
  {
    title: "机器ID",
    dataIndex: "machine_id",
  },
  {
    title: "机器版本",
    dataIndex: "edition",
  },
];

export default {
  data() {
    return {
      visible: false,
      columns,
      data_list: [],
      loading: false,
      total: 0,
      search_params: {
        page: 1,
        limit: 10,
      },
      selectedRows: "",
      selectedRowKeys: [],

      seller_type: "seller_id",
      seller_value: "",
      store_type: "store_id",
      store_value: "",
      machine_type: "machine_id",
      machine_value: "",
    };
  },

  created() {
    this.getList();
  },

  methods: {
    moment,
    handleOk() {
      this.loading = true;
      this.$emit("func", this.selectedRows, this.selectedRowKeys);
      this.visible = false;
    },

    childMethod(machine_id_list, machine_list) {
      this.visible = true;
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.selectedRowKeys = machine_id_list;
      this.selectedRows = machine_list;
      this.clearParams();
      this.loading = false;
    },

    getList(is_search) {
      this.search_params.seller_name = "";
      this.search_params.store_name = "";
      this.search_params.machine_name = "";
      this.search_params[this.seller_type] = this.seller_value;
      this.search_params[this.store_type] = this.store_value;
      this.search_params[this.machine_type] = this.machine_value;
      if (is_search) this.search_params.page = 1;
      VersionGetMachine(this.search_params).then(res => {
        this.data_list = res.data.list;
        this.total = res.data.count;
      });
    },

    clearParams() {
      this.seller_value = "";
      this.store_value = "";
      this.machine_value = "";
      this.search_params = {};
      this.search_params.page = 1;
      this.search_params.limit = 10;
      this.search_params.seller_type = "seller_id";
      this.search_params.machine_type = "machine_id";
      this.search_params.store_type = "store_id";
      this.getList();
    },

    onPageChange(page, pageSize) {
      this.search_params.page = page;
      this.search_params.limit = pageSize;
      this.getList();
    },

    onChange(keys, rows) {
      this.selectedRowKeys = keys;
      if (keys.length == rows.length) {
        this.selectedRows = rows;
      } else {
        this.selectedRows = keys.map(key => this.selectedRows.find(row => row.auto_id == key) || rows.find(row => row.auto_id == key));
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.ant-select {
  width: 100px;
}
</style>
