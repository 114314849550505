var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"title":"选择商户","width":"70%"},on:{"ok":_vm.handleOk},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-row',{staticStyle:{"margin-top":"10px"}},[_c('a-input',{staticStyle:{"width":"200px"},attrs:{"placeholder":"商户号/商户名称/手机号"},model:{value:(_vm.searchParams.keywords),callback:function ($$v) {_vm.$set(_vm.searchParams, "keywords", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"searchParams.keywords"}}),_c('a-button',{staticStyle:{"margin-left":"10px"},on:{"click":_vm.getList}},[_vm._v("查询")]),_c('a',{staticStyle:{"margin-left":"10px"},on:{"click":_vm.clear}},[_vm._v("重置查询")])],1),_c('a-table',{staticStyle:{"margin-top":"20px"},attrs:{"row-selection":{ selectedRowKeys: _vm.selectedRowKeys, onChange: _vm.onSelectChange },"loading":_vm.loading,"columns":_vm.columns,"data-source":_vm.orderList,"rowKey":function (record) { return record.seller_id; },"pagination":{
        current: _vm.searchParams.page,
        pageSize: _vm.searchParams.limit,
        total: _vm.total,
        showLessItems: true,
        showQuickJumper: true,
        showTotal: function (total, range) { return ("总计 " + total + " 条"); },
        onChange: _vm.onPageChange,
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }