<template>
  <div>
    <a-card>
      <a-button type="primary" @click="addSeller(1)">新增商户</a-button>
      <a-row style="margin: 10px 0">
        <a-input placeholder="商户ID/商户名称/手机号" v-model="searchParams.keywords" style="width: 200px"></a-input>
        销售类型:
        <a-select placeholder="请选择销售类型" v-model="searchParams.sale_type" style="width: 180px">
          <a-select-option :value="1">云果直销</a-select-option>
          <a-select-option :value="2">代理商直销</a-select-option>
        </a-select>
        <a-select
          v-if="searchParams.sale_type == 2"
          show-search
          :filter-option="this.$filterOption"
          placeholder="请选择"
          mode="multiple"
          v-model="searchParams.agent_ids"
          style="margin: 0 20px 20px 0; width: 150px">
          <a-select-option v-for="item in agent_ids_list" :key="item.agent_id" :value="item.agent_id"> {{ item.agent_name }} </a-select-option>
        </a-select>
        <a-button @click="getList" style="margin-left: 10px">查询</a-button>
      </a-row>
      <!-- 商户列表 -->
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="dataList"
        :rowKey="record => record.seller_id"
        tableLayout="fixed"
        :pagination="{
          current: searchParams.page,
          pageSize: searchParams.limit,
          total: total,
          showLessItems: true,
          showQuickJumper: true,
          showTotal: (total, range) => `总计 ${total} 条`,
          onChange: onPageChange,
          onShowSizeChange: onPageChange,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          onShowSizeChange: onPageChange,
        }">
        <template slot="seller_id" slot-scope="text, record">
          <a :href="`https://manage.yunguos.com/user/login?seller_id=${record.seller_id}&account=17791285105`" target="_blank">{{ text }}</a>
        </template>
        <template slot="seller_name" slot-scope="text, record">
          <a :href="`https://manage.yunguos.com/user/login?seller_id=${record.seller_id}&account=17791285105`" target="_blank">{{ text }}</a>
        </template>
        <template slot="seller_login" slot-scope="text, record">
          <a :href="`https://manage.yunguos.com/user/login?seller_id=${record.seller_id}`" target="_blank">
            {{ `https://manage.yunguos.com/user/login?seller_id=${record.seller_id}` }}
          </a>
        </template>
        <template slot="status" slot-scope="text, record">
          <a-switch :checked="record.status == 1 ? true : false" @change="GoodsBrandStatus($event, record)" />
        </template>
        <template slot="action" slot-scope="text, record">
          <a-popconfirm style="margin-right: 10px" title="确定重置密码吗?" ok-text="是" cancel-text="否" @confirm="resetPwd(record.seller_id)">
            <a>重置密码</a>
          </a-popconfirm>
          <a-popconfirm style="margin-right: 10px" title="确定关闭登录短信认证吗?" ok-text="是" cancel-text="否" @confirm="closeSmsLogin(record.seller_id)">
            <a>关闭登录短信认证</a>
          </a-popconfirm>
          <a-popconfirm style="margin-right: 10px" title="确定开通该商户短信吗?" ok-text="是" cancel-text="否" @confirm="createSms(record.seller_id)" v-if="record.is_sms != 1">
            <a>开通短信</a>
          </a-popconfirm>
          <a @click="showModel(record)" style="margin-right: 10px">调整短信</a>
          <a @click="openGenerateDynamicTokenDialog(record.seller_id)" style="margin-right: 10px">动态口令</a>
          <!-- <a>开通地址</a> -->
          <a @click="addSeller(2, record)" style="margin-right: 10px">编辑</a>
          <a-popconfirm style="margin-right: 10px" title="确定删除该商户吗?" ok-text="是" cancel-text="否" @confirm="confirm(record.seller_id)">
            <a class="error-color">删除</a>
          </a-popconfirm>
        </template>
      </a-table>
    </a-card>

    <!-- 动态口令 -->
    <a-modal v-model="token_visible" title="动态口令" @ok="handleOk"> 请牢记动态口令{{ token }},重新生成前,有效时间10分钟 </a-modal>

    <a-modal v-model="visible" title="调整短信" @ok="smsNum" @cancel="handleCancel">
      <a-form-model v-if="sms_sign" ref="ruleForm" :form="form" :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 14, offset: 1 }">
        <!-- <a-form-model-item label="短信账户" prop="amount"> </a-form-model-item> -->
        <a-form-model-item label="短信签名" prop="sms_sign">
          {{ sms_sign }}
        </a-form-model-item>
        <a-form-model-item label="选择产品" prop="amount">
          <a-radio-group v-model="is_open">
            <a-radio :value="1">通知类短信</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="账户可用额度" prop="sms_num">
          {{ form.sms_num }}
        </a-form-model-item>
        <a-form-model-item label="操作类型" prop="type">
          <a-radio-group v-model="form.type">
            <a-radio :value="1">充值</a-radio>
            <a-radio :value="2">转回</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="操作条数" prop="number">
          <a-input-number v-model.trim="form.number" :min="1" :precision="0" placeholder="请输入操作条数" style="width: 100%"></a-input-number>
        </a-form-model-item>
        <a-form-model-item label="备注" prop="reason">
          <a-textarea placeholder="请输入原因" v-model.trim="form.reason" />
        </a-form-model-item>
      </a-form-model>
      <div v-else>该商户暂未开通短信，请先开通短信后进行操作</div>
    </a-modal>
  </div>
</template>

<script>
import { SellerList, SellerDel, createSms, resetPwd, closeSmsLogin, generateDynamicToken, agentlist } from "@/views/api/seller";
import moment from "moment";
import { mixins } from "../../mixin/index";
import { smsNum } from "@/views/api/order";

export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
      columns: [
        {
          title: "商户ID",
          dataIndex: "seller_id",
          scopedSlots: {
            customRender: "seller_id",
          },
        },
        {
          title: "商户名称",
          dataIndex: "seller_name",
          scopedSlots: {
            customRender: "seller_name",
          },
        },
        {
          title: "商户登录地址",
          dataIndex: "seller_login",
          width: 200,
          scopedSlots: {
            customRender: "seller_login",
          },
        },
        {
          title: "联系人",
          dataIndex: "rename",
        },
        {
          title: "手机号",
          dataIndex: "mobile",
        },
        {
          title: "开始时间",
          dataIndex: "start_time",
          customRender: text => {
            return moment(text * 1000).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          title: "结束时间",
          dataIndex: "end_time",
          customRender: text => {
            return moment(text * 1000).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          title: "开户时间",
          dataIndex: "create_time",
        },
        {
          title: "门店数量",
          dataIndex: "store_num",
        },
        {
          title: "机器数量",
          dataIndex: "machine_num",
        },
        // {
        //   title: '短信条数',
        //   dataIndex: 'sms_num',
        // },
        {
          title: "短信签名",
          dataIndex: "sms_sign",
        },
        // {
        //   title: '开通的支付方式',
        //   dataIndex: 'payment_num',
        // },
        // {
        //   title: "语音剩余条数",
        //   dataIndex: "voice_num",
        // },
        {
          title: "销售类型",
          dataIndex: "agent_id",
          customRender: text => {
            return text == 0 ? "云果直销" : "代理商直销";
          },
        },
        {
          title: "操作",
          scopedSlots: {
            customRender: "action",
          },
          width: 200,
        },
      ],
      visible: false,
      sms_sign: "",
      token_visible: false,
      token: "",
      is_open: 1,
      agent_ids_list: [],
    };
  },
  computed: {},
  methods: {
    getList() {
      this.loading = true;
      SellerList(this.searchParams).then(res => {
        this.dataList = res.data.list;
        this.total = res.data.count;
        this.loading = false;
      });
      this.agentlist();
    },

    agentlist() {
      if (this.agent_ids_list.length != 0) return;
      agentlist({ page: 0, limit: 0 }).then(res => {
        this.agent_ids_list = res.data.list;
        this.loading = false;
      });
    },

    // 分页
    onPageChange(page, pageSize) {
      this.searchParams.page = page;
      this.searchParams.limit = pageSize;
      this.getList();
    },

    // 新增商户
    addSeller(flag, row) {
      this.$router.push({
        path: `/addSeller`,
        query: {
          row: row,
        },
      });
    },

    // 删除
    confirm(seller_id) {
      SellerDel({ seller_id }).then(res => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.getList();
        }
      });
    },

    // 开通短信
    createSms(seller_id) {
      createSms({ seller_id }).then(res => {
        if (res.code == 200) {
          this.$message.success("开通成功");
          this.getList();
        }
      });
    },

    // 重置密码
    resetPwd(seller_id) {
      resetPwd({ seller_id }).then(res => {
        if (res.code == 200) {
          this.$message.success("重置密码成功");
          this.getList();
        }
      });
    },

    // 关闭登录短信认证
    closeSmsLogin(seller_id) {
      closeSmsLogin({ seller_id }).then(res => {
        if (res.code == 200) {
          this.$message.success("关闭登录短信认证成功");
          this.getList();
        }
      });
    },

    // 动态口令弹框
    openGenerateDynamicTokenDialog(seller_id) {
      this.token_visible = true;
      generateDynamicToken({ seller_id, scene: "pay:config:modify" }).then(res => {
        this.token = res.data.token;
      });
    },

    showModel(row) {
      this.visible = true;
      this.order_id = row.order_id;
      this.form.seller_id = row.seller_id;
      this.form.sms_num = row.sms_num;
      this.sms_sign = row.sms_sign;
    },

    // 改价
    smsNum() {
      smsNum(this.form).then(res => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.visible = false;
          this.getList();
        }
      });
    },

    handleCancel() {
      this.visible = false;
    },

    handleOk() {
      this.token_visible = false;
    },
  },
};
</script>
<style lang="less" scoped></style>
