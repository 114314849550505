<template>
  <div>
    <a-card>
      <a-button type="primary" @click="cache">同步缓存</a-button>
      <!-- 系统产品列表 -->
      <a-table :loading="loading" :columns="columns" :data-source="dataList" :rowKey="(record) => record.product_id" tableLayout="fixed" :pagination="false">
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { productList,cache } from '@/views/api/system'
import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
      columns: [
      {
        title: '名称',
        dataIndex: 'name',
      },
      {
        title: '标题',
        dataIndex: 'title',
      },
      {
        title: '简介',
        dataIndex: 'desc',
      },
      ],
    };
  },
  computed: {},
  methods: {
    getList() {
      this.loading = true
      productList(this.searchParams).then(res=>{
        this.dataList = res.data
        this.loading = false
      })
    },
    cache() {
      cache({}).then(res=>{
        if (res.code == 200) {
          this.$message.success('同步成功')
          this.getList()
        }
      })
    }
  },
  created() {
    this.getList()
  },
};
</script>
<style lang="less" scoped>
</style>