<template>
  <div>
    <a-card>
      <!-- 收银机列表 -->
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="dataList"
        tableLayout="fixed"
        :pagination="{
          current: searchParams.page,
          pageSize: searchParams.limit,
          total: total,
          showLessItems: true,
          showQuickJumper: true,
          showTotal: (total, range) => `总计 ${total} 条`,
          onChange: onPageChange,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          onShowSizeChange: onPageChange,
        }">
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { smsOrder } from "@/views/api/order";
import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
      order_id: "",
      amount: "",
      searchParams: {
        page: 1,
        limit: 10,
      },
      total: 0,
      columns: [
        {
          title: "充值ID",
          dataIndex: "recharge_id",
          width: 200
        },
        {
          title: "商户名称",
          dataIndex: "seller_name",
        },
        {
          title: "交易金额",
          dataIndex: "amount",
        },
        {
          title: "短信条数",
          dataIndex: "number",
        },
        {
          title: "类型",
          dataIndex: "type",
          customRender: text => {
            return text == 1 ? "充值" : "转回";
          },
        },
        {
          title: "原因",
          dataIndex: "reason",
        },
        {
          title: "交易单号",
          dataIndex: "trade_no",
        },
        {
          title: "操作时间",
          dataIndex: "update_time",
        },
        {
          title: "短信类型",
          dataIndex: "sms_type",
          customRender: () => {
            return "普通";
          },
        },
        {
          title: "付款状态",
          dataIndex: "status",
          customRender: text => {
            return text == 0 ? "未支付" : "已支付";
          },
        },
        {
          title: '操作人',
          dataIndex: 'admin_name'
        }
      ],
    };
  },
  computed: {},
  methods: {
    getList() {
      this.loading = true;
      smsOrder(this.searchParams).then(res => {
        this.dataList = res.data.list;
        this.total = res.data.count;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
