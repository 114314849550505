export const mixins = {
  data() {
    return {
      visible: false,
      loading: false,
      total: 0,
      dataList: [],
      searchParams: {
        limit: 10,
        page: 1,
      },
      form: {},
    };
  },
  computed: {},
  created() {
    this.getList()
  },
  mounted() {},
  methods: {
    // 分页
    onPageChange(page, pageSize) {
      this.searchParams.page = page;
      this.searchParams.limit = pageSize;
      this.getList();
    },
    // 清除数据
    clearParams() {
      this.searchParams.keywords = {};
      this.searchParams.page = 1;
      this.searchParams.limit = 10;
      this.getList();
    },
  },
};
