<template>
  <div>
    <a-card>
      <a-row style="margin-bottom: 10px">
        代理商状态:
        <a-select placeholder="请选择" v-model="searchParams.status" style="margin-right: 20px">
          <a-select-option :value="1">云果直销</a-select-option>
          <a-select-option :value="2">代理商直销</a-select-option>
        </a-select>

        客户经理:
        <a-select placeholder="请选择" v-model="searchParams.manager_name" style="margin-right: 20px">
          <a-select-option value="张峰瑜"> 张峰瑜 </a-select-option>
          <a-select-option value="杨文涛"> 杨文涛 </a-select-option>
          <a-select-option value="史新旺"> 史新旺 </a-select-option>
        </a-select>

        代理商户号:
        <a-input placeholder="请输入代理商户号" v-model="searchParams.agent_id" style="width: 220px; margin-right: 20px"></a-input>

        代理商名称:
        <a-select placeholder="请选择" mode="multiple" v-model="searchParams.agent_ids" style="margin: 0 20px 20px 0">
          <a-select-option v-for="item in agent_ids_list" :key="item.agent_id" :value="item.agent_id"> {{ item.agent_name }} </a-select-option>
        </a-select>

        <br />

        省市区:
        <a-select placeholder="请选择省" v-model="searchParams.province_id" @change="AreaList(1)">
          <a-select-option v-for="d in province_List" :key="d.area_id">
            {{ d.area_name }}
          </a-select-option>
        </a-select>
        <a-select placeholder="请选择市" v-model="searchParams.city_id" @change="AreaList(2)">
          <a-select-option v-for="d in city_List" :key="d.area_id">
            {{ d.area_name }}
          </a-select-option>
        </a-select>
        <a-select placeholder="请选择区" v-model="searchParams.district_id" @change="AreaList(3)" style="margin-right: 20px">
          <a-select-option v-for="d in district_List" :key="d.area_id" :value="d.area_id">
            {{ d.area_name }}
          </a-select-option>
        </a-select>

        统计时间:
        <a-select placeholder="请选择" v-model="time_type">
          <a-select-option :value="1"> 创建时间 </a-select-option>
          <a-select-option :value="2"> 到期时间 </a-select-option>
        </a-select>
        <a-date-picker v-model="start" placeholder="请选择开始时间" :allowClear="false" @change="onStartChange" />
        <span style="width: 5px; margin: 0 10px">-</span>
        <a-date-picker v-model="end" placeholder="请选择结束时间" :allowClear="false" @change="onEndChange" />

        <a-button @click="getList" style="margin-left: 10px">查询</a-button>
      </a-row>

      <a-button @click="addEdit(null)" style="margin: 20px 0">新增代理商</a-button>

      <a-table
        style="padding: 0"
        :loading="loading"
        size="small"
        :columns="columns"
        :data-source="dataList"
        :rowKey="record => record.agent_id"
        :scroll="{ x: 1800 }"
        :pagination="{
          current: searchParams.page,
          pageSize: searchParams.limit,
          total: total,
          showLessItems: true,
          showQuickJumper: true,
          showTotal: (total, range) => `总计 ${total} 条`,
          onChange: onPageChange,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          onShowSizeChange: onPageChange,
        }">
        <template slot="login_url" slot-scope="text">
          <a target="_blank">{{ text }}</a>
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click="addEdit(record)" style="margin-right: 10px">编辑</a>
          <a v-if="record.status != 0" @click="changeStatus(record, 0)" style="margin-right: 10px">停用</a>
          <a v-else @click="changeStatus(record, 1)" style="margin-right: 10px">启用</a>
          <a @click="del(record)" style="margin-right: 10px">删除</a>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { AreaList } from "@/views/api/common";
import { agentlist, agentchangeStatus, agentdelete } from "@/views/api/seller";
import { mixins } from "../../mixin/index";
import moment from "moment";

export default {
  mixins: [mixins],
  data() {
    return {
      modalText: "",
      columns: [
        {
          title: "代理商户号",
          dataIndex: "agent_id",
        },
        {
          title: "代理商名称",
          dataIndex: "agent_name",
        },
        // {
        //   title: "登录地址",
        //   dataIndex: "login_url",
        //   scopedSlots: {
        //     customRender: "login_url",
        //   },
        //   width: 300,
        // },
        {
          title: "详细地址",
          dataIndex: "address",
        },
        {
          title: "代理商经理名称",
          dataIndex: "manager_name",
        },

        {
          title: "开始时间",
          dataIndex: "start_time",
        },
        {
          title: "到期时间",
          dataIndex: "end_time",
        },

        {
          title: "状态",
          dataIndex: "status",
          customRender: text => {
            return text == 0 ? "停用" : text == 1 ? "正常 " : text == 2 ? "审核中" : "";
          },
        },
        {
          title: " 联系人姓名",
          dataIndex: "contact_name",
        },
        {
          title: "手机号",
          dataIndex: "mobile",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
        },
        {
          title: "操作",
          fixed: "right",
          scopedSlots: {
            customRender: "action",
          },
        },
      ],

      selectedRowKeys: [],
      selectedRows: [],
      seller: {},

      store: [],
      store_id_list: [],
      pay_type_list: [],

      store_num: "",
      renewal_model: "",

      start: null,
      end: null,
      paymentList: [],
      province_List: [],
      city_List: [],
      district_List: [],

      agent_ids_list: [],
      searchParams: {},

      time_type: undefined,
      create_time: [],
      expire_time: [],
    };
  },
  computed: {},
  methods: {
    moment,
    getList() {
      this.loading = true;
      if (this.time_type == 1) {
        this.searchParams.create_time = this.create_time;
      } else {
        this.searchParams.expire_time = this.expire_time;
      }
      agentlist(this.searchParams).then(res => {
        this.dataList = res.data.list;
        this.total = res.data.count;
        this.loading = false;
      });
      if (this.agent_ids_list.length != 0) return;
      agentlist({ page: 0, limit: 0 }).then(res => {
        this.agent_ids_list = res.data.list;
        this.loading = false;
      });
    },

    // 确认
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) return this.addEdit();
        return false;
      });
    },

    changeStatus(record, status) {
      agentchangeStatus({ status: status, agent_id: record.agent_id }).then(res => {
        if (res.code == 200) {
          this.$message.success("操作成功");
          this.getList();
        }
      });
    },

    del(record) {
      agentdelete({ agent_id: record.agent_id }).then(res => {
        if (res.code == 200) {
          this.$message.success("已删除");
          this.getList();
        }
      });
    },

    // 新增商户
    addEdit(row) {
      this.$router.push({
        path: `/agentAdd`,
        query: {
          row: row,
        },
      });
    },

    // 省市区
    AreaList(area_id) {
      const area = area_id;
      if (area_id == 1) {
        area_id = this.searchParams.province_id;
        this.city_List = [];
        this.searchParams.city_id = undefined;
        this.district_List = [];
        this.searchParams.district_id = undefined;
      }
      if (area_id == 2) {
        area_id = this.searchParams.city_id;
        this.district_List = [];
        this.searchParams.district_id = undefined;
      }
      AreaList({ area_id: area_id }).then(res => {
        if (area == 0) this.province_List = res.data;
        if (area == 1) this.city_List = res.data;
        if (area == 2) this.district_List = res.data;
      });
      this.$forceUpdate();
    },

    onStartChange(date, dateString) {
      this.start = moment(dateString);
      if (this.time_type == 1) {
        this.create_time[0] = moment(dateString).unix();
      } else {
        this.expire_time[0] = moment(dateString).unix();
      }
    },

    onEndChange(date, dateString) {
      this.end = moment(dateString);
      if (this.time_type == 1) {
        this.create_time[1] = moment(dateString).unix();
      } else {
        this.expire_time[1] = moment(dateString).unix();
      }
    },
  },

  created() {},
};
</script>
<style lang="less" scoped>
.ant-select {
  width: 150px;
}
</style>
