// 公共接口
import request from "../utils/request";

// 单位列表 ---------------------------
export async function GoodsUnitList(data) {
  return request({
    url: "/GoodsUnit/list",
    method: "post",
    data,
  });
}

// 添加单位
export async function unitAdd(data) {
  return request({
    url: "/GoodsUnit/add",
    method: "post",
    data,
  });
}

// 编辑单位
export async function unitEdit(data) {
  return request({
    url: "/GoodsUnit/edit",
    method: "post",
    data,
  });
}

// 删除单位
export async function unitDel(data) {
  return request({
    url: "/GoodsUnit/delete",
    method: "post",
    data,
  });
}

// 单位状态
export async function unitStatus(data) {
  return request({
    url: "/GoodsUnit/status",
    method: "post",
    data,
  });
}

// 出入库类型列表  ---------------------------
export async function WarehouseCateList(data) {
  return request({
    url: "/WarehouseCate/list",
    method: "post",
    data,
  });
}

// 添加出入库类型
export async function WarehouseCateAdd(data) {
  return request({
    url: "/WarehouseCate/add",
    method: "post",
    data,
  });
}

// 编辑出入库类型
export async function WarehouseCateEdit(data) {
  return request({
    url: "/WarehouseCate/edit",
    method: "post",
    data,
  });
}

// 删除出入库类型
export async function WarehouseCateDel(data) {
  return request({
    url: "/WarehouseCate/delete",
    method: "post",
    data,
  });
}

// 出入库类型状态
export async function WarehouseCateStatus(data) {
  return request({
    url: "/WarehouseCate/status",
    method: "post",
    data,
  });
}

// 短信套餐列表  ---------------------------
export async function SmsList(data) {
  return request({
    url: "/Sms/list",
    method: "post",
    data,
  });
}

// 添加短信套餐
export async function SmsAdd(data) {
  return request({
    url: "/Sms/add",
    method: "post",
    data,
  });
}

// 编辑短信套餐
export async function SmsEdit(data) {
  return request({
    url: "/Sms/edit",
    method: "post",
    data,
  });
}

// 删除短信套餐
export async function SmsDel(data) {
  return request({
    url: "/Sms/delete",
    method: "post",
    data,
  });
}

// 广告模块---------------------
export async function PositionList(data) {
  return request({
    url: "/Position/list",
    method: "post",
    data,
  });
}

export async function PositionEdit(data) {
  return request({
    url: "/Position/edit",
    method: "post",
    data,
  });
}

export async function PositionAdd(data) {
  return request({
    url: "/Position/add",
    method: "post",
    data,
  });
}

export async function PositionDel(data) {
  return request({
    url: "/Position/delete",
    method: "post",
    data,
  });
}

// 导航模块------------------------
export async function WxPathList(data) {
  return request({
    url: "/WxPath/list",
    method: "post",
    data,
  });
}

export async function WxPathAdd(data) {
  return request({
    url: "/WxPath/add",
    method: "post",
    data,
  });
}

export async function WxPathEdit(data) {
  return request({
    url: "/WxPath/edit",
    method: "post",
    data,
  });
}

export async function WxPathDel(data) {
  return request({
    url: "/WxPath/delete",
    method: "post",
    data,
  });
}

// 收银机异常日志--------------------
export async function ErrorLogList(data) {
  return request({
    url: "/ErrorLog/list",
    method: "post",
    data,
  });
}

// 版本升级-----------------------
export async function versionAdd(data) {
  return request({
    url: "/Version/add",
    method: "post",
    data,
  });
}

export async function versionList(data) {
  return request({
    url: "/Version/list",
    method: "post",
    data,
  });
}

export async function versionEdit(data) {
  return request({
    url: "/Version/edit",
    method: "post",
    data,
  });
}

export async function versionDel(data) {
  return request({
    url: "/Version/delete",
    method: "post",
    data,
  });
}

// 系统产品模块------------------
export async function productList(data) {
  return request({
    url: "/Product/list",
    method: "post",
    data,
  });
}

export async function productAdd(data) {
  return request({
    url: "/Product/add",
    method: "post",
    data,
  });
}

export async function productEdit(data) {
  return request({
    url: "/Product/edit",
    method: "post",
    data,
  });
}

export async function productDel(data) {
  return request({
    url: "/Product/delete",
    method: "post",
    data,
  });
}

// 图片分类--------------------
export async function FolderCateList(data) {
  return request({
    url: "/FolderCate/list",
    method: "post",
    data,
  });
}

export async function FolderCateAdd(data) {
  return request({
    url: "/FolderCate/add",
    method: "post",
    data,
  });
}

export async function FolderCateEdit(data) {
  return request({
    url: "/FolderCate/edit",
    method: "post",
    data,
  });
}

export async function FolderCateDel(data) {
  return request({
    url: "/FolderCate/delete",
    method: "post",
    data,
  });
}

// 文件-----------------------
export async function FolderFileList(data) {
  return request({
    url: "/FolderFile/list",
    method: "post",
    data,
  });
}

export async function FolderFileAdd(data) {
  return request({
    url: "/FolderFile/add",
    method: "post",
    data,
  });
}

export async function FolderFileEdit(data) {
  return request({
    url: "/FolderFile/edit",
    method: "post",
    data,
  });
}

export async function FolderFileDel(data) {
  return request({
    url: "/FolderFile/delete",
    method: "post",
    data,
  });
}

// 快递公司-----------------------
export async function expressList(data) {
  return request({
    url: "/express/list",
    method: "post",
    data,
  });
}

export async function expressAdd(data) {
  return request({
    url: "/express/add",
    method: "post",
    data,
  });
}

export async function expressEdit(data) {
  return request({
    url: "/express/edit",
    method: "post",
    data,
  });
}

export async function expressDel(data) {
  return request({
    url: "/express/delete",
    method: "post",
    data,
  });
}

// 开户行-----------------------
export async function bankList(data) {
  return request({
    url: "/bank/list",
    method: "post",
    data,
  });
}

export async function bankAdd(data) {
  return request({
    url: "/bank/add",
    method: "post",
    data,
  });
}

export async function bankEdit(data) {
  return request({
    url: "/bank/edit",
    method: "post",
    data,
  });
}

export async function bankDel(data) {
  return request({
    url: "/bank/delete",
    method: "post",
    data,
  });
}

// 通知模板--------------------
export async function noticeList(data) {
  return request({
    url: "/notice/list",
    method: "post",
    data,
  });
}

export async function noticeAdd(data) {
  return request({
    url: "/notice/add",
    method: "post",
    data,
  });
}

export async function noticeEdit(data) {
  return request({
    url: "/notice/edit",
    method: "post",
    data,
  });
}

export async function noticeDel(data) {
  return request({
    url: "/notice/delete",
    method: "post",
    data,
  });
}

// 系统用户--------------------
export async function userAdd(data) {
  return request({
    url: "/User/add",
    method: "post",
    data,
  });
}

export async function userEdit(data) {
  return request({
    url: "/User/edit",
    method: "post",
    data,
  });
}

export async function userDel(data) {
  return request({
    url: "/User/delete",
    method: "post",
    data,
  });
}

// 角色列表----------------------
export async function roleList(data) {
  return request({
    url: "/Role/list",
    method: "post",
    data,
  });
}

// 支付方式--------------------
export async function paymentList(data) {
  return request({
    url: "/Payment/list",
    method: "post",
    data,
  });
}

export async function paymentStatus(data) {
  return request({
    url: "/Payment/status",
    method: "post",
    data,
  });
}

export async function updateCache(data) {
  return request({
    url: "/Payment/updateCache",
    method: "post",
    data,
  });
}

// 系统用户------------------
// 用户平台列表
export async function platformList(data) {
  return request({
    url: "/User/platformList",
    method: "post",
    data,
  });
}

// 用户代理列表
export async function agentList(data) {
  return request({
    url: "/User/agentList",
    method: "post",
    data,
  });
}

// 同步缓存
export async function cache(data) {
  return request({
    url: "/product/cache",
    method: "post",
    data,
  });
}

// 版本详情
export async function Versioninfo(data) {
  return request({
    url: "/Version/info",
    method: "post",
    data,
  });
}

// 小程序版本列表
export async function VersionXcxList(data) {
  return request({
    url: "/VersionXcx/list",
    method: "post",
    data,
  });
}

// 小程序版本删除
export async function VersionXcxDel(data) {
  return request({
    url: "/VersionXcx/delete",
    method: "post",
    data,
  });
}

// 小程序版本删除
export async function VersionXcxadd(data) {
  return request({
    url: "/VersionXcx/add",
    method: "post",
    data,
  });
}
// 小程序版本删除
export async function VersionXcxedit(data) {
  return request({
    url: "/VersionXcx/edit",
    method: "post",
    data,
  });
}
// 小程序版本删除
export async function VersionXcxinfo(data) {
  return request({
    url: "/VersionXcx/info",
    method: "post",
    data,
  });
}

// 获取版本列表
export async function ErrorLogversion(data) {
  return request({
    url: "/ErrorLog/version",
    method: "post",
    data,
  });
}

// 版本升级相关----------------------------------------------------------------------------------
// 获取商户列表
export async function VersionGetSeller(data) {
  return request({
    url: "/Version/getSeller",
    method: "post",
    data,
  });
}

// 获取门店列表
export async function VersionGetStore(data) {
  return request({
    url: "/Version/getStore",
    method: "post",
    data,
  });
}

// 获取机器列表
export async function VersionGetMachine(data) {
  return request({
    url: "/Version/getMachine",
    method: "post",
    data,
  });
}

// 获取版本可用商户 -----------------------
export async function versionSeller(data) {
  return request({
    url: "/Version/versionSeller",
    method: "post",
    data,
  });
}

// 获取版本可用门店
export async function versionStore(data) {
  return request({
    url: "/Version/versionStore",
    method: "post",
    data,
  });
}

// 获取版本可用机器
export async function versionMachine(data) {
  return request({
    url: "/Version/versionMachine",
    method: "post",
    data,
  });
}

// 添加商户  -----------------------
export async function addSeller(data) {
  return request({
    url: "/Version/addSeller",
    method: "post",
    data,
  });
}

// 添加门店
export async function addStore(data) {
  return request({
    url: "/Version/addStore",
    method: "post",
    data,
  });
}

// 添加机器
export async function addMachine(data) {
  return request({
    url: "/Version/addMachine",
    method: "post",
    data,
  });
}

// 删除商户 -----------------------
export async function delSeller(data) {
  return request({
    url: "/Version/delSeller",
    method: "post",
    data,
  });
}

// 删除门店
export async function delStore(data) {
  return request({
    url: "/Version/delStore",
    method: "post",
    data,
  });
}

// 删除机器
export async function delMachine(data) {
  return request({
    url: "/Version/delMachine",
    method: "post",
    data,
  });
}
