<template>
  <div>
    <a-card>
      <!-- <a-button type="primary" @click="addSeller(1)">新增商户</a-button> -->
      <a-row style="margin-bottom: 10px">
        <a-select placeholder="请选择" v-model="search_type">
          <a-select-option value="goods_name">商品名称</a-select-option>
          <a-select-option value="barcode">商户条码</a-select-option>
        </a-select>
        <a-input placeholder="请输入" v-model="search_value" style="width: 160px; margin-right: 20px"></a-input>

        商品状态:
        <a-select placeholder="请选择" v-model="status" style="width: 100px">
          <a-select-option :value="1">上架</a-select-option>
          <a-select-option :value="0">下架</a-select-option>
        </a-select>
        <a-button @click="getList(true)" type="primary" style="margin-left: 10px">查询</a-button>
        <a style="margin-left: 8px" @click="clearParams">重置筛选条件</a>
      </a-row>

      <a-button @click="showImportBox" type="primary" style="margin-bottom: 10px">导入</a-button>
      <!-- 商品库 -->
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="dataList"
        :rowKey="record => record.goods_id"
        tableLayout="fixed"
        :pagination="{
          current: searchParams.page,
          pageSize: searchParams.limit,
          total: total,
          showLessItems: true,
          showQuickJumper: true,
          showTotal: (total, range) => `总计 ${total} 条`,
          onChange: onPageChange,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          onShowSizeChange: onPageChange,
        }">
        <!-- <template slot="status" slot-scope="text, record">
          <a-switch :checked="record.status == 1 ? true : false" @change="GoodsBrandStatus($event,record)" />
        </template> -->
        <template slot="action" slot-scope="text, record">
          <a @click="edit(record)" style="margin-right: 10px">编辑</a>
          <!-- <a-popconfirm title="确定删除该商户吗?" ok-text="是" cancel-text="否" @confirm="confirm(record.seller_id)">
            <a class="error-color">删除</a>
          </a-popconfirm> -->
        </template>
      </a-table>
    </a-card>

    <a-modal v-model="import_visibel" title="批量导入" centered width="50%" @ok="GoodsImport">
      <div>
        <a-upload
          name="file"
          style="display: inline-block"
          :fileList="fileList"
          :remove="remove"
          :customRequest="handleChange"
          accept=".xlsx, .xls"
          :before-upload="beforeUpload">
          <a-button :disabled="fileList.length > 0" style="margin-right: 20px">添加文件</a-button>
          <a class="m-l-15" @click.stop="GoodsTemplate">下载模板</a>
        </a-upload>
      </div>
    </a-modal>

    <a-modal v-model="visibel" @ok="close">
      <div>成功数据: {{ data.success_num }} 条,</div>
      <div>失败数据: {{ data.error_num }} 条,</div>
      <div v-for="(item, i) in data.error_list" :key="i">{{ item }}</div>
    </a-modal>
  </div>
</template>

<script>
import { goodsList, SellerDel, GoodsTemplate, GoodsImport } from "@/views/api/seller";
// import moment from "moment";
import { mixins } from "../../mixin/index";
import { sign, randomString } from "@/views/utils/sign.js";

export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
      columns: [
        {
          title: "商品ID",
          dataIndex: "goods_id",
        },
        {
          title: "商品分类",
          dataIndex: "cate_name",
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
        },
        {
          title: "售卖单位",
          dataIndex: "unit_name",
        },
        {
          title: "商品条码",
          dataIndex: "barcode",
        },
        {
          title: "成本价",
          dataIndex: "cost_price",
        },
        {
          title: "销售价",
          dataIndex: "sale_price",
        },
        {
          title: "会员价",
          dataIndex: "vip_price",
        },
        {
          title: "商品状态",
          dataIndex: "status",
          customRender: text => {
            return text ? "上架" : "下架";
          },
        },
        {
          title: "操作",
          scopedSlots: {
            customRender: "action",
          },
        },
      ],

      import_visibel: false,
      import_loading: false,
      fileList: [],
      visibel: false,

      data: {},

      search_type: "goods_name",
      search_value: "",
      status: undefined,
    };
  },
  computed: {},
  methods: {
    getList(is_search) {
      this.loading = true;
      this.searchParams.goods_name = "";
      this.searchParams.barcode = "";
      this.searchParams[this.search_type] = this.search_value;
      if (is_search) this.searchParams.page = 1;
      goodsList(this.searchParams).then(res => {
        this.dataList = res.data.list;
        this.total = res.data.count;
        this.loading = false;
      });
    },

    // 分页
    onPageChange(page, pageSize) {
      this.searchParams.page = page;
      this.searchParams.limit = pageSize;
      this.getList();
    },

    clearParams() {
      this.search_params = {
        page: 1,
        limit: 10,
      };
      this.search_value = "";
      this.getList();
    },

    edit(row) {
      this.$router.push({
        path: `/addGoods`,
        query: {
          goods_id: row.goods_id,
        },
      });
    },

    // 删除
    confirm(seller_id) {
      SellerDel({ seller_id }).then(res => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.getList();
        }
      });
    },

    // 模版
    GoodsTemplate() {
      GoodsTemplate({}).then(result => {
        if (result.code == 200) window.open(result.data);
      });
    },

    // 导入框
    showImportBox() {
      this.fileList = [];
      this.import_visibel = true;
    },

    // 上传前钩子 ---------------------------------------------------
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        let size = file.size / 1024 / 1024;
        if (size > 2) {
          this.$message.warning("文件大小不得超过2M");
          return reject(false); //如有问题通过reject 函数传一个false阻止他的上传
        }
        if (extension != "xlsx" && extension != "xls") {
          this.$message.warning("只能上传后缀是.xlsx或xls的文件");
          return reject(false); //如有问题通过reject 函数传一个false阻止他的上传
        } else {
          return resolve(true);
        }
      });
    },

    // 导入上传
    async handleChange(file) {
      this.fileList = [];
      this.fileList.push(file.file);
    },

    GoodsImport() {
      this.import_loading = true;
      var data = {};
      const noncestr = randomString(20);
      const timestamp = Date.parse(new Date()) / 1000 + "";
      const token = window.sessionStorage.getItem("access_token");
      data.data = {
        noncestr: noncestr,
        timestamp: timestamp,
        user_token: token,
      };
      var params = new FormData();
      params.append("file", this.fileList[0]);
      params.append("noncestr", noncestr);
      params.append("timestamp", timestamp);
      params.append("user_token", token);
      params.append("sign", sign(data));
      GoodsImport(params).then(res => {
        this.import_loading = false;
        if (res.code == 200) {
          this.data = res.data;
          this.import_visibel = false;
          this.visibel = true;
        }
      });
    },

    close() {
      this.visibel = false;
      this.data = {};
    },

    remove() {
      this.fileList = [];
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="less" scoped></style>
