<template>
  <div>
    <a-card>
      <a-button type="primary" @click="addnotice(1)">新增通知模板</a-button>
      <!-- 通知模板列表 -->
      <a-table :loading="loading" :columns="columns" :data-source="dataList" :rowKey="(record) => record.notice_id" tableLayout="fixed" :pagination="false">
        <template slot="action" slot-scope="text, record">
          <a @click="addnotice(2,record)" style="margin-right:10px;">编辑</a>
          <a-popconfirm title="确定删除该通知模板吗?" ok-text="是" cancel-text="否" @confirm="confirm(record.notice_id)">
            <a class="error-color">删除</a>
          </a-popconfirm>
        </template>
      </a-table>
    </a-card>
    <!-- 添加通知模板 -->
    <a-modal v-model="visible" :title='`${modalText}通知模板`' @ok="handleOk" @cancel="handleCancel">
      <a-form-model ref="ruleForm" :form="form" :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16, offset: 1 }">
        <a-form-model-item label="通知模板标题" prop='title'>
          <a-input placeholder="请输入通知模板名称" v-model="form.title" show-word-limit />
        </a-form-model-item>
        <a-form-model-item label="短信内容" prop='sms_content'>
          <a-input placeholder="请输入短信内容" v-model="form.sms_content" show-word-limit />
        </a-form-model-item>
        <a-form-model-item label="订阅消息内容">
          <a-input  placeholder="请输入订阅消息内容" v-model="form.subscribe_content"/>
        </a-form-model-item>
        <a-form-model-item label="模版消息内容">
          <a-input  placeholder="请输入模版消息内容" v-model="form.template_content"/>
        </a-form-model-item>
        <a-form-model-item label="模版类型">
          <a-select placeholder="请选择模版类型" v-model="form.type">
            <a-select-option :value="1">交易</a-select-option>
            <a-select-option :value="2">营销</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="启用状态">
          <a-switch v-if="visible" :checked="form.status == 0 ? false : true" @change="statusChange" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { noticeList,noticeAdd,noticeEdit,noticeDel } from '@/views/api/system'
import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
      form: {
        status: 0
      },
      modalText: '',
      columns: [
      {
        title: '通知模板ID',
        dataIndex: 'notice_id',
      },
      {
        title: '通知模板名称',
        dataIndex: 'title',
      },
      {
        title: '短信内容',
        dataIndex: 'sms_content',
      },
      {
        title: '订阅消息内容',
        dataIndex: 'subscribe_content',
      },
      {
        title: '模版消息内容',
        dataIndex: 'template_content',
      },
      {
        title: '模版类型',
        dataIndex: 'type',
        customRender: (text) => {
        return text == 1 ? "交易" : '营销';
        },
      },
      {
        title: '状态',
        dataIndex: 'status',
        customRender: (text) => {
        return text == 1 ? "启用" : '禁用';
        },
      },
      {
        title: '操作',
        scopedSlots: {
          customRender: 'action'
        }
      }],
    };
  },
  computed: {},
  methods: {
    getList() {
      this.loading = true
      noticeList(this.searchParams).then(res=>{
        this.dataList = res.data
        this.loading = false
      })
    },
    // 确认
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) return this.addEdit();
        return false;
      });
    },
    // 添加编辑
    addEdit() {
      this.flag == 1 ? noticeAdd(this.form).then((res) => this.handleSuccess(1,res)) : noticeEdit(this.form).then((res) => this.handleSuccess(2,res));
    },
    // 操作成功
    handleSuccess(flag,res) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? '添加' : flag == 2 ? '编辑' : flag == 3 ? '删除' : '修改状态'}成功`);
        this.visible = false;
        this.getList()
      }
    },
    // 弹框关闭
    handleCancel() {
      this.visible = false;
    },
    // 新增通知模板
    addnotice(flag,row) {
      this.form = {}
      this.flag = flag;
      this.visible = true;
      if (row) {
        this.form = JSON.parse(JSON.stringify(row));
        this.modalText = "编辑";
      } else {
        this.form.status = 1
        this.modalText = "新增";
      }
    },
    // 删除
    confirm(notice_id) {
      noticeDel({ notice_id }).then((res) => this.handleSuccess(3,res));
    },
    // 弹框启用状态
    statusChange(checked) {
      this.$forceUpdate()
      checked ? this.form.status = 1 : this.form.status = 0
    },
  },
  created() {
    this.getList()
  },
};
</script>
<style lang="less" scoped>
</style>