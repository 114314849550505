<template>
  <div>
    <a-card>
      <a-button type="primary" @click="addUnit(1)">新增单位</a-button>
      <!-- 单位列表 -->
      <a-table :loading="loading" :columns="columns" :data-source="dataList" :rowKey="(record) => record.unit_id" tableLayout="fixed" :pagination="{
            current: searchParams.page,
            pageSize: searchParams.limit,
            total: total,
            showLessItems: true,
            showQuickJumper: true,
            showTotal: (total, range) => `总计 ${total} 条`,
            onChange: onPageChange,
            showSizeChanger: true,
	          pageSizeOptions: ['10', '20', '50'],
            onShowSizeChange: onPageChange,
      }">
        <template slot="status" slot-scope="text, record">
          <a-switch :checked="record.status == 1 ? true : false" @change="unitStatus($event,record)" />
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click="addUnit(2,record)" style="margin-right:10px;">编辑</a>
          <a-popconfirm title="确定删除该单位吗?" ok-text="是" cancel-text="否" @confirm="confirm(record.unit_id)">
            <a class="error-color">删除</a>
          </a-popconfirm>
        </template>
      </a-table>
    </a-card>
    <!-- 添加单位 -->
    <a-modal v-model="visible" :title='`${modalText}商品单位`' @ok="handleOk" @cancel="handleCancel">
      <a-form-model ref="ruleForm" :form="form" :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16,offset: 1 }">
        <a-form-model-item label="单位名称" prop='unit_name'>
          <a-input placeholder="请输入单位名称" v-model="form.unit_name" prop="unit_name" :maxLength="8" show-word-limit />
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-radio-group v-model="form.status">
            <a-radio :value="0">禁用</a-radio>
            <a-radio :value="1">启用</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="单位属性" prop="type">
          <a-radio-group v-model="form.type">
            <a-radio :value="2">计件单位</a-radio>
            <a-radio :value="1">计重单位</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="排序">
          <a-input-number v-model="form.sort" :min="1" placeholder="请输入序号" style="width:100%" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { GoodsUnitList,unitAdd,unitEdit,unitDel,unitStatus } from '@/views/api/system'
import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
      modalText: '',
      columns: [
      {
        title: '单位ID',
        dataIndex: 'unit_id',
      },
      {
        title: '单位名称',
        dataIndex: 'unit_name',
      },
      {
        title: '排序',
        dataIndex: 'sort',
      },
      {
        title: '单位名称',
        dataIndex: 'type',
        customRender: (text) => {
        return text == 1 ? "称重" : "计件";
        },
      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
      },
      {
        title: '是否启用',
        dataIndex: 'status',
        scopedSlots: {
          customRender: 'status'
        }
      },
      {
        title: '操作',
        scopedSlots: {
          customRender: 'action'
        }
      }],
    };
  },
  computed: {},
  methods: {
    getList() {
      this.loading = true
      GoodsUnitList(this.searchParams).then(res=>{
        this.dataList = res.data
        this.loading = false
      })
    },
    // 确认
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) return this.addEdit();
        return false;
      });
    },
    // 添加编辑
    addEdit() {
      this.flag == 1 ? unitAdd(this.form).then((res) => this.handleSuccess(1,res)) : unitEdit(this.form).then((res) => this.handleSuccess(2,res));
    },
    // 操作成功
    handleSuccess(flag,res) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? '添加' : flag == 2 ? '编辑' : flag == 3 ? '删除' : '修改状态'}成功`);
        this.visible = false;
        this.clearCheckForm();
        this.getList()
      }
    },
    // 清除form
    clearCheckForm() {
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });
    },
    // 弹框关闭
    handleCancel() {
      this.visible = false;
      this.clearCheckForm();
      this.form = {type: 2, sort:100};
    },
    // 新增单位
    addUnit(flag,row) {
      this.flag = flag;
      this.form = {type:2,sort:100};
      this.visible = true;
      if (row) {
        this.form = JSON.parse(JSON.stringify(row));
        this.modalText = "编辑";
      } else {
        this.modalText = "新增";
      }
    },
    // 删除
    confirm(unit_id) {
      unitDel({ unit_id }).then((res) => this.handleSuccess(3,res));
    },
    // 修改状态
    unitStatus(e,record) {
      e ? record.status = 1 : record.status = 0
      unitStatus({status: record.status,unit_id: record.unit_id}).then((res) => this.handleSuccess(4,res));
    },
  },
  created() {
    this.getList()
  },
};
</script>
<style lang="less" scoped>
</style>