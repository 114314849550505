<template>
  <div>
    <a-card>
      <div class="title">{{params? '编辑': '添加'}}商户</div>
      <a-form-model ref="ruleForm" :form="form" :model="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 8,offset: 1 }">
        <a-form-model-item label="角色名称" prop='role_name'>
          <a-input placeholder="请输入角色名称" v-model="form.role_name" />
        </a-form-model-item>
        <a-form-model-item label="角色描述" prop='role_desc'>
          <a-textarea placeholder="请输入角色描述" v-model="form.role_desc" :auto-size="{ minRows: 3, maxRows: 5 }" />
        </a-form-model-item>
        <a-form-model-item label="角色状态">
          <a-radio-group v-model="form.status">
            <a-radio :value="1">启用</a-radio>
            <a-radio :value="0">禁用</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="默认开启">
          <a-radio-group v-model="form.is_open">
            <a-radio :value="1">启用</a-radio>
            <a-radio :value="0">禁用</a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>

      <div class="title">添加权限</div>
      <a-tabs type="card" @change="callback">
        <a-tab-pane :key="1" tab="零售">
          <a-tree v-if="nodeList.length" ref="tree" :blockNode="false" v-model="checkedKeys" :replace-fields="replaceFields" checkable :defaultExpandAll="true" :selected-keys="selectedKeys" :tree-data="nodeList || []" @check="offCheck" />
          <!-- <a-tree v-if="key == 1 && nodeList.length" ref="tree" :blockNode="false" v-model="checkedKeys" :replace-fields="replaceFields" checkable :defaultExpandAll="true" :selected-keys="selectedKeys" :tree-data="nodeList[0].children" @check="offCheck" /> -->
        </a-tab-pane>
        <a-tab-pane :key="2" tab="线上">
          <a-tree ref="tree" v-if="nodeList2.length" :blockNode="false" v-model="checkedKeysOn" :replace-fields="replaceFields" checkable :defaultExpandAll="true" :selected-keys="selectedKeys" :tree-data="nodeList2 || []" @check="onCheck" />
          <!-- <a-tree v-if="key == 2 && nodeList.length" ref="tree" :blockNode="false" v-model="checkedKeysOn" :replace-fields="replaceFields" checkable :defaultExpandAll="true" :selected-keys="selectedKeys" :tree-data="nodeList[1].children" @check="onCheck" /> -->
        </a-tab-pane>
        <a-tab-pane :key="4" tab="会员">
          <a-tree ref="tree" v-if="nodeList4.length" :blockNode="false" v-model="checkedKeysMember" :replace-fields="replaceFields" checkable :defaultExpandAll="true" :selected-keys="selectedKeys" :tree-data="nodeList4 || []" @check="onCheckMember" />
          <!-- <a-tree v-if="key == 3 && nodeList.length" ref="tree" :blockNode="false" v-model="checkedKeysMember" :replace-fields="replaceFields" checkable :defaultExpandAll="true" :selected-keys="selectedKeys" :tree-data="nodeList[2].children" @check="onCheckMember" /> -->
        </a-tab-pane>
      </a-tabs>
    </a-card>
    <div style="height:100px;">
      <a-card class="addBottom">
        <a-button @click="goback">返回</a-button>
        <a-button @click="RoleAdd" type="primary" style="margin-left:20px;">提交</a-button>
      </a-card>
    </div>
  </div>
</template>

<script>
import { SellerNodeList,SellerRoleAdd,SellerRoleEdit,SellerRoleDetail } from '@/views/api/seller'

export default {
  data() {
    return {
        key: 1,
        params: 1,
        form: {
            status:1,
            is_open:1,
            nodes: []
        },
        autoExpandParent: true,
        selectedKeys: [],
        replaceFields: {
        children: "child",
        title: "title",
        key: "node_id",
        },
        nodeList: [],
        nodeList2: [],
        nodeList4: [],
        role_id: '',
        checkedKeys: [],
        checkedKeysOn: [],
        checkedKeysMember: []
    };
  },
  computed: {},
  methods: {
    init() {
      SellerNodeList({type: 1}).then(res=>{
        this.nodeList = res.data
      })
      SellerNodeList({type: 2}).then(res=>{
        this.nodeList2 = res.data
      })
      SellerNodeList({type: 4}).then(res=>{
        this.nodeList4 = res.data
      })
    },

    checkChange(checkedKeys, e) {
      this.checkedKeys = checkedKeys
      this.form.nodes = [...this.checkedKeys,...e.halfCheckedKeys];
    },

    offCheck(checkedKeys, e) {
      this.checkedKeys = checkedKeys;
      this.countCheckedKeys(e);
    },
    onCheck(checkedKeys, e) {
      this.checkedKeysOn = checkedKeys;
      this.countCheckedKeys(e);
    },
    onCheckMember(checkedKeys, e) {
      this.checkedKeysMember = checkedKeys;
      this.countCheckedKeys(e);
    },

    RoleAdd() {
        if (this.role_id) {
            SellerRoleEdit(this.form).then(res=>{
            if (res.code == 200) {
                this.$message.success('添加成功')
                this.goback()
            }
        })
        } else {
            this.form.role_id = this.role_id
            SellerRoleAdd(this.form).then(res=>{
            if (res.code == 200) {
                this.$message.success('添加成功')
                this.goback()
            }
        })
        }
    },

    detail(role_id) {
        SellerRoleDetail({role_id}).then(res=>{
            this.form = res.data
        })
    },
    
    goback() {
        this.$router.push({
        path: `/sellerRole`,
      });
    },

    callback(key) {
      this.key = key
    }
  },

  created() {
      this.init()
      if (this.$route.query.row) {
          this.role_id = this.$route.query.row
          this.detail(this.$route.query.row)
      } else {
          this.form = {           
            status:1,
            is_open:1,
            nodes: []
        }
      }
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-form-item-label {
  text-align: center;
}
/deep/.ant-tree > li {
  line-height: 50px;
  background-color: #f8f9fe;
  > ul {
    background-color: #fff;
    > li {
      > ul {
        > li {
          border-top: 1px solid #eee;
        }
      }
    }
  }
}
/deep/span.ant-tree-switcher {
  display: none;
}
/deep/.ant-tree-switcher {
  line-height: 50px !important;
}
/deep/.ant-tree-node-content-wrapper {
  line-height: 50px !important;
}
/deep/.ant-tree > .ant-tree-treenode-switcher-open {
  ul {
    ul {
      ul {
        li {
          display: inline-block;
        }
      }
    }
  }
}
/deep/.ant-tree-title {
  line-height: 40px;
}
.iconback {
  margin: 10px 0;
  padding-top: 10px;
}
.addBottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  z-index: 999;
}
</style>