<template>
  <div>
    <a-modal v-model="visible" title="选择商户" @ok="handleOk" width="80%">
      <a-row style="margin-top: 10px">
        <a-input placeholder="商户号/商户名称/手机号" v-model="searchParams.keywords" style="width: 200px"></a-input>
        <a-button @click="getList(true)" style="margin-left: 10px">查询</a-button>
      </a-row>

      <a-table
        size="small"
        :loading="loading"
        :columns="columns"
        :data-source="dataList"
        :rowKey="record => record.seller_id"
        tableLayout="fixed"
        :row-selection="{ selectedRowKeys: selectedRowKeys, selectedRows: selectedRows, onChange: onSelectChange, type: 'radio' }"
        :pagination="{
          current: searchParams.page,
          pageSize: searchParams.limit,
          total: total,
          showLessItems: true,
          showQuickJumper: true,
          showTotal: (total, range) => `总计 ${total} 条`,
          onChange: onPageChange,
          onShowSizeChange: onPageChange,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          onShowSizeChange: onPageChange,
        }">
        <template slot="seller_id" slot-scope="text, record">
          <a :href="`https://manage.yunguos.com/user/login?seller_id=${record.seller_id}&account=17791285105`" target="_blank">{{ text }}</a>
        </template>
        <template slot="seller_name" slot-scope="text, record">
          <a :href="`https://manage.yunguos.com/user/login?seller_id=${record.seller_id}&account=17791285105`" target="_blank">{{ text }}</a>
        </template>
        <template slot="seller_login" slot-scope="text, record">
          <a :href="`https://manage.yunguos.com/user/login?seller_id=${record.seller_id}`" target="_blank">
            {{ `https://manage.yunguos.com/user/login?seller_id=${record.seller_id}` }}
          </a>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { SellerList } from "@/views/api/seller";
import { mixins } from "../../mixin/index";
import moment from "moment";

export default {
  mixins: [mixins],

  data() {
    return {
      columns: [
        {
          title: "商户ID",
          dataIndex: "seller_id",
          scopedSlots: {
            customRender: "seller_id",
          },
        },
        {
          title: "商户名称",
          dataIndex: "seller_name",
          scopedSlots: {
            customRender: "seller_name",
          },
        },
        {
          title: "联系人",
          dataIndex: "rename",
        },
        {
          title: "手机号",
          dataIndex: "mobile",
        },
        {
          title: "开始时间",
          dataIndex: "start_time",
          customRender: text => {
            return moment(text * 1000).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          title: "结束时间",
          dataIndex: "end_time",
          customRender: text => {
            return moment(text * 1000).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          title: "开户时间",
          dataIndex: "create_time",
        },
        {
          title: "门店数量",
          dataIndex: "store_num",
        },
        {
          title: "机器数量",
          dataIndex: "machine_num",
        },
        {
          title: "短信签名",
          dataIndex: "sms_sign",
        },
      ],

      dataList: [],
      total: 0,
      visible: false,

      selectedRowKeys: [],
      selectedRows: [],
    };
  },
  computed: {},
  methods: {
    getList(is_search) {
      this.loading = true;
      if (is_search) this.searchParams.page = 1;
      SellerList(this.searchParams).then(res => {
        this.dataList = res.data.list;
        this.total = res.data.count;
        this.loading = false;
      });
    },

    // 分页
    onPageChange(page, pageSize) {
      this.searchParams.page = page;
      this.searchParams.limit = pageSize;
      this.getList();
    },

    childMethod() {
      this.visible = true;
    },

    handleOk() {
      this.visible = false
      this.$emit("getSeller", this.selectedRows, this.selectedRowKeys);
    },

    onSelectChange(keys, rows) {
      this.selectedRowKeys = keys;
      if (keys.length == rows.length) {
        this.selectedRows = rows;
      } else {
        this.selectedRows = keys.map(key => this.selectedRows.find(row => row.seller_id == key) || rows.find(row => row.seller_id == key));
      }
    },
  },
  created() {},
};
</script>
<style lang="less" scoped></style>
