<template>
  <div>
    <a-button type="primary"  style="margin-bottom: 10px" @click="back">返回</a-button>
    <a-card>
      <a-row style="margin-bottom: 10px">
        <a-input placeholder="请输入商户号/门店名称" v-model="searchParams.keywords" style="width: 220px; margin-right: 20px"></a-input>

        订单状态:
        <a-select allowClear placeholder="请选择订单状态" v-model="searchParams.pay_status" style="width: 180px">
          <a-select-option :value="1">已支付</a-select-option>
          <a-select-option :value="0">未支付</a-select-option>
        </a-select>

        <a-select allowClear placeholder="请选择" v-model="order_type" style="width: 180px">
          <a-select-option :value="1">续费单号</a-select-option>
          <a-select-option :value="2">支付流水号</a-select-option>
        </a-select>
        <a-input placeholder="请输入" v-model="order_id" style="width: 220px; margin-right: 20px"></a-input>

        销售类型:
        <a-select allowClear placeholder="请选择销售类型" v-model="searchParams.sale_type" style="width: 180px">
          <a-select-option :value="1">云果直销</a-select-option>
          <a-select-option :value="2">代理商直销</a-select-option>
        </a-select>

        <a-select allowClear v-if="searchParams.sale_type == 2" placeholder="请选择" mode="multiple" v-model="searchParams.agent_ids" style="margin: 0 20px 20px 0; width: 150px">
          <a-select-option v-for="item in agent_ids_list" :key="item.agent_id" :value="item.agent_id"> {{ item.agent_name }} </a-select-option>
        </a-select>
        <a-button @click="getList" style="margin-left: 10px">查询</a-button>
        <a-button @click="exportList" style="margin-left: 10px">导出</a-button>
      </a-row>

      <a-table
        style="padding: 0"
        :loading="loading"
        size="small"
        :columns="columns"
        :data-source="dataList"
        :rowKey="record => record.path_id"
        :pagination="{
          current: searchParams.page,
          pageSize: searchParams.limit,
          total: total,
          showLessItems: true,
          showQuickJumper: true,
          showTotal: (total, range) => `总计 ${total} 条`,
          onChange: onPageChange,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          onShowSizeChange: onPageChange,
        }">
        <template slot="action" slot-scope="text, record">
          <a v-if="record.pay_status == 0" @click="edit(record)" style="margin-right: 10px">修改</a>
        </template>
      </a-table>

      <div style="font-size: 18px; font-weight: 500">所有页合计: {{ total_amount }}</div>
    </a-card>

    <a-modal v-model="visible" :title="`${modalText}续费`" @ok="handleOk" @cancel="handleCancel">
      <a-form-model ref="ruleForm" :form="form" :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16, offset: 1 }">
        <a-form-model-item label="续费门店">
          {{ store_num }}
        </a-form-model-item>

        <a-form-model-item label="续费时间" v-if="renewal_model == 1">
          <a-select placeholder="请选择续费时间" v-model="form.renew_time">
            <a-select-option :value="12">12个月</a-select-option>
            <a-select-option :value="24">24个月</a-select-option>
            <a-select-option :value="36">36个月</a-select-option>
            <a-select-option :value="60">60个月</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="门店续费期限统一截止时间" v-if="renewal_model == 2">
          <a-date-picker v-model="form.uniform_date" />
        </a-form-model-item>

        <a-form-model-item label="续费金额" prop="name">
          <a-input placeholder="请输入续费金额" v-model="form.renew_money" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { SellerRenewlist, SellerRenewedit, agentlist, SellerRenewexportList } from "@/views/api/seller";
import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins],
  data() {
    return {
      form: {},
      modalText: "",
      columns: [
        {
          title: "序号",
          customRender: (text, record, index) => {
            return index + 1;
          },
        },
        {
          title: "续费单号",
          dataIndex: "order_id",
          width: 200,
        },
        {
          title: "销售类型",
          dataIndex: "sale_type",
          customRender: text => {
            return text == 1 ? "云果直销" : "代理商直销";
          },
        },
        {
          title: "续费门店数量",
          dataIndex: "store_num",
        },
        {
          title: "订单金额",
          dataIndex: "amount",
        },
        {
          title: "订单状态",
          dataIndex: "pay_status",
          customRender: text => {
            return text == 1 ? "已支付" : "未支付";
          },
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
        },
        {
          title: "支付方式",
          dataIndex: "pay_type",
          customRender: text => {
            return text == 1 ? "对公付款到西安银行" : text == 2 ? "企业支付宝付款" : text == 3 ? "对公付款到建设银行" : "免支付";
          },
        },
        {
          title: "支付来源",
          dataIndex: "pay_source",
          customRender: text => {
            return text == 1 ? "boss后台" : text == 2 ? "管理后台" : text == 3 ? "pos收银" : "";
          },
        },
        {
          title: "第三方订单号",
          dataIndex: "trade_no",
        },
        {
          title: "支付时间",
          dataIndex: "pay_time",
        },
        {
          title: "操作",
          fixed: "right",
          scopedSlots: {
            customRender: "action",
          },
        },
      ],

      selectedRowKeys: [],
      selectedRows: [],
      seller: {},

      store: [],
      store_id_list: [],
      pay_type_list: [],

      seller_id: "",
      store_num: "",
      renewal_model: "",
      agent_ids_list: [],
      total_amount: "",
      order_type: 1,
      order_id: "",
    };
  },
  computed: {},
  methods: {
    getList() {
      if (!this.seller_id) return;
      this.loading = true;
      this.searchParams.seller_id = this.seller_id;
      if (this.order_type == 2) this.searchParams.trade_no = this.order_id;
      if (this.order_type == 1) this.searchParams.order_id = this.order_id;
      SellerRenewlist(this.searchParams).then(res => {
        this.dataList = res.data.list;
        this.total = res.data.count;
        this.total_amount = res.data.total_amount;
        this.loading = false;
      });
    },

    agentlist() {
      if (this.agent_ids_list.length != 0) return;
      agentlist({ page: 0, limit: 0 }).then(res => {
        this.agent_ids_list = res.data.list;
        this.loading = false;
      });
    },

    // 确认
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) return this.addEdit();
        return false;
      });
    },

    // 添加编辑
    addEdit() {
      SellerRenewedit(this.form).then(res => this.handleSuccess(1, res));
    },

    // 操作成功
    handleSuccess(flag, res) {
      if (res.code == 200) {
        this.$message.success(`编辑成功`);
        this.visible = false;
        this.getList();
      }
    },

    // 弹框关闭
    handleCancel() {
      this.form = {};
      this.visible = false;
    },

    // 续费数据
    renewData() {},

    // 新增续费
    edit(row) {
      this.form = {};
      const { amount, renew_time, order_id } = row;
      this.form = { renew_money: amount, renew_time, order_id };
      this.store_num = row.store_num;
      this.renewal_model = row.seller_renewal_model;
      this.visible = true;
    },

    // 导出
    exportList() {
      SellerRenewexportList(this.searchParams).then(result => {
        if (result.code == 200) window.open(result.data.download_url);
      });
    },

    back() {
      this.$router.go(-1)
    }
  },

  created() {
    this.seller_id = this.$route.query.seller_id;
    this.getList();
    this.agentlist();
  },
};
</script>
<style lang="less" scoped></style>
