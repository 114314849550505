<template>
  <div>
    <a-card>
      <a-form-model ref="ruleForm" :form="form" :model="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 8, offset: 1 }">
        <a-form-model-item label="续费用户">
          <span v-if="form.seller_name" style="margin-right: 15px">{{ form.seller_name }}</span>
          <a @click="openSeller">{{ form.seller_name ? "修改" : "选择商户" }}</a>
        </a-form-model-item>

        <a-form-model-item label="续费门店" v-if="form.seller_name">
          <a @click="openStore">选择门店</a>
          <br />
          <a-tag v-for="item in form.store" :key="item.store_id" closable @close="delStore(item.store_id)"> {{ item.store_name }} </a-tag>
        </a-form-model-item>

        <a-form-model-item label="续费时间" v-if="renewal_model == 1">
          <a-select placeholder="请选择续费时间" v-model="form.renew_time" @change="mathMoney">
            <a-select-option :value="12">12个月</a-select-option>
            <a-select-option :value="24">24个月</a-select-option>
            <a-select-option :value="36">36个月</a-select-option>
            <a-select-option :value="60">60个月</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="续费统一截止时间" v-if="renewal_model == 2">
          <a-date-picker v-model="form.uniform_date" show-time />
        </a-form-model-item>

        <a-form-model-item label="续费金额" prop="name">
          <a-input placeholder="请输入续费金额" v-model="form.renew_money" @change="$forceUpdate()" />
        </a-form-model-item>

        <a-form-model-item label="支付方式">
          <a-select placeholder="请选择支付方式" v-model="form.pay_type">
            <a-select-option v-for="item in pay_type_list" :key="item.pay_type" :value="item.pay_type">{{ item.pay_type_name }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="备注信息" prop="path">
          <a-input placeholder="请输入备注信息" v-model="form.notice" />
        </a-form-model-item>

        <a-form-model-item label="支付凭证" prop="path">
          <a-upload :customRequest="handleChange" list-type="picture-card" :before-upload="beforeUpload" :show-upload-list="false">
            <img v-if="voucher" :src="voucher" class="avatar" />
            <div v-else>
              <div class="ant-upload-text">点击上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-card>

    <a-card class="m-t-10">
      <a-row>
        <a-col :offset="3">
          <a-button type="primary" @click="handleOk">提交</a-button>
          <a-button style="margin-left: 15px" @click="back()">取消</a-button>
        </a-col>
      </a-row>
    </a-card>

    <seller ref="seller" @getSeller="getSeller"></seller>
    <store ref="store" @getStore="getStore"></store>
  </div>
</template>

<script>
import { sellerRenewpayTypeList, SellerRenewadd } from "@/views/api/seller";
import { image } from "@/views/api/common";
import seller from "./seller-modal.vue";
import store from "./store_modal.vue";
import moment from "moment";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default {
  components: { seller, store },
  data() {
    return {
      visible: false,
      params: "",
      form: {
        renew_time: 1,
      },
      unit_list: [],
      url: "",
      store: [],
      store_id_list: [],
      pay_type_list: [],
      renewal_money: 0,
      renewal_model: 0,
      voucher: "",
      base_renew_money: "",
    };
  },
  computed: {},
  methods: {
    moment,
    // 取消 - 返回
    back() {
      this.$router.go(-1);
    },

    openSeller() {
      this.$refs.seller.childMethod();
    },

    getSeller(seller_list) {
      if (!seller_list[0]) return;
      this.seller = seller_list[0];
      this.renewal_model = this.seller.renewal_model;
      this.renewal_money = this.seller.renewal_money;
      this.form.seller_name = this.seller.seller_name;
      this.form.seller_id = this.seller.seller_id;
      this.form.renew_money = this.seller.renewal_money;
      this.base_renew_money = JSON.parse(JSON.stringify(this.seller.renewal_money));

      this.form.store = [];
      this.form.store_ids = [];
    },

    openStore() {
      console.log(this.form.seller_id);
      this.$refs.store.childMethod(this.form.seller_id, this.form.store, this.form.store_ids);
    },

    getStore(store_list, store_id_list) {
      this.form.store = store_list;
      console.log(store_id_list);
      this.form.store_ids = store_id_list;
      if (this.renewal_model == 1) this.mathMoney()
      this.mathMoney();
      this.$forceUpdate();
    },

    delStore(store_id) {
      this.form.store = this.form.store.filter(item => item.store_id != store_id);
      this.form.store_ids = this.form.store_ids.filter(item => item != store_id);
      if (this.renewal_model == 1) this.mathMoney()
      
      this.$forceUpdate();
    },

    // 上传前钩子
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        const type = file.type;
        const isJpgOrPng = type === "image/jpeg" || type === "image/png" || type === "image/jpg";
        if (!isJpgOrPng) {
          this.$message.error("只能上传jpeg、png、jpg文件!");
          return reject(false); //如有问题通过reject 函数传一个false阻止他的上传
        } else {
          return resolve(true);
        }
      });
    },

    // 上传
    async handleChange({ file }) {
      this.loading = true;
      getBase64(file, async imageUrl => {
        var params = { name: file.name, url: imageUrl };
        await image(params).then(res => {
          if (res.code == 200) {
            this.voucher = imageUrl;
            this.form.voucher = res.data.path;
          }
          this.loading = false;
        });
      });
    },

    // 确认
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) return this.addEdit();
        return false;
      });
    },

    // 添加编辑
    addEdit() {
      const params = this.form;
      params.uniform_date = moment(this.form.uniform_date).format("YYYY-MM-DD HH:mm:ss");
      SellerRenewadd(this.form).then(res => this.handleSuccess(1, res));
    },

    // 操作成功
    handleSuccess(flag, res) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? "编辑" : "添加"}成功`);
        this.back();
      }
    },

    sellerRenewpayTypeList() {
      sellerRenewpayTypeList({}).then(res => {
        this.pay_type_list = res.data;
      });
    },

    mathMoney() {
      if (this.renewal_model == 2) return;
      const num = this.form.renew_time / 12;
      this.form.renew_money = this.base_renew_money * num * this.form.store_ids.length;
      this.$forceUpdate();
    },
  },

  created() {
    this.sellerRenewpayTypeList();
    this.form = this.$route.params;
    console.log(this.form);
    this.renewal_model = this.$route.params.seller_renewal_model;
    if (this.form.store) this.form.renew_money = this.form.store[0].renew_money;
    if (this.form.store) this.base_renew_money = JSON.parse(JSON.stringify(this.form.store[0].renew_money));
    this.form.store_ids = [];
    if (this.form.store_id_list) {
      this.form.store_id_list.forEach(item => {
        this.form.store_ids.push(item);
      });
    }

    if (!this.form.renew_time) this.form.renew_time = 12;
    this.mathMoney();
  },
};
</script>
<style scoped>
.ant-form-item-label {
  text-align: center !important;
}
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 4px;
}
.pic {
  width: 150px;
  height: 150px;
}
</style>
