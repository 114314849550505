<template>
  <div>
    <a-card>
      <a-button type="primary" @click="addPosition(1)">新增广告位置</a-button>
      <!-- 广告位置列表 -->
      <a-table :loading="loading" :columns="columns" :data-source="dataList" :rowKey="(record) => record.position_id" tableLayout="fixed" :pagination="{
            current: searchParams.page,
            pageSize: searchParams.limit,
            total: total,
            showLessItems: true,
            showQuickJumper: true,
            showTotal: (total, range) => `总计 ${total} 条`,
            onChange: onPageChange,
            showSizeChanger: true,
	          pageSizeOptions: ['10', '20', '50'],
            onShowSizeChange: onPageChange,
      }">
        <template slot="action" slot-scope="text, record">
          <a @click="addPosition(2,record)" style="margin-right:10px;">编辑</a>
          <a-popconfirm title="确定删除该广告位置吗?" ok-text="是" cancel-text="否" @confirm="confirm(record.position_id)">
            <a class="error-color">删除</a>
          </a-popconfirm>
        </template>
      </a-table>
    </a-card>
    <!-- 添加广告位置 -->
    <a-modal v-model="visible" :title='`${modalText}广告位置`' @ok="handleOk" @cancel="handleCancel">
      <a-form-model ref="ruleForm" :form="form" :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16, offset: 1 }">
        <a-form-model-item label="广告位置名称" prop='name'>
          <a-input placeholder="请输入广告位置名称" v-model="form.name" show-word-limit />
        </a-form-model-item>
        <a-form-model-item label="宽度" prop='width'>
          <a-input placeholder="请输入宽度" v-model="form.width" show-word-limit />
        </a-form-model-item>
        <a-form-model-item label="高度">
          <a-input-number v-model="form.height" :min="1" placeholder="请输入高度" style="width:100%" />
        </a-form-model-item>
        <a-form-model-item label="终端">
          <a-select placeholder="请选择终端" v-model="form.terminal">
            <a-select-option :value="1">后台</a-select-option>
            <a-select-option :value="2">收银</a-select-option>
            <a-select-option :value="3">小程序</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="启用状态">
          <a-switch v-if="visible" :checked="form.status == 0 ? false : true" @change="statusChange" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { PositionList,PositionAdd,PositionEdit,PositionDel } from '@/views/api/system'
import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
      form: {
        status: 0
      },
      modalText: '',
      columns: [
      {
        title: '广告位置ID',
        dataIndex: 'position_id',
      },
      {
        title: '广告位置名称',
        dataIndex: 'name',
      },
      {
        title: '宽度',
        dataIndex: 'width',
      },
      {
        title: '高度',
        dataIndex: 'height',
      },
      {
        title: '终端',
        dataIndex: 'terminal',
        customRender: (text) => {
        return text == 1 ? "后台" : text == 2 ? "收银" : '小程序';
        },
      },
      {
        title: '状态',
        dataIndex: 'status',
        customRender: (text) => {
        return text == 1 ? "显示" : '隐藏';
        },
      },
      {
        title: '操作',
        scopedSlots: {
          customRender: 'action'
        }
      }],
    };
  },
  computed: {},
  methods: {
    getList() {
      this.loading = true
      PositionList(this.searchParams).then(res=>{
        this.dataList = res.data.list
        this.total = res.data.count
        this.loading = false
      })
    },
    // 确认
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) return this.addEdit();
        return false;
      });
    },
    // 添加编辑
    addEdit() {
      this.flag == 1 ? PositionAdd(this.form).then((res) => this.handleSuccess(1,res)) : PositionEdit(this.form).then((res) => this.handleSuccess(2,res));
    },
    // 操作成功
    handleSuccess(flag,res) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? '添加' : flag == 2 ? '编辑' : flag == 3 ? '删除' : '修改状态'}成功`);
        this.visible = false;
        this.getList()
      }
    },
    // 弹框关闭
    handleCancel() {
      this.visible = false;
    },
    // 新增广告位置
    addPosition(flag,row) {
      this.form = {}
      this.flag = flag;
      this.visible = true;
      if (row) {
        this.form = JSON.parse(JSON.stringify(row));
        this.modalText = "编辑";
      } else {
        this.form.status = 1
        this.modalText = "新增";
      }
    },
    // 删除
    confirm(position_id) {
      PositionDel({ position_id }).then((res) => this.handleSuccess(3,res));
    },
    // 弹框启用状态
    statusChange(checked) {
      this.$forceUpdate()
      checked ? this.form.status = 1 : this.form.status = 0
    },
  },
  created() {
    this.getList()
  },
};
</script>
<style lang="less" scoped>
</style>