var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-card',[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.addWxPath(1)}}},[_vm._v("新增导航路径")]),_c('a-table',{attrs:{"loading":_vm.loading,"columns":_vm.columns,"data-source":_vm.dataList,"rowKey":function (record) { return record.path_id; },"tableLayout":"fixed","pagination":{
            current: _vm.searchParams.page,
            pageSize: _vm.searchParams.limit,
            total: _vm.total,
            showLessItems: true,
            showQuickJumper: true,
            showTotal: function (total, range) { return ("总计 " + total + " 条"); },
            onChange: _vm.onPageChange,
            showSizeChanger: true,
	          pageSizeOptions: ['10', '20', '50'],
            onShowSizeChange: _vm.onPageChange,
      }},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return [_c('a',{staticStyle:{"margin-right":"10px"},on:{"click":function($event){return _vm.addWxPath(2,record)}}},[_vm._v("编辑")]),_c('a-popconfirm',{attrs:{"title":"确定删除该导航路径吗?","ok-text":"是","cancel-text":"否"},on:{"confirm":function($event){return _vm.confirm(record.path_id)}}},[_c('a',{staticClass:"error-color"},[_vm._v("删除")])])]}}])})],1),_c('a-modal',{attrs:{"title":(_vm.modalText + "导航路径")},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-form-model',{ref:"ruleForm",attrs:{"form":_vm.form,"model":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 16, offset: 1 }}},[_c('a-form-model-item',{attrs:{"label":"导航路径名称","prop":"name"}},[_c('a-input',{attrs:{"placeholder":"请输入导航路径名称","show-word-limit":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{attrs:{"label":"路径","prop":"path"}},[_c('a-input',{attrs:{"placeholder":"请输入路径","show-word-limit":""},model:{value:(_vm.form.path),callback:function ($$v) {_vm.$set(_vm.form, "path", $$v)},expression:"form.path"}})],1),_c('a-form-model-item',{attrs:{"label":"类型"}},[_c('a-select',{attrs:{"placeholder":"请选择类型"},model:{value:(_vm.form.path_type),callback:function ($$v) {_vm.$set(_vm.form, "path_type", $$v)},expression:"form.path_type"}},[_c('a-select-option',{attrs:{"value":"navigateTo"}},[_vm._v("navigateTo")]),_c('a-select-option',{attrs:{"value":"redirectTo"}},[_vm._v("redirectTo")]),_c('a-select-option',{attrs:{"value":"switchTab"}},[_vm._v("switchTab")]),_c('a-select-option',{attrs:{"value":"reLaunch"}},[_vm._v("reLaunch")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }