<template>
    <div>
        <a-card>
            <a-button type="primary" @click="updateCache()">更新支付方式数据</a-button>
            <!-- 支付方式列表 -->
            <a-table :loading="loading" :columns="columns" :data-source="dataList" :rowKey="(record) => record.payment_id" tableLayout="fixed" :pagination="false">
                <template slot="action" slot-scope="text, record">
                    <img :src="record.payment_icon" alt="">
                </template>
                <template slot="status" slot-scope="text, record">
                    <a-switch :checked="record.status == 1 ? true : false" @change="statusChange($event,record)" />
                </template>
            </a-table>
        </a-card>
    </div>
</template>

<script>
import { paymentList,paymentStatus,updateCache } from '@/views/api/system'
import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
        columns: [
            {
              title: '支付方式ID',
              dataIndex: 'payment_id',
            },
            {
              title: '支付方式名称',
              dataIndex: 'payment_name',
            },
            {
              title: '图片',
              dataIndex: 'payment_icon',
              scopedSlots: {
                customRender: 'payment_icon'
              }
            },
            {
              title: '编码',
              dataIndex: 'payment_code',
            },
            {
              title: '简介',
              dataIndex: 'payment_info',
            },
            {
              title: '配置信息',
              dataIndex: 'payment_config',
            },
            {
              title: '状态',
              dataIndex: 'status',
              scopedSlots: {
              customRender: 'status'
            }
            }
        ],
    };
  },
  computed: {},
  methods: {
    getList() {
      this.loading = true
      paymentList(this.searchParams).then(res=>{
        this.dataList = res.data
        this.total = res.data.count
        this.loading = false
      })
    },
    // 更新支付方式
    updateCache() {
        updateCache({}).then(res=>{
            if (res.code == 200) this.$message.success('更新支付方式数据成功')
        })
    },
    // 修改状态
    statusChange(e,record) {
      e ? record.status = 1 : record.status = 0
      paymentStatus({status: record.status,payment_id: record.payment_id}).then((res) => {
          if (res.code == 200) {
              this.$message.success('修改状态成功')
              this.getList()
          }
      });
    },
  },
  created() {
    this.getList()
  },
};
</script>
<style lang="less" scoped>
</style>