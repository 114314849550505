<template>
  <div>
    <a-card>
      <div class="title">{{ params ? "编辑" : "添加" }}代理商</div>
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
        <a-form-model-item label="代理商名称" prop="agent_name">
          <a-input placeholder="请输入代理商名称" v-model="form.agent_name" />
        </a-form-model-item>

        <a-form-model-item label="省市区" prop="city" style="width: 35%" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
          <a-select placeholder="请选择省" v-model="form.province_id" style="width: 33.3%" @change="AreaList(1)">
            <a-select-option v-for="d in province_List" :key="d.area_id">
              {{ d.area_name }}
            </a-select-option>
          </a-select>
          <a-select placeholder="请选择市" v-model="form.city_id" style="width: 33.3%" @change="AreaList(2)">
            <a-select-option v-for="d in city_List" :key="d.area_id">
              {{ d.area_name }}
            </a-select-option>
          </a-select>
          <a-select placeholder="请选择区" v-model="form.district_id" style="width: 33.3%" @change="AreaList(3)">
            <a-select-option v-for="d in district_List" :key="d.area_id" :value="d.area_id">
              {{ d.area_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="详细地址" prop="address" style="width: 33%">
          <a-input placeholder="请输入详细地址" v-model="form.address" />
        </a-form-model-item>

        <a-form-model-item label="代理商经理" prop="manager_name">
          <a-select placeholder="请选择" v-model="form.manager_name">
            <a-select-option value="张峰瑜"> 张峰瑜 </a-select-option>
            <a-select-option value="杨文涛"> 杨文涛 </a-select-option>
            <a-select-option value="史新旺"> 史新旺 </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="联系人姓名" prop="contact_name">
          <a-input placeholder="请输入姓名" v-model="form.contact_name" />
        </a-form-model-item>

        <a-form-model-item label="手机号码" prop="mobile">
          <a-input placeholder="请输入手机号码" v-model="form.mobile" />
        </a-form-model-item>

        <a-form-model-item label="选择时间" prop="time" style="width: 33%" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
          <a-date-picker v-model="start" placeholder="请选择开始时间" :allowClear="false" @change="onStartChange" style="width: 46%" />
          <span style="width: 3%; margin: 0 1%">-</span>
          <a-date-picker v-model="end" placeholder="请选择结束时间" :allowClear="false" @change="onEndChange" style="width: 46%" />
        </a-form-model-item>

        <a-form-model-item label="营业执照" prop="certificates">
          <div class="clearfix">
            <a-upload :customRequest="handleChange" list-type="picture-card" :before-upload="beforeUpload" :show-upload-list="false">
              <img v-if="certificates" :src="certificates" class="avatar" />
              <div v-else>
                <div class="ant-upload-text">点击上传</div>
              </div>
            </a-upload>
          </div>
        </a-form-model-item>

        <a-form-model-item label="法人身份证(正)" prop="idcard_pros">
          <div class="clearfix">
            <a-upload :customRequest="handleChangepros" list-type="picture-card" :before-upload="beforeUpload" :show-upload-list="false">
              <img v-if="idcard_pros" :src="idcard_pros" class="avatar" />
              <div v-else>
                <div class="ant-upload-text">点击上传</div>
              </div>
            </a-upload>
          </div>
        </a-form-model-item>

        <a-form-model-item label="法人身份证(反)" prop="idcard_cons">
          <div class="clearfix">
            <a-upload :customRequest="handleChangecons" list-type="picture-card" :before-upload="beforeUpload" :show-upload-list="false">
              <img v-if="idcard_cons" :src="idcard_cons" class="avatar" />
              <div v-else>
                <div class="ant-upload-text">点击上传</div>
              </div>
            </a-upload>
          </div>
        </a-form-model-item>

        <a-form-model-item label="备注" prop="notice">
          <a-textarea v-model="form.notice"></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-card>
    <a-card class="m-t-10">
      <a-row>
        <a-col :offset="3">
          <a-button style="margin-right: 15px" @click="back()">取消</a-button>
          <a-button type="primary" @click="addEdit" :loading="loading">提交</a-button>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { paymentName, AreaList, image } from "@/views/api/common";
import { agentadd, agentedit, agentdetail } from "@/views/api/seller";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

import moment from "moment";

export default {
  data() {
    let validateTime = (rule, value, callback) => {
      if (this.form.sms_type && !this.form.sms_sign) {
        return callback(new Error("请选择开始时间"));
      } else if (!this.end) {
        return callback(new Error("请选择结束时间"));
      } else {
        return callback();
      }
    };
    let validateCity = (rule, value, callback) => {
      if (!this.form.province_id || !this.form.city_id || !this.form.district_id) {
        return callback(new Error("请选择省市区"));
      } else {
        return callback();
      }
    };
    // let validchecksignValidatorateCity = (rule, value, callback) => {
    //   if (!this.form.province_id || !this.form.city_id || !this.form.district_id) {
    //     return callback(new Error("请选择省市区"));
    //   } else {
    //     return callback();
    //   }
    // };
    // 检验手机号
    let checkMobileValidator = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入11位手机号"));
      } else {
        const reg = /^1[3456789]\d{9}$/;
        if (!reg.test(value)) {
          return callback(new Error("请输入正确的手机号"));
        } else {
          return callback();
        }
      }
    };

    return {
      params: "",
      form: {
        seller_id: "",
        start_time: null,
        end_time: null,
        payment_num: [1, 2, 8, 16],
        sms_type: 0,
        store_num: 1,
        machine_num: 2,
        voice_num: 0,
        sub_products: [],
        products: [],
      },
      payment_disabled: [1, 2, 8, 16],
      start: null,
      end: null,
      paymentList: [],
      province_List: [],
      city_List: [],
      district_List: [],
      idcard_cons: "",
      idcard_pros: "",
      certificates: "",
      fileList: [],
      add_time_s: [],
      loading: false,

      rules: {
        agent_name: { required: true, message: "请输入商户号", trigger: "change" },
        address: { required: true, message: "请输入详细地址", trigger: "change" },
        manager_name: { required: true, message: "请选择代理商经理名称", trigger: "change" },
        time: { required: true, validator: validateTime, trigger: "change" },
        city: { required: true, validator: validateCity, trigger: "change" },
        contact_name: { required: true, message: "联系人姓名", trigger: "change" },
        mobile: { required: true, validator: checkMobileValidator, trigger: "change" },
      },
    };
  },
  computed: {},
  methods: {
    moment,
    // 支付方式
    paymentName() {
      paymentName({}).then(res => {
        this.paymentList = res.data;
        this.paymentList.forEach(item => {
          item.value = item.payment_type;
          item.label = item.payment_name;
        });
      });
    },

    // 详情
    agentdetail() {
      agentdetail({ agent_id: this.form.agent_id }).then(res => {
        if (!res.data.sub_products) res.data.sub_products = [];
        this.form = res.data;
        this.idcard_cons = res.data.idcard_cons_full;
        this.idcard_pros = res.data.idcard_pros_full;
        this.certificates = res.data.certificates_full;
        this.onStartChange("", moment(res.data.start_time * 1000));
        this.onEndChange("", moment(res.data.end_time * 1000));
        AreaList({ area_id: res.data.province_id }).then(result => {
          this.city_List = result.data;
        });
        AreaList({ area_id: res.data.city_id }).then(result => {
          this.district_List = result.data;
        });
      });
    },

    // 省市区
    AreaList(area_id) {
      const area = area_id;
      if (area_id == 1) {
        area_id = this.form.province_id;
        this.city_List = [];
        this.form.city_id = undefined;
        this.district_List = [];
        this.form.district_id = undefined;
      }
      if (area_id == 2) {
        area_id = this.form.city_id;
        this.district_List = [];
        this.form.district_id = undefined;
      }
      AreaList({ area_id: area_id }).then(res => {
        if (area == 0) this.province_List = res.data;
        if (area == 1) this.city_List = res.data;
        if (area == 2) this.district_List = res.data;
      });
      if (area_id == 3) this.changeCity();
      this.$forceUpdate();
    },

    onStartChange(date, dateString) {
      this.start = moment(dateString);
      this.form.start_time = moment(dateString).unix();
    },

    onEndChange(date, dateString) {
      this.end = moment(dateString);
      this.form.end_time = moment(dateString).unix();
    },

    // 取消 - 返回
    back() {
      this.$router.go(-1);
    },

    // 添加编辑
    addEdit() {
      this.$refs.ruleForm.validate(valid => {
        console.log(valid);
        if (!valid) {
          return false;
        } else {
          this.params ? agentedit(this.form).then(res => this.handleSuccess(1, res)) : agentadd(this.form).then(res => this.handleSuccess(2, res));
        }
      });
    },

    changeCity() {
      this.$nextTick(() => {
        this.$refs.ruleForm.validate(valid => {
          if (!valid) {
            return false;
          } else {
            return true;
          }
        });
      });
    },

    // 操作成功
    handleSuccess(flag, res) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? "编辑" : "添加"}成功`);
        this.back();
      }
    },

    // 上传前钩子
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        const type = file.type;
        const isJpgOrPng = type === "image/jpeg" || type === "image/png" || type === "image/jpg";
        if (!isJpgOrPng) {
          this.$message.error("只能上传jpeg、png、jpg文件!");
          return reject(false); //如有问题通过reject 函数传一个false阻止他的上传
        } else {
          return resolve(true);
        }
      });
    },

    // 上传
    async handleChange({ file }) {
      this.loading = true;
      getBase64(file, async imageUrl => {
        var params = { name: file.name, url: imageUrl };
        await image(params).then(res => {
          if (res.code == 200) {
            this.certificates = imageUrl;
            this.form.business_license = res.data.path;
          }
          this.loading = false
        });
      });
    },

    // 上传
    async handleChangecons({ file }) {
      this.loading = true;
      getBase64(file, async imageUrl => {
        var params = { name: file.name, url: imageUrl };
        await image(params).then(res => {
          if (res.code == 200) {
            this.idcard_cons = imageUrl;
            this.form.id_card_img_back = res.data.path;
          }
          this.loading = false
        });
      });
    },

    // 上传
    async handleChangepros({ file }) {
      this.loading = true;
      getBase64(file, async imageUrl => {
        var params = { name: file.name, url: imageUrl };
        await image(params).then(res => {
          if (res.code == 200) {
            this.idcard_pros = imageUrl;
            this.form.id_card_img_front = res.data.path;
          }
          this.loading = false
        });
      });
    },
  },
  created() {
    this.paymentName();
    this.AreaList(0);
    this.params = this.$route.query.row;
    if (this.params) {
      this.form = this.params;
      this.agentdetail();
    } else {
      this.onStartChange("", moment());
      this.onEndChange("", moment().add(1, "y"));
    }
  },
};
</script>
<style scoped lang="less">
.ant-form-item-label {
  text-align: center !important;
}
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 4px;
}

/deep/ .ant-form-item {
  width: 22%;
  display: inline-block;
}
</style>
