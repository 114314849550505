<template>
  <div class="home">
    <a-layout>
      <a-layout-sider width="160px">
        <div class="logo">云果BOSS后台</div>
        <a-menu mode="inline" v-model="selectedKeys" :inline-collapsed="collapsed">
          <!-- <a-menu-item key="1"> -->
          <!-- <a-icon type="pie-chart" /> -->
          <!-- <span>主页</span> -->
          <!-- </a-menu-item> -->
          <a-sub-menu v-for="item in menu_list" :key="item.node_id">
            <span slot="title">
              <!-- <a-icon type="mail" /> -->
              <span>{{ item.node_name }}</span>
            </span>
            <a-menu-item v-for="itemm in item.children" :key="itemm.node_id" @click.native="toPath(itemm.key, item.node_name, itemm.node_name, itemm)">
              {{ itemm.node_name }}
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout>
        <a-layout-header>
          <a-breadcrumb style="width: 90%; display: inline-block">
            <a-breadcrumb-item><a @click="gohome">首页</a></a-breadcrumb-item>
            <a-breadcrumb-item v-if="breadcrumb_name">{{ breadcrumb_name }}</a-breadcrumb-item>
            <a-breadcrumb-item v-if="breadcrumb_son_name">{{ breadcrumb_son_name }}</a-breadcrumb-item>
          </a-breadcrumb>
          <a class="logout" @click="logout" style="color: #999">退出登录</a>
        </a-layout-header>
        <a-layout-content>
          <div class="main">
            <router-view />
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
    <!-- nav -->
    <!-- <div class="nav"> -->
    <!-- <a-button type="primary" style="margin-bottom: 16px" @click="toggleCollapsed">
        <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
      </a-button> -->
    <!-- </div> -->
    <!-- breadcrumb -->
  </div>
</template>

<script>
// @ is an alias to /src
import { menu, logout } from "@/views/api/common";
export default {
  name: "Home",
  data() {
    return {
      collapsed: false,
      menu_list: [],
      openKeys: [],
      selectedKeys: [Number(sessionStorage.getItem("selectedKeys"))],
      breadcrumb_name: sessionStorage.getItem("name"),
      breadcrumb_son_name: sessionStorage.getItem("son_name"),
    };
  },

  watch: {
    $route: {
      handler() {
        this.selectedKeys = [Number(sessionStorage.getItem("selectedKeys"))]
        this.breadcrumb_name = sessionStorage.getItem("name")
        this.breadcrumb_son_name = sessionStorage.getItem("son_name")
      }
    }
  },
  created() {
    this.menu();
  },
  methods: {
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
    menu() {
      menu({}).then(res => {
        this.listToTree(res.data, [], 0);
      });
    },
    toPath(key, name, son_name, item) {
      console.log(item);
      // const path = '/' + name + '/' + son_name
      // this.openKeys = path
      sessionStorage.setItem("name", name);
      sessionStorage.setItem("son_name", son_name);
      sessionStorage.setItem("selectedKeys", item.node_id);

      // this.breadcrumb_name = sessionStorage.getItem('name')
      // this.breadcrumb_son_name = sessionStorage.getItem('son_name')
      this.$router.push(`/${key}`);
    },
    // 数组转树形结构
    listToTree(list, tree, parent_id) {
      list.forEach(item => {
        // 判断是否为父级菜单
        if (item.parent_id === parent_id) {
          const child = {
            ...item,
            key: item.controller || item.name,
            children: [],
          };
          // 迭代 list， 找到当前菜单相符合的所有子菜单
          this.listToTree(list, child.children, item.node_id);
          // 删掉不存在 children 值的属性
          if (child.children.length <= 0) {
            delete child.children;
          }
          // 加入到树中
          tree.push(child);
          this.menu_list = tree;
        }
      });
    },
    gohome() {
      this.$router.push("/Homepage");
    },
    // 退出
    logout() {
      logout({}).then(res => {
        if (res.code == 200) {
          this.$router.push("/login");
        }
      });
    },
  },
};
</script>

<style lang="less">
.home {
  height: 100%;
}
.ant-breadcrumb {
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  text-align: left;
  line-height: 60px;
  background: #fff;
}
.ant-menu {
  height: 100%;
  padding-top: 60px;
}
.logo {
  position: absolute;
  height: 60px;
  width: 160px;
  z-index: 99;
  text-align: center;
  line-height: 60px;
}

#components-layout-demo-basic {
  text-align: center;
}
#components-layout-demo-basic .ant-layout-header,
#components-layout-demo-basic .ant-layout-footer {
  background: #7dbcea;
  color: #fff;
}
#components-layout-demo-basic .ant-layout-footer {
  line-height: 1.5;
}

#components-layout-demo-basic .ant-layout-sider {
  background: #3ba0e9;
  color: #fff;
  line-height: 120px;
}
#components-layout-demo-basic .ant-layout-content {
  background: rgba(16, 142, 233, 1);
  color: #fff;
  min-height: 120px;
  height: 100%;
  line-height: 120px;
}
#components-layout-demo-basic > .ant-layout {
  margin-bottom: 48px;
}
#components-layout-demo-basic > .ant-layout:last-child {
  margin: 0;
}
.ant-layout-header {
  background: #fff;
}
.ant-layout {
  height: 100%;
}
.main {
  padding: 10px 10px 0;
}
.logout {
  float: right;
}
</style>
