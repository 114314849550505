<template>
  <div>
    <a-card>
      <a-button type="primary" @click="addUnit(1)">新增出入库类型</a-button>
      <!-- 出入库类型列表 -->
      <a-table :loading="loading" :columns="columns" :data-source="dataList" :rowKey="(record) => record.cate_id" tableLayout="fixed" :pagination="{
            current: searchParams.page,
            pageSize: searchParams.limit,
            total: total,
            showLessItems: true,
            showQuickJumper: true,
            showTotal: (total, range) => `总计 ${total} 条`,
            onChange: onPageChange,
            showSizeChanger: true,
	          pageSizeOptions: ['10', '20', '50'],
            onShowSizeChange: onPageChange,
      }">
        <template slot="status" slot-scope="text, record">
          <a-switch :checked="record.status == 1 ? true : false" @change="WarehouseCateStatus($event,record)" />
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click="addUnit(2,record)" style="margin-right:10px;">编辑</a>
          <a-popconfirm title="确定删除该出入库类型吗?" ok-text="是" cancel-text="否" @confirm="confirm(record.cate_id)">
            <a class="error-color">删除</a>
          </a-popconfirm>
        </template>
      </a-table>
    </a-card>
    <!-- 添加出入库类型 -->
    <a-modal v-model="visible" :title='`${modalText}出入库类型`' @ok="handleOk" @cancel="handleCancel">
      <a-form-model ref="ruleForm" :form="form" :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16, offset: 1 }">
        <a-form-model-item label="父级ID">
          <a-select placeholder="请选择父级ID" v-model="form.parent_id">
            <a-select-option v-for="i in cateList" :key="i.cate_id">
              {{ i.cate_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="出入库类型名称" prop='cate_name'>
          <a-input placeholder="请输入出入库类型名称" v-model="form.cate_name" prop="cate_name" :maxLength="8" show-word-limit />
        </a-form-model-item>
        <a-form-model-item label="类型">
          <a-radio-group v-model="form.type">
            <a-radio :value="1">入库</a-radio>
            <a-radio :value="2">出库</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="状态" prop="status">
          <a-radio-group v-model="form.status">
            <a-radio :value="1">启用</a-radio>
            <a-radio :value="0">禁用</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="是否系统内置" prop="is_system">
          <a-radio-group v-model="form.is_system">
            <a-radio :value="1">是</a-radio>
            <a-radio :value="0">否</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="是否可加子级" prop="is_child">
          <a-radio-group v-model="form.is_child">
            <a-radio :value="1">是</a-radio>
            <a-radio :value="0">否</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="排序">
          <a-input-number v-model="form.sort" :min="1" placeholder="请输入序号" style="width:100%" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { WarehouseCateList,WarehouseCateAdd,WarehouseCateEdit,WarehouseCateDel,WarehouseCateStatus } from '@/views/api/system'
import { cateName } from '@/views/api/common'
import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
      from: {
        status: 1,
        type: 1,
        is_system: 1,
        is_child: 1
      },
      modalText: '',
      cateList: [],
      columns: [
      {
        title: '出入库类型ID',
        dataIndex: 'cate_id',
      },
      {
        title: '出入库类型名称',
        dataIndex: 'cate_name',
      },
      {
        title: '类型',
        dataIndex: 'type',
        customRender: (text) => {
        return text == 1 ? "入库" : "出库";
        },
      },
            {
        title: '排序',
        dataIndex: 'sort',
      },
      {
        title: '父级ID',
        dataIndex: 'parent_id',
      },
      {
        title: '状态',
        dataIndex: 'status',
        customRender: (text) => {
        return text == 0 ? "禁用" : "启用";
        },
      },
      {
        title: '系统内置',
        dataIndex: 'is_system',
        customRender: (text) => {
        return text == 0 ? "否" : "是";
        },
      },
      {
        title: '是否可加子级',
        dataIndex: 'is_child',
        customRender: (text) => {
        return text == 0 ? "否" : "是";
        },
      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
      },
      {
        title: '操作',
        scopedSlots: {
          customRender: 'action'
        }
      }],
    };
  },
  computed: {},
  methods: {
    getList() {
      this.loading = true
      WarehouseCateList(this.searchParams).then(res=>{
        this.dataList = res.data.list
        this.total = res.data.count
        this.loading = false
      })
    },
    cateName() {
      cateName({}).then(res=>{
        this.cateList = res.data.concat({cate_id: 0, cate_name: '顶级分类'})
      })
    },
    // 确认
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) return this.addEdit();
        return false;
      });
    },
    // 添加编辑
    addEdit() {
      this.flag == 1 ? WarehouseCateAdd(this.form).then((res) => this.handleSuccess(1,res)) : WarehouseCateEdit(this.form).then((res) => this.handleSuccess(2,res));
    },
    // 操作成功
    handleSuccess(flag,res) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? '添加' : flag == 2 ? '编辑' : flag == 3 ? '删除' : '修改状态'}成功`);
        this.visible = false;
        this.clearCheckForm();
        this.getList()
      }
    },
    // 清除form
    clearCheckForm() {
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });
    },
    // 弹框关闭
    handleCancel() {
      this.visible = false;
      this.clearCheckForm();
      this.form = {type: 1, sort:100, status: 1, is_system: 1, is_child: 1};
    },
    // 新增出入库类型
    addUnit(flag,row) {
      console.log(row)
      this.flag = flag;
      this.form = {type: 1, sort:100, status: 1, is_system: 1, is_child: 1};
      this.visible = true;
      if (row) {
        this.form = JSON.parse(JSON.stringify(row));
        this.modalText = "编辑";
      } else {
        this.modalText = "新增";
      }
    },
    // 删除
    confirm(cate_id) {
      WarehouseCateDel({ cate_id }).then((res) => this.handleSuccess(3,res));
    },
    // 修改状态
    WarehouseCateStatus(e,record) {
      e ? record.status = 1 : record.status = 0
      WarehouseCateStatus({status: record.status,cate_id: record.cate_id}).then((res) => this.handleSuccess(4,res));
    },
  },
  created() {
    this.getList()
    this.cateName()
  },
};
</script>
<style lang="less" scoped>
</style>