<template>
  <div>
    <a-card>
      <a-row style="margin-bottom: 10px">
        上报时间:
        <a-select placeholder="请选择" v-model="searchParams.time_type" @change="$forceUpdate()">
          <a-select-option value="create_time">创建时间</a-select-option>
          <a-select-option value="expire_time">到期时间</a-select-option>
        </a-select>
        <a-select v-model="ranges" :allowClear="false" style="width: 100px; display: inline-block" placeholder="请选择" @change="rangeChange">
          <a-select-option :value="1"> 今天 </a-select-option>
          <a-select-option :value="2"> 昨天 </a-select-option>
          <a-select-option :value="4"> 近七天 </a-select-option>
          <a-select-option :value="5"> 本周 </a-select-option>
          <a-select-option :value="6"> 上周 </a-select-option>
          <a-select-option :value="7"> 本月 </a-select-option>
          <a-select-option :value="8"> 上月 </a-select-option>
          <a-select-option :value="-2"> 自定义 </a-select-option>
        </a-select>
        <a-date-picker :locale="locale" :allowClear="false" v-model="start_time" placeholder="请选择" @change="onStartChange" style="width: 130px" :disabled-date="startDisabledDate" />
        <span style="width: 10px; margin: 0 4px">-</span>
        <a-date-picker :locale="locale" :allowClear="false" v-model="end_time" placeholder="请选择" @change="onEndChange" style="width: 130px; margin-right: 20px" :disabled-date="endDisabledDate" />

        <a-select placeholder="请选择" v-model="seller_type">
          <a-select-option value="seller_id">商户ID</a-select-option>
          <a-select-option value="seller_name">商户名称</a-select-option>
        </a-select>
        <a-input placeholder="请输入" v-model="seller_value" style="width: 160px; margin-right: 20px"></a-input>

        <a-select placeholder="请选择" v-model="store_type">
          <a-select-option value="store_id">门店ID</a-select-option>
          <a-select-option value="store_name">门店名称</a-select-option>
        </a-select>
        <a-input placeholder="请输入" v-model="store_value" style="width: 160px; margin-right: 20px"></a-input>
        <a-button @click="getList(true)" style="margin-left: 10px">查询</a-button>
      </a-row>

      <!-- 门店列表 -->
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="dataList"
        :rowKey="record => record.store_id"
        tableLayout="fixed"
        :pagination="{
          current: searchParams.page,
          pageSize: searchParams.limit,
          total: total,
          showLessItems: true,
          showQuickJumper: true,
          showTotal: (total, range) => `总计 ${total} 条`,
          onChange: onPageChange,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          onShowSizeChange: onPageChange,
        }">
        <template slot="status" slot-scope="text, record">
          <a-switch :checked="record.status == 1 ? true : false" @change="statusChange($event, record)" />
        </template>
        <template slot="expire_time" slot-scope="text, record">
          <a-date-picker show-time @change="onChange" style="display: inline-block; margin-right: 5px" v-model="record.expire_time" />
          <a @click="StoreEdit(record.expire_time, record.store_id)">确定</a>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import moment from "moment";
import zhCn from "ant-design-vue/es/date-picker/locale/zh_CN";
import "moment/dist/locale/zh-cn";
import { StoreList, StoreStatus, StoreEdit } from "@/views/api/seller";
import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
      locale: zhCn,
      ranges: -2,
      start_time: null,
      end_time: null,

      columns: [
        {
          title: "商户ID",
          dataIndex: "seller_id",
          width: 100,
        },
        {
          title: "商户名称",
          dataIndex: "seller_name",
        },
        {
          title: "门店ID",
          dataIndex: "store_id",
          width: 80,
        },
        {
          title: "门店名称",
          dataIndex: "store_name",
        },
        {
          title: "门店编号",
          dataIndex: "store_number",
          width: 100,
        },
        {
          title: "序列号",
          dataIndex: "serial_num",
        },
        // {
        //   title: "省市区",
        //   dataIndex: "province_name",
        //   customRender: (text, record) => {
        //     return record.province_name + "-" + record.city_name + "-" + record.district_name;
        //   },
        // },
        {
          title: "类型",
          dataIndex: "store_type",
          customRender: text => {
            return text == 1 ? "直营" : "加盟";
          },
          width: 80,
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
        },
        {
          title: "到期日期",
          dataIndex: "expire_time",
          scopedSlots: {
            customRender: "expire_time",
          },
          width: 300,
        },
        {
          title: "状态",
          dataIndex: "status",
          scopedSlots: {
            customRender: "status",
          },
        },
      ],

      seller_type: "seller_id",
      seller_value: "",
      store_type: "store_id",
      store_value: "",
    };
  },
  computed: {},
  methods: {
    moment,
    getList(is_search) {
      this.loading = true;
      this.searchParams.store_id = ''
      this.searchParams.store_name = ''
      this.searchParams[this.seller_type] = this.seller_value;
      this.searchParams[this.store_type] = this.store_value;
      if (is_search) this.searchParams.page = 1;
      StoreList(this.searchParams).then(res => {
        this.dataList = res.data.list;
        this.total = res.data.count;
        this.loading = false;
      });
    },

    // 修改状态
    statusChange(e, record) {
      e ? (record.status = 1) : (record.status = 0);
      StoreStatus({ status: record.status, store_id: record.store_id }).then(res => {
        if (res.code == 200) {
          this.$message.success("修改状态成功");
          this.getList();
        }
      });
    },

    StoreEdit(expire_time, store_id) {
      StoreEdit({ expire_time, store_id }).then(res => {
        if (res.code == 200) {
          this.$message.success("修改成功");
        }
        this.getList();
      });
    },

    onChange(value, dateString) {
      console.log("Selected Time: ", value);
      console.log("Formatted Selected Time: ", dateString);
    },

    startDisabledDate(current) {
      return current && current < moment(this.end_time).subtract(3, "month");
    },

    endDisabledDate(current) {
      return current && current > moment(this.start_time).add(3, "month");
    },

    onStartChange(date, dateString) {
      this.start_time = moment(dateString);
      this.sendTime();
      this.ranges = -2;
    },

    onEndChange(date, dateString) {
      this.end_time = moment(dateString);
      this.sendTime();
      this.ranges = -2;
    },

    onChangetime(date, dateString) {
      this.start_time = moment(dateString[0]);
      this.end_time = moment(dateString[1]);
      this.sendTime();
    },

    sendTime() {
      if (moment(this.start_time).unix() > moment(this.end_time).unix()) return this.$message.error("开始时间不能大于结束时间");
      this.searchParams.start_time = moment(this.start_time).unix();
      this.searchParams.end_time = moment(this.end_time).unix();
      this.getList();
    },

    rangeChange() {
      if (this.ranges == -2) {
        this.start_time = undefined;
        this.end_time = undefined;
      } else {
        this.onChangetime("", this.$getdayTime(this.ranges));
      }
    },
  },
  created() {
    this.searchParams.time_type = 'create_time'
    this.getList();
  },
};
</script>
<style lang="less" scoped>
/deep/.ant-select {
  width: 100px;
}
</style>
