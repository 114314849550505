import md5 from "js-md5";

// 字典排序
export function sort(arys) {
  var newkey = Object.keys(arys).sort();
  var newObj = {};
  for (var i = 0; i < newkey.length; i++) {
    newObj[newkey[i]] = arys[newkey[i]];
  }
  return newObj;
}

// 签名
export function sign(Data) {
  var date = JSON.stringify(Data)
  var data = JSON.parse(date)
  for (let key in data) {
    if (typeof data[key] === "object" || key == "nodes" || key == "url" || (data[key] ?? "") === "") delete data[key];
  }
  var encryptData = sort(data);
  encryptData = Object.getOwnPropertyNames(encryptData)
    .map((key) => {
      return key + "=" + encryptData[key];
    })
    .join("&");
  encryptData = md5(encryptData + "&key=123456").toUpperCase();
  return encryptData;
}

// 随机字符串
export function randomString(len) {
  len = len || 20;
  var $chars =
    "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  var maxPos = $chars.length;
  var pwd = "";
  for (var i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}
