import { render, staticRenderFns } from "./seller-modal.vue?vue&type=template&id=ec8b2fac&scoped=true&"
import script from "./seller-modal.vue?vue&type=script&lang=js&"
export * from "./seller-modal.vue?vue&type=script&lang=js&"
import style0 from "./seller-modal.vue?vue&type=style&index=0&id=ec8b2fac&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec8b2fac",
  null
  
)

export default component.exports