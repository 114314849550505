<template>
    <div>
        <a-card>
            <a-button type="primary" @click="addSms(1)">新增短信套餐</a-button>
            <!-- 短信套餐列表 -->
            <a-table :loading="loading" :columns="columns" :data-source="dataList" :rowKey="(record) => record.sms_id" tableLayout="fixed" :pagination="{
            current: searchParams.page,
            pageSize: searchParams.limit,
            total: total,
            showLessItems: true,
            showQuickJumper: true,
            showTotal: (total, range) => `总计 ${total} 条`,
            onChange: onPageChange,
            showSizeChanger: true,
	          pageSizeOptions: ['10', '20', '50'],
            onShowSizeChange: onPageChange,
      }">
                <template slot="action" slot-scope="text, record">
                    <a @click="addSms(2,record)" style="margin-right:10px;">编辑</a>
                    <a-popconfirm title="确定删除该短信套餐吗?" ok-text="是" cancel-text="否" @confirm="confirm(record.sms_id)">
                        <a class="error-color">删除</a>
                    </a-popconfirm>
                </template>
            </a-table>
        </a-card>
        <!-- 添加短信套餐 -->
        <a-modal v-model="visible" :title='`${modalText}短信套餐`' @ok="handleOk" @cancel="handleCancel">
            <a-form-model ref="ruleForm" :form="form" :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16, offset: 1 }">
                <a-form-model-item label="短信套餐名称" prop='sms_name'>
                    <a-input placeholder="请输入短信套餐名称" v-model="form.sms_name" show-word-limit />
                </a-form-model-item>
                <a-form-model-item label="短信套餐标题" prop='sms_title'>
                    <a-input placeholder="请输入短信套餐标题" v-model="form.sms_title" show-word-limit />
                </a-form-model-item>
                <a-form-model-item label="价格">
                    <a-input-number v-model="form.price" :min="1" placeholder="请输入价格" style="width:100%" />
                </a-form-model-item>
                <a-form-model-item label="条数">
                    <a-input-number v-model="form.number" :min="1" placeholder="请输入条数" style="width:100%" />
                </a-form-model-item>
                <a-form-model-item label="类型">
                    <a-radio-group v-model="form.sms_type" @change="$forceUpdate()">
                        <a-radio :value="1">普通</a-radio>
                        <a-radio :value="2">营销</a-radio>
                    </a-radio-group>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import { SmsList,SmsAdd,SmsEdit,SmsDel } from '@/views/api/system'
import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
      modalText: '',
      columns: [
      {
        title: '短信套餐ID',
        dataIndex: 'sms_id',
      },
      {
        title: '短信套餐名称',
        dataIndex: 'sms_name',
      },
      {
        title: '套餐标题',
        dataIndex: 'sms_title',
      },
      {
        title: '价格',
        dataIndex: 'price',
      },
      {
        title: '类型',
        dataIndex: 'sms_type',
        customRender: (text) => {
        return text == 1 ? "普通" : "营销";
        },
      },
      {
        title: '条数',
        dataIndex: 'number',
      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
      },
      {
        title: '操作',
        scopedSlots: {
          customRender: 'action'
        }
      }],
    };
  },
  computed: {},
  methods: {
    getList() {
      this.loading = true
      SmsList(this.searchParams).then(res=>{
        this.dataList = res.data.list
        this.total = res.data.count
        this.loading = false
      })
    },
    // 确认
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) return this.addEdit();
        return false;
      });
    },
    // 添加编辑
    addEdit() {
      this.flag == 1 ? SmsAdd(this.form).then((res) => this.handleSuccess(1,res)) : SmsEdit(this.form).then((res) => this.handleSuccess(2,res));
    },
    // 操作成功
    handleSuccess(flag,res) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? '添加' : flag == 2 ? '编辑' : flag == 3 ? '删除' : '修改状态'}成功`);
        this.visible = false;
        this.getList()
      }
    },
    // 弹框关闭
    handleCancel() {
      this.visible = false;
    },
    // 新增短信套餐
    addSms(flag,row) {
        this.form = {}
      this.flag = flag;
      this.visible = true;
      this.form.sms_type = 1
      if (row) {
        this.form = JSON.parse(JSON.stringify(row));
        this.modalText = "编辑";
      } else {
        this.modalText = "新增";
      }
    },
    // 删除
    confirm(sms_id) {
      SmsDel({ sms_id }).then((res) => this.handleSuccess(3,res));
    },
  },
  created() {
    this.getList()
  },
};
</script>
<style lang="less" scoped>
</style>