<template>
    <div>
        <a-card>
            <a-button type="primary" @click="addSeller(null)">新增商户</a-button>
            <!-- 商户列表 -->
            <a-table :loading="loading" :columns="columns" :data-source="dataList" :rowKey="(record) => record.role_id" tableLayout="fixed" :pagination="{
            current: searchParams.page,
            pageSize: searchParams.limit,
            total: total,
            showLessItems: true,
            showQuickJumper: true,
            showTotal: (total, range) => `总计 ${total} 条`,
            onChange: onPageChange,
            showSizeChanger: true,
	        pageSizeOptions: ['10', '20', '50'],
          onShowSizeChange: onPageChange,
      }">
                <!-- <template slot="status" slot-scope="text, record">
                  <a-switch :checked="record.status == 1 ? true : false" @change="GoodsBrandStatus($event,record)" />
                </template> -->
                <template slot="action" slot-scope="text, record">
                    <a @click="addSeller(record.role_id)" style="margin-right:10px;">编辑</a>
                    <a-popconfirm title="确定删除吗?" ok-text="是" cancel-text="否" @confirm="confirm(record.role_id)">
                        <a class="error-color">删除</a>
                    </a-popconfirm>
                </template>
            </a-table>
        </a-card>
    </div>
</template>

<script>
import { SellerRoleList,SellerDel } from '@/views/api/seller'
// import moment from "moment";
import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
      columns: [
      {
        title: '角色ID',
        dataIndex: 'role_id',
      },
      {
        title: '角色名称',
        dataIndex: 'role_name',
      },
      {
        title: '是否默认开启',
        dataIndex: 'is_open',
        customRender: (text) => {
        return text == 1 ? "是" : '否';
        },
      },
      {
        title: '状态',
        dataIndex: 'status',
        customRender: (text) => {
        return text == 1 ? "正常" : '禁用';
        },
      },
      {
        title: '描述',
        dataIndex: 'role_desc',
      },
      {
        title: '操作',
        scopedSlots: {
          customRender: 'action'
        },
      }
      ],
    };
  },
  computed: {},
  methods: {
    getList() {
      this.loading = true
      SellerRoleList(this.searchParams).then(res=>{
        this.dataList = res.data.list
        this.total = res.data.count
        this.loading = false
      })
    },
    // 分页
    onPageChange(page, pageSize) {
      this.searchParams.page = page;
      this.searchParams.limit = pageSize;
      this.getList();
    },
    // 新增商户
    addSeller(row) {
        this.$router.push({
        path: `/roleAdd`,
        query: {
          row: row,
        },
      });
    },
    // 删除
    confirm(role_id) {
      SellerDel({ role_id }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.getList()
        }
      });
    },
  },
  created() {
    this.getList()
  },
};
</script>
<style lang="less" scoped>
</style>