var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-card',[_c('a-row',{staticStyle:{"margin-bottom":"10px"}},[_c('a-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.search_type),callback:function ($$v) {_vm.search_type=$$v},expression:"search_type"}},[_c('a-select-option',{attrs:{"value":"goods_name"}},[_vm._v("商品名称")]),_c('a-select-option',{attrs:{"value":"barcode"}},[_vm._v("商户条码")])],1),_c('a-input',{staticStyle:{"width":"160px","margin-right":"20px"},attrs:{"placeholder":"请输入"},model:{value:(_vm.search_value),callback:function ($$v) {_vm.search_value=$$v},expression:"search_value"}}),_vm._v(" 商品状态: "),_c('a-select',{staticStyle:{"width":"100px"},attrs:{"placeholder":"请选择"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v("上架")]),_c('a-select-option',{attrs:{"value":0}},[_vm._v("下架")])],1),_c('a-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.getList(true)}}},[_vm._v("查询")]),_c('a',{staticStyle:{"margin-left":"8px"},on:{"click":_vm.clearParams}},[_vm._v("重置筛选条件")])],1),_c('a-button',{staticStyle:{"margin-bottom":"10px"},attrs:{"type":"primary"},on:{"click":_vm.showImportBox}},[_vm._v("导入")]),_c('a-table',{attrs:{"loading":_vm.loading,"columns":_vm.columns,"data-source":_vm.dataList,"rowKey":function (record) { return record.goods_id; },"tableLayout":"fixed","pagination":{
        current: _vm.searchParams.page,
        pageSize: _vm.searchParams.limit,
        total: _vm.total,
        showLessItems: true,
        showQuickJumper: true,
        showTotal: function (total, range) { return ("总计 " + total + " 条"); },
        onChange: _vm.onPageChange,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'],
        onShowSizeChange: _vm.onPageChange,
      }},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return [_c('a',{staticStyle:{"margin-right":"10px"},on:{"click":function($event){return _vm.edit(record)}}},[_vm._v("编辑")])]}}])})],1),_c('a-modal',{attrs:{"title":"批量导入","centered":"","width":"50%"},on:{"ok":_vm.GoodsImport},model:{value:(_vm.import_visibel),callback:function ($$v) {_vm.import_visibel=$$v},expression:"import_visibel"}},[_c('div',[_c('a-upload',{staticStyle:{"display":"inline-block"},attrs:{"name":"file","fileList":_vm.fileList,"remove":_vm.remove,"customRequest":_vm.handleChange,"accept":".xlsx, .xls","before-upload":_vm.beforeUpload}},[_c('a-button',{staticStyle:{"margin-right":"20px"},attrs:{"disabled":_vm.fileList.length > 0}},[_vm._v("添加文件")]),_c('a',{staticClass:"m-l-15",on:{"click":function($event){$event.stopPropagation();return _vm.GoodsTemplate.apply(null, arguments)}}},[_vm._v("下载模板")])],1)],1)]),_c('a-modal',{on:{"ok":_vm.close},model:{value:(_vm.visibel),callback:function ($$v) {_vm.visibel=$$v},expression:"visibel"}},[_c('div',[_vm._v("成功数据: "+_vm._s(_vm.data.success_num)+" 条,")]),_c('div',[_vm._v("失败数据: "+_vm._s(_vm.data.error_num)+" 条,")]),_vm._l((_vm.data.error_list),function(item,i){return _c('div',{key:i},[_vm._v(_vm._s(item))])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }