<template>
  <div>
    <a-card>
      <a-row style="margin-top: 10px">
        <a-input placeholder="请输入商户名称" v-model="searchParams.seller_name" style="width: 200px; margin-right: 20px"></a-input>
        <a-input placeholder="请输入商户ID" v-model="searchParams.seller_id" style="width: 200px; margin-right: 20px"></a-input>
        <a-input placeholder="请输入门店ID" v-model="searchParams.store_id" style="width: 200px"></a-input>
        <a-button @click="getList" style="margin-left: 10px">查询</a-button>
      </a-row>
      <!-- 收银机列表 -->
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="dataList"
        tableLayout="fixed"
        :pagination="{
          current: searchParams.page,
          pageSize: searchParams.limit,
          total: total,
          showLessItems: true,
          showQuickJumper: true,
          showTotal: (total, range) => `总计 ${total} 条`,
          onChange: onPageChange,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          onShowSizeChange: onPageChange,
        }">
        <!-- <template slot="status" slot-scope="text, record">
          <a-switch :checked="record.status == 1 ? true : false" @change="statusChange($event, record)" />
        </template> -->
        <template slot="action" slot-scope="text, record">
          <!-- <a @click="showModel(record)" style="margin-right:10px;">修改过期时间</a> -->

          <a-popconfirm v-if="record.status == 1" title="请谨慎操作，一旦作废，该机器直接清零无法使用!" ok-text="是" cancel-text="否" @confirm="statusChange(record)">
            <a style="color: red"> 作废 </a>
          </a-popconfirm>
        </template>
      </a-table>
    </a-card>
    <!-- 添加导航路径 -->
    <!--  -->
    <a-modal v-model="visible" title="修改过期时间" @ok="expireTime" @cancel="handleCancel">
      <a-form-model ref="ruleForm" :form="form" :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 14, offset: 1 }">
        <a-form-model-item label="过期时间" prop="name">
          <a-date-picker v-if="visible" show-time placeholder="请选择时间" @change="onChange" @ok="onOk" style="width: 100%" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import { MachineList, MachineStatus, expireTime } from "@/views/api/seller";
import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
      form: {},
      visible: false,
      searchParams: {
        page: 1,
        limit: 10,
      },
      total: 0,
      columns: [
        {
          title: "收银机ID",
          dataIndex: "machine_id",
        },
        {
          title: "商户名称",
          dataIndex: "seller_name",
        },
        {
          title: "门店名称",
          dataIndex: "store_name",
        },
        {
          title: "门店ID",
          dataIndex: "store_id",
        },
        {
          title: "机器码",
          dataIndex: "machine_code",
        },
        {
          title: "收银版本",
          dataIndex: "edition",
        },
        {
          title: "机器当前IP",
          dataIndex: "machine_ip",
        },
        {
          title: "过期时间",
          dataIndex: "expire_time",
          customRender: text => {
            return moment(text * 1000).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          title: "状态",
          dataIndex: "status",
          customRender: text => {
            return text ? "启用" : "已作废";
          },
          // scopedSlots: {
          //   customRender: "status",
          // },
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: {
            customRender: "action",
          },
        },
      ],
    };
  },
  computed: {},
  methods: {
    getList() {
      this.loading = true;
      MachineList(this.searchParams).then(res => {
        this.dataList = res.data.list;
        this.total = res.data.count;
        this.loading = false;
      });
    },
    showModel(row) {
      this.visible = true;
      this.form = {};
      this.form.seller_id = row.seller_id;
      this.form.machine_id = row.machine_id;
    },
    // 过期时间修改
    expireTime() {
      expireTime(this.form).then(res => {
        if (res.code == 200) {
          this.$message.success("修改过期时间成功");
          this.getList();
        }
      });
    },
    // 修改状态
    statusChange(record) {
      MachineStatus({ status: 0, machine_id: record.machine_id, seller_id: record.seller_id }).then(res => {
        if (res.code == 200) {
          this.$message.success("已作废");
          this.visible = false;
          this.getList();
        }
      });
    },

    onChange(value) {
      this.form.expire_time = Date.parse(value) / 1000;
    },
    onOk(value) {
      this.form.expire_time = Date.parse(value) / 1000;
    },
    handleCancel() {
      this.visible = false;
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="less" scoped></style>
