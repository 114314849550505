
// 公共接口
import request from "../utils/request";

// 商户列表
export async function SellerList(data) {
  return request({
    url: "/Seller/list",
    method: "post",
    data,
  });
}

// 商户详情
export async function SellerDetail(data) {
  return request({
    url: "/Seller/detail",
    method: "post",
    data,
  });
}

// 添加单位
export async function SellerAdd(data) {
  return request({
    url: "/Seller/add",
    method: "post",
    data,
  });
}

// 编辑单位
export async function SellerEdit(data) {
  return request({
    url: "/Seller/edit",
    method: "post",
    data,
  });
}

// 删除单位
export async function SellerDel(data) {
  return request({
    url: "/Seller/delete",
    method: "post",
    data,
  });
}

// 门店列表 ------------------
export async function StoreList(data) {
  return request({
    url: "/Store/list",
    method: "post",
    data,
  });
}

// 修改门店状态
export async function StoreStatus(data) {
  return request({
    url: "/Store/status",
    method: "post",
    data,
  });
}

// 收银机管理
export async function MachineList(data) {
  return request({
    url: "/Machine/list",
    method: "post",
    data,
  });
}

export async function MachineStatus(data) {
  return request({
    url: "/Machine/status",
    method: "post",
    data,
  });
}

export async function expireTime(data) {
  return request({
    url: "/Machine/expireTime",
    method: "post",
    data,
  });
}

// 商品库列表
export async function goodsList(data) {
  return request({
    url: "/goods/list",
    method: "post",
    data,
  });
}

// 商家节点
export async function SellerNodeList(data) {
  return request({
    url: "/SellerNode/list",
    method: "post",
    data,
  });
}

// 商家详情
export async function SellerNodeDetail(data) {
  return request({
    url: "/SellerNode/detail",
    method: "post",
    data,
  });
}

// 删除商家节点
export async function SellerNodeDel(data) {
  return request({
    url: "/SellerNode/delete",
    method: "post",
    data,
  });
}

// 添加商家节点
export async function SellerNodeAdd(data) {
  return request({
    url: "/SellerNode/add",
    method: "post",
    data,
  });
}

// 编辑商家节点
export async function SellerNodeEdit(data) {
  return request({
    url: "/SellerNode/edit",
    method: "post",
    data,
  });
}

// 商家角色列表
export async function SellerRoleList(data) {
  return request({
    url: "/SellerRole/list",
    method: "post",
    data,
  });
}

// 商家角色添加
export async function SellerRoleAdd(data) {
  return request({
    url: "/SellerRole/add",
    method: "post",
    data,
  });
}

// 商家角色列表
export async function SellerRoleEdit(data) {
  return request({
    url: "/SellerRole/edit",
    method: "post",
    data,
  });
}

// 商家角色列表
export async function SellerRoleDel(data) {
  return request({
    url: "/SellerRole/delete",
    method: "post",
    data,
  });
}

// 商家角色详情
export async function SellerRoleDetail(data) {
  return request({
    url: "/SellerRole/detail",
    method: "post",
    data,
  });
}


// 商户号
export async function getSellerId(data) {
  return request({
    url: "/Seller/getSellerId",
    method: "post",
    data,
  });
}

// 获取商品单位
export async function getGoodsUnitName(data) {
  return request({
    url: "/GoodsUnit/getGoodsUnitName",
    method: "post",
    data,
  });
}

// 编辑商品
export async function goodsEdit(data) {
  return request({
    url: "/goods/edit",
    method: "post",
    data,
  });
}

// 添加商品
export async function goodsAdd(data) {
  return request({
    url: "/goods/add",
    method: "post",
    data,
  });
}

// 商品详情
export async function goodsDetail(data) {
  return request({
    url: "/goods/detail",
    method: "post",
    data,
  });
}

// 区域列表
export async function SellerAreaList(data) {
  return request({
    url: "/SellerArea/list",
    method: "post",
    data,
  });
}

// 区域添加
export async function SellerAreaAdd(data) {
  return request({
    url: "/SellerArea/add",
    method: "post",
    data,
  });
}

// 区域编辑
export async function SellerAreaEdit(data) {
  return request({
    url: "/SellerArea/edit",
    method: "post",
    data,
  });
}

// 区域删除
export async function SellerAreaDel(data) {
  return request({
    url: "/SellerArea/delete",
    method: "post",
    data,
  });
}

// 开通短信
export async function createSms(data) {
  return request({
    url: "/Seller/createSms",
    method: "post",
    data,
  });
}

// 重置密码
export async function resetPwd(data) {
  return request({
    url: "/Seller/resetPwd",
    method: "post",
    data,
  });
}

// 关闭登录短信认证
export async function closeSmsLogin(data) {
  return request({
    url: "/Seller/closeSmsLogin",
    method: "post",
    data,
  });
}

// 动态口令
export async function generateDynamicToken(data) {
  return request({
    url: "/Seller/generateDynamicToken",
    method: "post",
    data,
  });
}

// 续费管理列表
export async function sellerRenewstoreList(data) {
  return request({
    url: "/sellerRenew/storeList",
    method: "post",
    data,
  });
}

// 获取支付方式列表
export async function sellerRenewpayTypeList(data) {
  return request({
    url: "/sellerRenew/payTypeList",
    method: "post",
    data,
  });
}

// 查询续费记录列表
export async function SellerRenewlist(data) {
  return request({
    url: "/sellerRenew/list",
    method: "post",
    data,
  });
}

// 新增续费
export async function SellerRenewadd(data) {
  return request({
    url: "/sellerRenew/add",
    method: "post",
    data,
  });
}

// 编辑续费
export async function SellerRenewedit(data) {
  return request({
    url: "/SellerRenew/edit",
    method: "post",
    data,
  });
}

// 代理商----------------------------------------------
// 列表
export async function agentlist(data) {
  return request({
    url: "/agent/list",
    method: "post",
    data,
  });
}

// 添加
export async function agentadd(data) {
  return request({
    url: "/agent/add",
    method: "post",
    data,
  });
}

// 编辑
export async function agentedit(data) {
  return request({
    url: "/agent/edit",
    method: "post",
    data,
  });
}

// 详情
export async function agentdetail(data) {
  return request({
    url: "/agent/detail",
    method: "post",
    data,
  });
}

// 变更代理商状态
export async function agentchangeStatus(data) {
  return request({
    url: "/agent/changeStatus",
    method: "post",
    data,
  });
}

// 删除代理商
export async function agentdelete(data) {
  return request({
    url: "/agent/delete",
    method: "post",
    data,
  });
}

// 导出续费记录
export async function SellerRenewexportList(data) {
  return request({
    url: "/SellerRenew/exportList",
    method: "post",
    data,
  });
}

// 修改门店
export async function StoreEdit(data) {
  return request({
    url: "/Store/edit",
    method: "post",
    data,
  });
}

export async function GoodsTemplate(data) {
  return request({
    url: "/Goods/template",
    method: "post",
    data,
  });
}

export async function GoodsImport(data) {
  return request({
    url: "/Goods/import",
    method: "post",
    data,
  });
}
