<template>
  <div>
    <a-card>
      <a-tabs type="card" @change="callback">
        <a-tab-pane key="1" tab="平台用户列表">
        </a-tab-pane>
        <a-tab-pane key="2" tab="代理用户列表">
        </a-tab-pane>
      </a-tabs>
      <a-button type="primary" @click="adduser(1)">新增{{key == 1 ? '平台' : '代理'}}用户</a-button>
      <!-- 系统用户列表 -->
      <a-table :loading="loading" :columns="columns" :data-source="dataList" :rowKey="(record) => record.user_id" tableLayout="fixed" :pagination="{
            current: searchParams.page,
            pageSize: searchParams.limit,
            total: total,
            showLessItems: true,
            showQuickJumper: true,
            showTotal: (total, range) => `总计 ${total} 条`,
            onChange: onPageChange,
            showSizeChanger: true,
	        pageSizeOptions: ['10', '20', '50'],
          onShowSizeChange: onPageChange,
      }">
        <template slot="action" slot-scope="text, record">
          <a @click="adduser(2,record)" style="margin-right:10px;">编辑</a>
          <a-popconfirm title="确定删除该系统用户吗?" ok-text="是" cancel-text="否" @confirm="confirm(record.user_id)">
            <a class="error-color">删除</a>
          </a-popconfirm>
        </template>
      </a-table>
    </a-card>
    <!-- 添加系统用户 -->
    <a-modal v-model="visible" :title='`${modalText}系统用户`' @ok="handleOk" @cancel="handleCancel">
      <a-form-model ref="ruleForm" :form="form" :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16, offset: 1 }">
        <a-form-model-item label="手机号码" prop='mobile'>
          <a-input placeholder="请输入手机号码" v-model="form.mobile" show-word-limit />
        </a-form-model-item>
        <a-form-model-item label="姓名" prop='rename'>
          <a-input placeholder="请输入姓名" v-model="form.rename" show-word-limit />
        </a-form-model-item>
        <a-form-model-item label="密码">
          <a-input placeholder="请输入密码" v-model="form.password" />
        </a-form-model-item>
        <a-form-model-item label="角色">
          <a-select placeholder="请选择角色" v-model="form.role_id">
            <a-select-option v-for="d in role_List" :key="d.role_id" :value="d.role_id">
              {{ d.role_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="启用状态">
          <a-switch v-if="visible" :checked="form.status == 0 ? false : true" @change="statusChange" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { platformList,agentList,userAdd,userEdit,userDel } from '@/views/api/system'
import { roleName } from '@/views/api/common'
import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
      form: {
        status: 0
      },
      key: '1',
      modalText: '',
      dataList: [],
      role_List: [],
      columns: [
      {
        title: '系统用户ID',
        dataIndex: 'user_id',
      },
      {
        title: '系统用户名称',
        dataIndex: 'rename',
      },
      {
        title: '手机号码',
        dataIndex: 'mobile',
      },
      {
        title: '密码',
        dataIndex: 'password',
      },
      {
        title: '状态',
        dataIndex: 'status',
        customRender: (text) => {
        return text == 1 ? "启用" : '禁用';
        },
      },
      {
        title: '操作',
        scopedSlots: {
          customRender: 'action'
        }
      }],
    };
  },
  computed: {},
  methods: {
    // 分页
    onPageChange(page, pageSize) {
      this.searchParams.page = page;
      this.searchParams.limit = pageSize;
      this.getplatformList();
    },
    getplatformList() {
      this.loading = true
      platformList(this.searchParams).then(res=>{
        this.dataList = res.data.list
        this.total = res.data.count
        this.loading = false
      })
    },
    getagentList () {
      this.loading = true
      agentList(this.searchParams).then(res=>{
        this.dataList = res.data.list
        this.total = res.data.count
        this.loading = false
      })
    },
    callback(key) {
      key == 1 ? this.getplatformList() : this.getagentList()
      this.key = key
    },
    roleList() {
        roleName({}).then(res=>{
            this.role_List = res.data
        })
    },
    // 确认
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) return this.addEdit();
        return false;
      });
    },
    // 添加编辑
    addEdit() {
      this.form.user_type = this.key
      this.flag == 1 ? userAdd(this.form).then((res) => this.handleSuccess(1,res)) : userEdit(this.form).then((res) => this.handleSuccess(2,res));
    },
    // 操作成功
    handleSuccess(flag,res) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? '添加' : flag == 2 ? '编辑' : flag == 3 ? '删除' : '修改状态'}成功`);
        this.visible = false;
        this.key == 1 ? this.getplatformList() : this.getagentList()
      }
    },
    // 弹框关闭
    handleCancel() {
      this.visible = false;
    },
    // 新增系统用户
    adduser(flag,row) {
      this.form = {}
      this.flag = flag;
      this.visible = true;
      if (row) {
        this.form = JSON.parse(JSON.stringify(row));
        this.modalText = "编辑";
      } else {
        this.form.status = 1
        this.modalText = "新增";
      }
    },
    // 删除
    confirm(user_id) {
      userDel({ user_id }).then((res) => this.handleSuccess(3,res));
    },
    // 弹框启用状态
    statusChange(checked) {
      this.$forceUpdate()
      checked ? this.form.status = 1 : this.form.status = 0
    },
  },
  created() {
    this.getplatformList()
    this.roleList()
  },
};
</script>
<style lang="less" scoped>
</style>