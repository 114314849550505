<template>
    <div>
      <a-card style="height:80vh;overflow-y:scroll">
        <a-row>
          <a-col :md="7">
            <div class="ant-tree-border" style="max-height:760px;overflow-y:scroll">
              <div class="ant-tree-group-title">图片库</div>
              <!-- :selected-keys="selectedKeys" -->
              <a-tree :replace-fields="replaceFields" :tree-data="treeData" default-expand-all>
                <template slot="custom" slot-scope="item">
                  <div @click="clickTree(item)">
                    <span>{{ item.cate_name }}({{item.file_num}})</span>
                    <span class="but_operation">
                      <span class="but_type" @click="append(item,'edit')">编辑</span>
                      <span class="but_type" @click="append(item,'addson')">新建子分组</span>
                    </span>
                  </div>
                </template>
              </a-tree>
            </div>
          </a-col>
          <!-- 图片列表 -->
          <a-col :md="16">
            <div class="a-list-inner-content">
              <div style="margin-bottom:20px;">
                <a-space>
                  <a-button type="primary" @click="append(1,'new')">新增图片分组</a-button>
                  <a-button type="primary" @click="showUploadModal(1)">本地上传</a-button>
                </a-space>
              </div>
              <!-- <a-row> -->
              <!-- <div class="antd-pages-my-files"> -->
              <!-- <div class="a-file-item a-border-item"> -->
              <!-- <a-checkbox v-if="ifShowState" :value="item.file_id" class="a-list-checkbox">123</a-checkbox> -->
              <!-- <img slot="cover" v-lazy="item.url" /> -->
              <div class="a-file-item a-border-item" style="display: inline-block;float: left;margin:0 10px;" v-for="(item,i) in goodsGroup" :key="i">
                <div class="imgBox" v-if="item.url">
                  <img :src="item.url" v-lazy="item.url" :onerror="errorImg" @click="showData(item)">
                  <div v-if="item.file_id == currentKey ? true : false" :class="item.file_id == currentKey ? 'isActive' : ''">
                    <a-icon type="check" />
                  </div>
                  <div class="ant-my-nowrap">{{item.name}}</div>
                </div>
                <div class="imgBox" v-if="!item.url">
                  <div class="a-file-full-img" @dblclick="showSon(item)"><img src="@/assets/img/file-icon.png">
                    <div class="ant-my-nowrap">{{item.name}}</div>
                  </div>
                </div>
              </div>
              <!-- <div class="a-file-item a-border-item" style="display: inline-block;float: left;margin:0 10px;" v-for="(item,index) in goodsGroup" :key="index">
              </div> -->
              <!-- <div v-if="item.url" :class="mouse&&index==current ? 'a-file-operate a-file-operate-hover':'a-file-operate'"> -->
              <!-- <span @click="showUploadModal(2,item)">编辑</span> -->
              <!-- </div> -->
              <!-- </div> -->
              <!-- </div> -->
              <!-- </a-row> -->
            </div>
          </a-col>
        </a-row>
        <!-- 上传编辑图片 -->
        <a-modal v-model="uploadModal" title="上传图片" centered @ok="FolderFile_handel" @cancel="handleCancel" :confirm-loading="confirmLoading" width="50%" :footer="null">
          <a-form :form="FolderFile_form" :labelCol="{span: 4}" :wrapperCol="{span: 16, offset: 1}">
            <a-form-item label="选择图片分组">
              <a-cascader v-model="FolderFile_form.cate_id" :options="treeData" @change="cateListOnChange" placeholder="请选择分类" :field-names="{
                      label: 'cate_name',
                      value: 'folder_id',
                      children: 'node',
                    }" />
            </a-form-item>
            <a-form-item label="商品图片">
              <div class="clearfix">
                <!-- :data="params"  :customRequest="customRequest" -->
                <a-upload name="image" action="https://boss.yunguos.com/api/FolderFile/add" :multiple="true" list-type="picture-card" :before-upload="beforeUpload" :file-list="fileList" @preview="handlePreview" @change="handleChange">
                  <a-icon type="plus" />
                </a-upload>
                <!-- <div class="a-upload-desc">图片格式支持JPG,PNG,GIF,BMP等格式，一次最多上传20张图片</div> -->
                <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                  <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
              </div>
            </a-form-item>
          </a-form>
        </a-modal>
        <!-- 新增文件分类 -->
        <a-modal v-model="treeModal" :title="`${title_name}文件分组`" centered @ok="FolderCateAdd" @cancel="handleAddsonCancel">
          <a-form :form="FolderCate_form" :labelCol="{span: 6}" :wrapperCol="{span: 18}">
            <a-form-item label="选择文件分组">
              <a-cascader :disabled="select_flag" v-model="FolderCate_form.parent_id" :options="treeData" change-on-select @change="cateListOnChange" placeholder="不选择默认顶级分组" :field-names="{
                label: 'cate_name',
                value: 'folder_id',
                children: 'node',
              }" />
            </a-form-item>
            <a-form-item label="分组名称">
              <a-input placeholder="请输入分组名称" v-model="FolderCate_form.cate_name" />
            </a-form-item>
          </a-form>
        </a-modal>
        <!-- 图片预览 -->
        <!-- <div class="a-file-item-previewCover">
            <img src="https://mercury-image.nhsoft.cn/public/system/mercury/asset2s/attachments/000/914/518/original/%E8%B1%86%E6%B5%86.png" />
             </div> -->
        <!-- </a-card> -->
      </a-card>
    </div>
  </template>
  
  <script>
  // import { sign, randomString } from "../../utils/sign";
  import { FolderCateList, FolderFileList, FolderCateAdd,FolderCateEdit, FolderFileEdit, FolderFileAdd } from '@/views/api/system'
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  export default {
    data() {
      return {
        select_flag: false,
        folder_id: false,
        title_name: '',
        visible: false,
        confirmLoading: false,
        FolderFile_flag: null, // 图片编辑/新增
        // cate_id: 0,
        cate_id: 0,
        editFile_form: {},
        FolderFile_form: {
          cate_type: 1
        },
        FolderCate_form: {
          cate_type: 1, // 1.图片 2.文件
          parent_id: [],
          cate_name: ''
        },
        expandedKeys: ["0-0-0"], //（受控）展开指定的树节点
        autoExpandParent: true, // 是否自动展开父节点
        selectedKeys: [],
        treeData: [
        ],
        replaceFields: {
          children: "node",
          title: "cate_name",
          key: "folder_id",
        },
        searchParams: {
          page: 1,
          limit: 10,
        },
        params: {
        },
        goodsGroup: [],
        checkAll: false, //全选
        showBatch: true, //控制批量操作按钮显示状态
        ifShowState: false, //批量操作(控制多选按钮显示状态)
        uploadModal: false, //上传图片 取消按钮
        previewVisible: false, //图片预览模态框
        previewImage: "", //图片预览
        fileList: [],
        treeModal: false, //新增子分类模态框
        mouse: false,
        current: 0,
        showMb: false,
        masData: "",
        edituploadModal: false, //编辑图片 取消按钮
        img_item: {},
        errorImg:'',
        currentKey: ''
      };
    },
    beforeCreate() {
      this.form = this.$form.createForm(this);
    },
    created() {
      this.FolderCateList();
      this.FolderFileList(0);
    },
    methods: {
      // sign, randomString,
      // 图片分类列表
      FolderCateList() {
        FolderCateList({}).then((res) => {
          this.addSlots(res.data)
        });
      },
      addSlots(treeData) {
        treeData.forEach(item=>{
          item.scopedSlots = { title: 'custom' }
          if (item.node) this.addSlots(item.node)
        })
        this.treeData = treeData
      },
      // 图片列表
      FolderFileList(cate_id) {
        FolderFileList({cate_id}).then((res) => {
          this.goodsGroup = res.data
        });
      },
      // 分类列表选择
      cateListOnChange(value) {
        console.log(value[value.length - 1])
        this.FolderFile_form.cate_id.push(value[value.length - 1])
      },
      // 创建--文件分类
      FolderCateAdd() {
        const {cate_name,parent_id,folder_id,flag} = this.FolderCate_form
        if (flag == 'edit') {
          FolderCateEdit({folder_id:folder_id,cate_name: cate_name, cate_type: 1, parent_id: parent_id[parent_id.length - 1]}).then((res) => this.handel_success(res,2));
        } else {
          FolderCateAdd({cate_name: cate_name, cate_type: 1, parent_id: parent_id[parent_id.length - 1]}).then((res) => this.handel_success(res,1));
        }
      },
      // 操作成功
      handel_success(res,flag) {
        if (res.code == 200) {
          this.$message.success(`${flag == 1 ? '添加' : flag == 2 ? '编辑' : '删除'}成功`)
          this.fileList = [];
          this.treeModal = false;
          this.uploadModal = false;
          this.FolderCateList();
          this.FolderCateList();
        }
      },
      // 文件夹双击
      showSon(item) {
        console.log(item)
        this.selectedKeys = item.parent_ids.map(Number);
        this.FolderFile_form.cate_id = item.parent_ids.map(Number);
        this.$forceUpdate()
        this.FolderFileList(item.folder_id);
      },
      // 文件夹新增子分组
      append(item,handel_flag) {
          this.FolderCate_form.cate_name = ''
          if (handel_flag == 'edit') {
            this.title_name = '编辑'
            this.select_flag = true
          } else {
            this.title_name = '新增'
            this.select_flag = false
          }
        if (handel_flag == 'addson') {
          this.FolderCate_form.parent_id = item.parent_ids.map(Number)
          console.log(this.FolderCate_form.parent_id)
          this.FolderCate_form.flag = 'add'
          this.FolderCate_form.folder_id = item.folder_id
        } else if (handel_flag == 'edit') {
          this.FolderCate_form.parent_id = item.parent_ids.map(Number).slice(0,-1)
          this.FolderCate_form.flag = 'edit'
          this.FolderCate_form.cate_name = item.cate_name
          this.FolderCate_form.folder_id = item.folder_id
        } else if (handel_flag == 'new'){
          this.FolderCate_form.parent_id = []
          this.FolderCate_form.folder_id
          this.FolderCate_form.flag = 'add'
        }
        this.$forceUpdate()
        // this.FolderCate_form.parent_id
        this.treeModal = true;
      },
      // 新增/编辑文件
      FolderFile_handel() {
        this.FolderFile_flag == 1 ? this.FolderFileAdd() : this.FolderFileEdit()
      },
      // 上传编辑图片
      FolderFileEdit() {
        console.log('file-edit')
        const {file_id,cate_id} = this.editFile_form
        var addArr = [];
        console.log(this.fileList)
        this.fileList.forEach((item, i) => {
          var obj = {};
          obj.name = item.name;
          obj.url = item.thumbUrl;
          addArr[i] = obj;
        });
        FolderFileEdit({ 
          file: { url: addArr[0].url, name: addArr[0].name }, 
          type: 1, 
          file_id: file_id, 
          cate_id: cate_id
        }).then((res) => this.handel_success(res,2))
      },
      // 上传图片
      FolderFileAdd() {
        console.log('file-add')
        if (!this.FolderFile_form.cate_id) {
          return this.$message.error('请选择分类!')
        }
        var addArr = [];
        this.confirmLoading = true;
        this.fileList.forEach((item, i) => {
          var obj = {};
          obj.name = item.name;
          obj.url = item.thumbUrl;
          addArr[i] = obj;
        });
        FolderFileAdd({
          files: addArr,
          type: 1,
          cate_id: this.FolderFile_form.cate_id[this.FolderFile_form.cate_id.length - 1],
        }).then((res) => {
          console.log(2)
          this.confirmLoading = false  
          this.handel_success(res,1)
          if (res.code == 400) {
            this.confirmLoading = false  
          }
          this.FolderFileList(this.folder_id)
        }).catch(res=>{
          console.log(res)
          this.confirmLoading = false  
        })
      },
      // 变更
      handleChange({ fileList }) {
        console.log(fileList)
        this.fileList = fileList;
      },
      // 上传前钩子
      beforeUpload(file) {
        // console.log(file, fileList)
        // this.params = {
        //   type: 1,
        //   cate_id: this.FolderFile_form.cate_id[this.FolderFile_form.cate_id.length - 1],
        //   noncestr: randomString(20),
        //   timestamp: Date.parse(new Date()) / 1000 + "",
        //   user_token: sessionStorage.getItem("access_token")
        // }
        // this.params.sign = sign(this.params)
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
          this.$message.error("只能上传jpeg及png文件!");
        }
        return isJpgOrPng;
      },
      // customRequest() {
      // },
      // 展开/收起节点时触发
      onExpand(expandedKeys) {
        this.expandedKeys = expandedKeys;
        this.autoExpandParent = false;
      },
      // 上传图片按钮
      showUploadModal(num,item) {
        if (num == 2) {
          this.FolderFile_form.cate_id = [item.cate_id]
        }
        this.editFile_form = item
        num == 1 ? this.FolderFile_flag = 1 : this.FolderFile_flag = 2
        this.uploadModal = true;
      },
      // 预览图片右上角关闭按钮
      handleCancel() {
        this.previewVisible = false;
      },
      // 预览图片
      async handlePreview(file) {
        if (!file.url && !file.preview) {
          console.log(file);
          file.preview = await getBase64(file.originFileObj);
        }
        this.previewImage = file.url || file.preview;
        this.previewVisible = true;
      },
      // 鼠标移入事件
      mouseover(index) {
        this.mouse = true;
        this.current = index;
      },
      // 鼠标移出事件
      mouseLeave() {
        this.mouse = false;
        this.current = null;
      },
      // 新增子分组关闭
      handleAddsonCancel() {
        this.treeModal = false
      },
      // 点击左侧菜单
      clickTree(e) {
        console.log(e)
        this.selectedKeys = e.parent_ids
        this.FolderFile_form.cate_id = e.parent_ids.map(Number)
        this.FolderCate_form.parent_id = e.parent_ids.map(Number).slice(0,-1)
        this.FolderFileList(e.folder_id)
        this.folder_id = e.folder_id
      },
      showData(data) {
        this.currentKey = data.file_id;
        this.$emit("func", data);
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  /deep/.viewer-canvas {
    width: 100% !important;
    height: 100% !important;
  }
  .imgBox {
    position: relative;
    width: 120px;
    height: 120px;
    img {
      height: 120px;
      width: 120px;
      border-radius: 3px;
      /deep/:hover {
        cursor: pointer;
      }
    }
  }
  .ant-my-nowrap {
    position: absolute;
    bottom: 0;
    width: 120px;
    text-align: center;
    background-color: #f4f6fa;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.65);
    white-space: nowrap;
  }
  /deep/.ant-tree-node-content-wrapper {
    width: 90%;
  }
  .ant-tree-title {
    width: 100%;
  }
  .title {
    float: left;
  }
  .ant-tree-group-title {
    background: #f4f6fa;
    line-height: 42px;
    padding-left: 20px;
  }
  .ant-tree-border {
    border: 1px solid #f5f5f5;
  }
  .ant-card-body {
    .ant-tree {
      line-height: 2;
      li {
        position: relative;
      }
    }
  }
  .a-list-inner-content {
    width: 96%;
    margin: 0 auto;
  }
  
  .a-file-item-previewCover {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    transition: all 0.3s;
    img {
      display: block;
      width: auto;
      height: auto;
      // max-width: 50%;
      // max-height: 50%;
    }
  }
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }
  .a-upload-desc {
    line-height: 20px;
    font-size: 12px;
  }
  .but_type {
    float: right;
    color: #1890ff;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .isActive {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(34, 34, 34, 0.5);
      width: 100%;
      height: 100%;
      text-align: center;
      .anticon {
        line-height: 120px;
        font-size: 36px;
        color: #00b5a1;
      }
    }
  </style>
  