<template>
  <div>
    <a-card>
      <a-row style="margin-bottom: 10px">
        续费状态:
        <a-select placeholder="请选择续费状态" v-model="searchParams.renew_status" style="width: 180px">
          <a-select-option :value="2">即将到期</a-select-option>
          <a-select-option :value="0">已到期</a-select-option>
          <a-select-option :value="1">未到期</a-select-option>
        </a-select>

        商户信息:
        <a-input placeholder="请输入商户ID/商户名称" v-model="searchParams.seller_keywords" style="width: 220px; margin-right: 20px"></a-input>

        门店信息:
        <a-input placeholder="请输入门店ID/门店名称" v-model="searchParams.store_keywords" style="width: 220px; margin-right: 20px"></a-input>

        销售类型:
        <a-select placeholder="请选择销售类型" v-model="searchParams.sale_type" style="width: 180px">
          <a-select-option :value="1">云果直销</a-select-option>
          <a-select-option :value="2">代理商直销</a-select-option>
        </a-select>
        <a-select v-if="searchParams.sale_type == 2" placeholder="请选择" mode="multiple" v-model="searchParams.agent_ids" style="margin: 0 20px 20px 0; width: 150px">
          <a-select-option v-for="item in agent_ids_list" :key="item.agent_id" :value="item.agent_id"> {{ item.agent_name }} </a-select-option>
        </a-select>
        <a-button @click="getList(true)" style="margin-left: 10px">查询</a-button>
      </a-row>

      <a-button type="primary" @click="addRenew()" style="margin-bottom: 10px; margin-right: 15px">新增续费</a-button>
      <a-button type="primary" @click="batchRenew()" style="margin-bottom: 10px">批量续费</a-button>

      <a-table
        style="padding: 0"
        :loading="loading"
        size="small"
        :columns="columns"
        :data-source="dataList"
        :rowKey="record => record.store_id"
        tableLayout="fixed"
        :row-selection="{ selectedRowKeys: selectedRowKeys, selectedRows: selectedRows, onChange: onSelectChange }"
        :locale="{ emptyText: '请先搜索商户' }"
        :scroll="{ x: 1600 }"
        :pagination="{
          current: searchParams.page,
          pageSize: searchParams.limit,
          total: total,
          showLessItems: true,
          showQuickJumper: true,
          showTotal: (total, range) => `总计 ${total} 条`,
          onChange: onPageChange,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          onShowSizeChange: onPageChange,
        }">
        <template slot="action" slot-scope="text, record">
          <a @click="tolist(record)" style="margin-right: 10px">续费记录</a>
        </template>
      </a-table>
    </a-card>

    <!-- <a-modal v-model="visible" :title="`${modalText}续费`" @ok="handleOk" @cancel="handleCancel">
      <a-form-model ref="ruleForm" :form="form" :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16, offset: 1 }">
        <a-form-model-item label="续费用户">
          <span v-if="seller.seller_name" style="margin-right: 15px">{{ seller.seller_name }}</span>
          <a @click="openSeller">{{ seller.seller_name ? "修改" : "选择商户" }}</a>
        </a-form-model-item>

        <a-form-model-item label="续费门店" v-if="seller.seller_name">
          <a @click="openStore">选择门店</a>
          <div v-for="item in store" :key="item.store_id">{{ item.store_name }} <a @click="delStore(item.store_id)" style="margin-left: 10px">删除</a></div>
        </a-form-model-item>

        <a-form-model-item label="续费时间" v-if="renewal_model == 1">
          <a-select placeholder="请选择续费时间" v-model="form.renew_time">
            <a-select-option :value="12">12个月</a-select-option>
            <a-select-option :value="24">24个月</a-select-option>
            <a-select-option :value="36">36个月</a-select-option>
            <a-select-option :value="60">60个月</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="门店续费期限统一截止时间" v-if="renewal_model == 2">
          <a-date-picker v-model="form.uniform_date" />
        </a-form-model-item>

        <a-form-model-item label="续费金额" prop="name">
          <a-input placeholder="请输入续费金额" v-model="form.renew_money" @change="$forceUpdate()" />
        </a-form-model-item>

        <a-form-model-item label="支付方式">
          <a-select placeholder="请选择支付方式" v-model="form.pay_type">
            <a-select-option v-for="item in pay_type_list" :key="item.pay_type" :value="item.pay_type">{{ item.pay_type_name }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="备注信息" prop="path">
          <a-input placeholder="请输入备注信息" v-model="form.notice" />
        </a-form-model-item>

        <a-form-model-item label="支付凭证" prop="path">
          <a-upload :customRequest="handleChange" list-type="picture-card" :before-upload="beforeUpload" :show-upload-list="false">
            <img v-if="voucher" :src="voucher" class="avatar" />
            <div v-else>
              <div class="ant-upload-text">点击上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal> -->
  </div>
</template>

<script>
import { sellerRenewstoreList, agentlist } from "@/views/api/seller";

import { mixins } from "../../mixin/index";

export default {
  mixins: [mixins],
  data() {
    return {
      form: {},
      modalText: "",
      columns: [
        {
          title: "门店ID",
          dataIndex: "store_id",
          fixed: "left",
          width: 80,
        },
        {
          title: "门店名称",
          dataIndex: "store_name",
          fixed: "left",
          width: 200,
        },
        {
          title: "商户ID",
          dataIndex: "seller_id",
        },
        {
          title: "商户名称",
          dataIndex: "seller_name",
        },
        {
          title: "销售类型",
          dataIndex: "agent_id",
          customRender: text => {
            return text == 0 ? "云果直销" : "代理商直销";
          },
        },
        {
          title: "省市区",
          dataIndex: "province_name",
          customRender: (text, record) => {
            return text + record.city_name + record.district_name;
          },
          width: 200,
        },
        {
          title: "门店经营类型",
          dataIndex: "store_type",
          customRender: text => {
            return text == 1 ? "直营" : "加盟";
          },
        },
        {
          title: "门店创建时间",
          dataIndex: "create_time",
        },
        {
          title: "到期日期",
          dataIndex: "expire_time",
          width: 120,
        },
        {
          title: "续费状态",
          dataIndex: "renew_status",
          customRender: text => {
            return text == 0 ? "已到期" : text == 1 ? "未到期" : text == 2 ? "即将到期" : "";
          },
          width: 80,
        },
        {
          title: "待续费金额",
          dataIndex: "renew_money",
        },
        {
          title: "续费时间",
          dataIndex: "renew_time",
          width: 80,
        },
        {
          title: "操作",
          fixed: "right",
          scopedSlots: {
            customRender: "action",
          },
        },
      ],

      selectedRowKeys: [],
      selectedRows: [],
      seller: {},
      agent_ids_list: [],
    };
  },
  computed: {},
  methods: {
    getList(is_search) {
      this.loading = true;
      if (is_search) this.searchParams.page = 1;
      sellerRenewstoreList(this.searchParams).then(res => {
        this.dataList = res.data.list;
        this.total = res.data.count;
        this.loading = false;
      });
    },

    agentlist() {
      if (this.agent_ids_list.length != 0) return;
      agentlist({ page: 0, limit: 0 }).then(res => {
        this.agent_ids_list = res.data.list;
        this.loading = false;
      });
    },

    // 操作成功
    handleSuccess(flag, res) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? "添加" : flag == 2 ? "编辑" : flag == 3 ? "删除" : "修改状态"}成功`);
        this.visible = false;
        this.getList();
      }
    },

    // 新增续费
    addRenew() {
      this.form = {};
      this.form.renew_time = 12;
      this.form.renew_money = 800;
      this.$router.push({
        name: `addSellerRenew`,
        params: this.form,
      });
    },

    // 批量续费
    batchRenew() {
      if (this.selectedRowKeys.length == 0) return this.$message.error("请勾选门店");
      const is_same_seller_id = this.selectedRows[0].seller_id;
      var is_same = this.selectedRows.every(item => item.seller_id === is_same_seller_id);
      if (!is_same) return this.$message.error("选中的门店不是同一个商户");
      const { seller_renewal_model, seller_id, seller_name } = this.selectedRows[0];
      this.form = { seller_renewal_model, seller_id, seller_name };
      this.form.store = this.selectedRows;
      this.form.store_id_list = this.selectedRowKeys;
      this.$router.push({
        name: `addSellerRenew`,
        params: this.form,
      });
      this.$forceUpdate();
    },

    onSelectChange(keys, rows) {
      this.selectedRowKeys = keys;
      if (keys.length == rows.length) {
        this.selectedRows = rows;
      } else {
        this.selectedRows = keys.map(key => this.selectedRows.find(row => row.store_id == key) || rows.find(row => row.store_id == key));
      }
    },

    // 跳转续费记录
    tolist(row) {
      this.$router.push({
        path: `/orderStore`,
        query: {
          seller_id: row.seller_id,
          store_id: row.store_id
        },
      });

      sessionStorage.setItem("selectedKeys", 48);
      sessionStorage.setItem("name", "订单管理");
      sessionStorage.setItem("son_name", "门店续费记录");
    },
  },

  created() {
    this.agentlist();
  },
};
</script>
<style lang="less" scoped>
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 4px;
}
</style>
