<template>
  <div>
    <a-card>
      <a-row style="margin-bottom: 10px">
        上报时间:
        <a-select v-model="ranges" :allowClear="false" style="width: 100px; display: inline-block" placeholder="请选择" @change="rangeChange">
          <a-select-option :value="1"> 今天 </a-select-option>
          <a-select-option :value="2"> 昨天 </a-select-option>
          <a-select-option :value="4"> 近七天 </a-select-option>
          <a-select-option :value="5"> 本周 </a-select-option>
          <a-select-option :value="6"> 上周 </a-select-option>
          <a-select-option :value="7"> 本月 </a-select-option>
          <a-select-option :value="8"> 上月 </a-select-option>
          <a-select-option :value="-2"> 自定义 </a-select-option>
        </a-select>
        <a-date-picker :locale="locale" :allowClear="false" v-model="start_time" placeholder="请选择" @change="onStartChange" style="width: 130px" :disabled-date="startDisabledDate" />
        <span style="width: 10px; margin: 0 4px">-</span>
        <a-date-picker :locale="locale" :allowClear="false" v-model="end_time" placeholder="请选择" @change="onEndChange" style="width: 130px; margin-right: 20px" :disabled-date="endDisabledDate" />

        <a-select placeholder="请选择" v-model="searchParams.seller_type">
          <a-select-option :value="1">商户ID</a-select-option>
          <a-select-option :value="2">商户名称</a-select-option>
        </a-select>
        <a-input placeholder="请输入" v-model="searchParams.seller_keywords" style="width: 220px; margin-right: 20px"></a-input>

        <a-select placeholder="请选择" v-model="searchParams.store_type">
          <a-select-option :value="1">门店ID</a-select-option>
          <a-select-option :value="2">门店名称</a-select-option>
        </a-select>
        <a-input placeholder="请输入" v-model="searchParams.store_keywords" style="width: 220px; margin-right: 20px"></a-input>
      </a-row>

      <a-row style="margin-bottom: 10px">
        机器号:
        <a-input placeholder="请输入" v-model="searchParams.machine_id" style="width: 220px; margin-right: 20px"></a-input>

        收银版本:
        <a-select placeholder="请选择" v-model="searchParams.app_version" style="width: 120px">
          <a-select-option v-for="d in version_list" :key="d.version">
            {{ d.version }}
          </a-select-option>
        </a-select>

        <a-button @click="getList" style="margin-left: 10px">查询</a-button>
      </a-row>

      <!-- 导航路径列表 -->
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="dataList"
        :rowKey="record => record.path_id"
        tableLayout="fixed"
        :defaultExpandAllRows="true"
        :key="Math.random()"
        :pagination="{
          current: searchParams.page,
          pageSize: searchParams.limit,
          total: total,
          showLessItems: true,
          showQuickJumper: true,
          showTotal: (total, range) => `总计 ${total} 条`,
          onChange: onPageChange,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          onShowSizeChange: onPageChange,
        }">
        <p slot="expandedRowRender" slot-scope="record" style="margin: 0">
          <a @click="copy(record.content)">
            <div v-html="record.content.replace(/\r\n/g, '<br />')"></div>
          </a>
        </p>
        <template slot="antion" slot-scope="text, record">
          <div><a @click="copy(record.content)">复制内容</a></div>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import moment from "moment";
import zhCn from "ant-design-vue/es/date-picker/locale/zh_CN";
import "moment/dist/locale/zh-cn";
import { ErrorLogList, ErrorLogversion } from "@/views/api/system";
import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
      form: {
        status: 0,
      },
      locale: zhCn,
      ranges: -2,
      start_time: null,
      end_time: null,
      version_list: [],
      modalText: "",
      columns: [
        {
          title: "异常日志ID",
          dataIndex: "log_id",
        },
        {
          title: "商户ID",
          dataIndex: "seller_id",
        },
        {
          title: "商户名称",
          dataIndex: "seller_name",
        },
        {
          title: "门店ID",
          dataIndex: "store_id",
        },
        {
          title: "门店名称",
          dataIndex: "store_name",
        },
        {
          title: "机器号",
          dataIndex: "machine_id",
        },
        {
          title: "收银版本",
          dataIndex: "app_version",
        },
        {
          title: "内容",
          dataIndex: "content",
          width: 400,
          ellipsis: true,
          scopedSlots: { customRender: "content" },
        },
        {
          title: "上报时间",
          dataIndex: "create_time",
          customRender: text => {
            return moment(text * 1000).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        // {
        //   title: "操作",
        //   dataIndex: "antion",
        //   scopedSlots: {
        //     customRender: "antion",
        //   },
        // },
      ],
    };
  },
  computed: {},
  methods: {
    getList() {
      this.loading = true;
      ErrorLogList(this.searchParams).then(res => {
        this.dataList = res.data.list;
        this.total = res.data.count;
        this.loading = false;
      });
      ErrorLogversion({}).then(res => (this.version_list = res.data));
    },

    // 复制路径
    copy(path) {
      var sinput = document.createElement("input");
      sinput.setAttribute("value", path);
      document.body.appendChild(sinput);
      sinput.select(); // 选中文本
      try {
        var copyed = document.execCommand("copy");
        if (copyed) {
          document.body.removeChild(sinput);
          this.$message.success(`复制成功`);
        }
      } catch {
        this.$message.error("复制失败,请检查浏览器兼容");
      }
    },

    startDisabledDate(current) {
      return current && current < moment(this.end_time).subtract(3, "month");
    },

    endDisabledDate(current) {
      return current && current > moment(this.start_time).add(3, "month");
    },

    onStartChange(date, dateString) {
      this.start_time = moment(dateString);
      this.sendTime();
      this.ranges = -2;
    },

    onEndChange(date, dateString) {
      this.end_time = moment(dateString);
      this.sendTime();
      this.ranges = -2;
    },

    onChangetime(date, dateString) {
      this.start_time = moment(dateString[0]);
      this.end_time = moment(dateString[1]);
      this.sendTime();
    },

    sendTime() {
      if (moment(this.start_time).unix() > moment(this.end_time).unix()) return this.$message.error("开始时间不能大于结束时间");
      this.searchParams.start_time = moment(this.start_time).unix();
      this.searchParams.end_time = moment(this.end_time).unix();
      this.getList();
    },

    rangeChange() {
      if (this.ranges == -2) {
        this.start_time = undefined;
        this.end_time = undefined;
      } else {
        this.onChangetime("", this.$getdayTime(this.ranges));
      }
    },
  },
  created() {
    this.getList();
    this.searchParams.seller_type = 1;
    this.searchParams.store_type = 1;
    this.searchParams.store_keywords = "";
    this.searchParams.seller_keywords = "";
  },
};
</script>
<style lang="less" scoped>
/deep/.ant-select {
  width: 130px;
}
</style>
