var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-card',[_c('a-row',{staticStyle:{"margin-top":"10px"}},[_c('a-input',{staticStyle:{"width":"200px","margin-right":"20px"},attrs:{"placeholder":"请输入商户名称"},model:{value:(_vm.searchParams.seller_name),callback:function ($$v) {_vm.$set(_vm.searchParams, "seller_name", $$v)},expression:"searchParams.seller_name"}}),_c('a-input',{staticStyle:{"width":"200px","margin-right":"20px"},attrs:{"placeholder":"请输入商户ID"},model:{value:(_vm.searchParams.seller_id),callback:function ($$v) {_vm.$set(_vm.searchParams, "seller_id", $$v)},expression:"searchParams.seller_id"}}),_c('a-input',{staticStyle:{"width":"200px"},attrs:{"placeholder":"请输入门店ID"},model:{value:(_vm.searchParams.store_id),callback:function ($$v) {_vm.$set(_vm.searchParams, "store_id", $$v)},expression:"searchParams.store_id"}}),_c('a-button',{staticStyle:{"margin-left":"10px"},on:{"click":_vm.getList}},[_vm._v("查询")])],1),_c('a-table',{attrs:{"loading":_vm.loading,"columns":_vm.columns,"data-source":_vm.dataList,"tableLayout":"fixed","pagination":{
        current: _vm.searchParams.page,
        pageSize: _vm.searchParams.limit,
        total: _vm.total,
        showLessItems: true,
        showQuickJumper: true,
        showTotal: function (total, range) { return ("总计 " + total + " 条"); },
        onChange: _vm.onPageChange,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'],
        onShowSizeChange: _vm.onPageChange,
      }},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return [(record.status == 1)?_c('a-popconfirm',{attrs:{"title":"请谨慎操作，一旦作废，该机器直接清零无法使用!","ok-text":"是","cancel-text":"否"},on:{"confirm":function($event){return _vm.statusChange(record)}}},[_c('a',{staticStyle:{"color":"red"}},[_vm._v(" 作废 ")])]):_vm._e()]}}])})],1),_c('a-modal',{attrs:{"title":"修改过期时间"},on:{"ok":_vm.expireTime,"cancel":_vm.handleCancel},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-form-model',{ref:"ruleForm",attrs:{"form":_vm.form,"model":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 14, offset: 1 }}},[_c('a-form-model-item',{attrs:{"label":"过期时间","prop":"name"}},[(_vm.visible)?_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"show-time":"","placeholder":"请选择时间"},on:{"change":_vm.onChange,"ok":_vm.onOk}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }