var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-card',[_c('a-table',{attrs:{"loading":_vm.loading,"columns":_vm.columns,"data-source":_vm.dataList,"tableLayout":"fixed","pagination":{
        current: _vm.searchParams.page,
        pageSize: _vm.searchParams.limit,
        total: _vm.total,
        showLessItems: true,
        showQuickJumper: true,
        showTotal: function (total, range) { return ("总计 " + total + " 条"); },
        onChange: _vm.onPageChange,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'],
        onShowSizeChange: _vm.onPageChange,
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }