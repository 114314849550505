// 公共接口
import request from "../utils/request";

// 登录
export async function login(data) {
  return request({
    url: "/login/login",
    method: "post",
    data,
  });
}

// 退出
export async function logout(data) {
  return request({
    url: "/login/logout",
    method: "post",
    data,
  });
}

// 菜单
export async function menu(data) {
  return request({
    url: "/Node/list",
    method: "post",
    data,
  });
}

// 支付方式名称(公共)
export async function paymentName(data) {
  return request({
    url: "/Payment/paymentName",
    method: "post",
    data,
  });
}

// 出入库分类(公共)
export async function cateName(data) {
  return request({
    url: "/WarehouseCate/cateName",
    method: "post",
    data,
  });
}

// 角色列表(公共)
export async function roleName(data) {
  return request({
    url: "/Role/roleName",
    method: "post",
    data,
  });
}

// 省市区列表
export async function AreaList(data) {
  return request({
    url: "/Area/list",
    method: "post",
    data,
  });
}


// 上传图片
export async function image(data) {
  return request({
    url: "/Seller/image",
    method: "post",
    data,
  });
}

// 上传文件
export async function upload(data) {
  return request({
    url: "/upload/upload",
    method: "post",
    data,
  });
}
