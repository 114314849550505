<template>
  <div>
    <a-card>
      <a-button type="primary" @click="addexpress(1)">新增快递公司</a-button>
      <!-- 快递公司列表 -->
      <a-table :loading="loading" :columns="columns" :data-source="dataList" :rowKey="(record) => record.express_id" tableLayout="fixed" :pagination="false">
        <template slot="action" slot-scope="text, record">
          <a @click="addexpress(2,record)" style="margin-right:10px;">编辑</a>
          <a-popconfirm title="确定删除该快递公司吗?" ok-text="是" cancel-text="否" @confirm="confirm(record.express_id)">
            <a class="error-color">删除</a>
          </a-popconfirm>
        </template>
      </a-table>
    </a-card>
    <!-- 添加快递公司 -->
    <a-modal v-model="visible" :title='`${modalText}快递公司`' @ok="handleOk" @cancel="handleCancel">
      <a-form-model ref="ruleForm" :form="form" :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16, offset: 1 }">
        <a-form-model-item label="快递公司名称" prop='express_name'>
          <a-input placeholder="请输入快递公司名称" v-model.trim="form.express_name" show-word-limit />
        </a-form-model-item>
        <a-form-model-item label="类型">
          <a-select placeholder="请选择类型" v-model="form.type">
            <a-select-option :value="1">快递</a-select-option>
            <a-select-option :value="2">同城</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { expressList,expressAdd,expressEdit,expressDel } from '@/views/api/system'
import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins], 
  components: {},
  data() {
    return {
      form: {
        status: 0
      },
      modalText: '',
      columns: [
      {
        title: '快递公司ID',
        dataIndex: 'express_id',
      },
      {
        title: '快递公司名称',
        dataIndex: 'express_name',
      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
      },
      {
        title: '图标',
        dataIndex: 'icon',
      },
      {
        title: '类型',
        dataIndex: 'type',
        customRender: (text) => {
        return text == 1 ? "快递" : '同城';
        },
      },
      {
        title: '操作',
        scopedSlots: {
          customRender: 'action'
        }
      }],
    };
  },
  computed: {},
  methods: {
    getList() {
      this.loading = true
      expressList(this.searchParams).then(res=>{
        this.dataList = res.data
        this.loading = false
      })
    },
    // 确认
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) return this.addEdit();
        return false;
      });
    },
    // 添加编辑
    addEdit() {
      this.flag == 1 ? expressAdd(this.form).then((res) => this.handleSuccess(1,res)) : expressEdit(this.form).then((res) => this.handleSuccess(2,res));
    },
    // 操作成功
    handleSuccess(flag,res) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? '添加' : flag == 2 ? '编辑' : flag == 3 ? '删除' : '修改状态'}成功`);
        this.visible = false;
        this.getList()
      }
    },
    // 弹框关闭
    handleCancel() {
      this.visible = false;
    },
    // 新增快递公司
    addexpress(flag,row) {
      this.form = {}
      this.flag = flag;
      this.visible = true;
      if (row) {
        this.form = JSON.parse(JSON.stringify(row));
        this.modalText = "编辑";
      } else {
        this.form.status = 1
        this.modalText = "新增";
      }
    },
    // 删除
    confirm(express_id) {
      expressDel({ express_id }).then((res) => this.handleSuccess(3,res));
    },
    // 弹框启用状态
    statusChange(checked) {
      this.$forceUpdate()
      checked ? this.form.status = 1 : this.form.status = 0
    },
  },
  created() {
    this.getList()
  },
};
</script>
<style lang="less" scoped>
</style>