<template>
  <div>
    <a-card>
      <!-- tabs切换菜单 -->
      <a-tabs type="card" @change="callback" v-model="type">
        <a-tab-pane :key="1" tab="零售"> </a-tab-pane>
        <a-tab-pane :key="2" tab="线上"> </a-tab-pane>
        <a-tab-pane :key="4" tab="会员"> </a-tab-pane>
        <a-tab-pane :key="16" tab="私域增长"> </a-tab-pane>
      </a-tabs>
      <a-button :loading="loading" type="primary" @click="addNode(null)">新增菜单</a-button>
      <!-- 列表区域 -->
      <a-table :pagination="false" :loading="loading" tableLayout="fixed" childrenColumnName="child" :columns="columns" :data-source="dataList" :rowKey="record => record.node_id">
        <template slot="action" slot-scope="text, record">
          <a @click="addChildrenNode(record)">新建子菜单</a>
          <a class="success-color" @click="addNode(record.node_id)">编辑</a>
          <a-popconfirm title="确定删除吗?" ok-text="是" cancel-text="否" @confirm="confirm(record.node_id)">
            <a class="error-color">删除</a>
          </a-popconfirm>
        </template>
      </a-table>
      <!-- 弹出框 -->
      <a-modal v-model="visible" :title="`${modalText}菜单`" @ok="handleOk" width="35%">
        <a-form-model ref="ruleForm" :form="form" :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item label="上级菜单">
            <a-cascader
              :options="dataList"
              v-model="parent_ids"
              :field-names="{
                label: 'title',
                value: 'node_id',
                children: 'child',
              }"
              placeholder="不选择默认为顶级菜单"
              @change="parentSortChange"
              change-on-select />
          </a-form-model-item>
          <a-form-model-item label="规则标识" ref="name" prop="name">
            <a-input placeholder="请输入规则标识" v-model.trim="form.name" />
          </a-form-model-item>
          <a-form-model-item label="标题名称">
            <a-input placeholder="请输入标题名称" v-model.trim="form.title" />
          </a-form-model-item>
          <a-form-model-item label="图标">
            <a-input placeholder="请输入图标名称" v-model.trim="form.icon" />
          </a-form-model-item>
          <a-form-model-item label="前端路径">
            <a-input placeholder="请输入前端路径" v-model.trim="form.path" />
          </a-form-model-item>
          <a-form-model-item label="后端路径">
            <a-input placeholder="请输入后端路径" v-model.trim="form.pathinfo" />
          </a-form-model-item>
          <a-form-model-item label="排序">
            <a-input v-model.trim="form.sort" placeholder="请输入排序" />
          </a-form-model-item>
          <a-form-model-item label="启用状态">
            <a-switch v-if="visible" :checked="form.status == 0 ? false : true" @change="statusChange" />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import { SellerNodeList, SellerNodeDel, SellerNodeAdd, SellerNodeEdit, SellerNodeDetail } from "@/views/api/seller";
// import moment from "moment";
import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
      modalText: "",
      key: "",
      modelSelect: [],
      form: {},
      type: 1, // 类型 （暂时写死）
      parent_ids: [],
      searchParams: {
        limit: 10,
        page: 1,
        type: 1,
      },
      columns: [
        {
          title: "标题名称",
          dataIndex: "title",
        },
        //   {
        //     title: '菜单ID',
        //     dataIndex: 'node_id',
        //     key: "node_id",
        //   },
        {
          title: "规则标识",
          dataIndex: "name",
        },

        {
          title: "字体图标",
          dataIndex: "icon",
        },
        {
          title: "路径",
          dataIndex: "path",
        },
        {
          title: "操作",
          scopedSlots: {
            customRender: "action",
          },
        },
      ],
    };
  },
  computed: {},
  methods: {
    getList() {
      this.loading = true;
      SellerNodeList(this.searchParams).then(res => {
        this.dataList = res.data;
        this.loading = false;
      });
    },
    // 分页
    onPageChange(page, pageSize) {
      this.searchParams.page = page;
      this.searchParams.limit = pageSize;
      this.getList();
    },
    // 新增节点
    addNode(node_id) {
      if (node_id) {
        SellerNodeDetail({ node_id }).then(res => {
          this.form = res.data;
          this.parent_ids = res.data.parent_ids;
          this.type = this.form.type
          this.modalText = "编辑";
          this.visible = true;
        });
      } else {
        this.modalText = "新增";
        this.form = {};
        this.parent_ids = "";
        this.form.status = 1;
        this.visible = true;
      }
    },
    // 新增子节点
    addChildrenNode(row) {
      SellerNodeDetail({ node_id:row.node_id}).then(res => {
        this.form = {};
        this.modalText = "新增子";
        this.parent_ids = [...res.data.parent_ids,row.node_id];
        this.form.parent_id = row.node_id;
        this.visible = true;
      });
    },
    // 删除
    confirm(node_id) {
      SellerNodeDel({ node_id }).then(res => {
        if (res.code == 200) {
          this.$message.success("删除成功");
          this.getList();
        }
      });
    },
    // 级联选择框 change
    parentSortChange(val) {
      this.form.node_id = val[val.length - 1];
    },
    handleOk() {
      this.form.type = this.type;
      console.log(this.form);
      if (this.form.node_id) {
        SellerNodeEdit(this.form).then(res => {
          if (res.code == 200) {
            this.$message.success("编辑成功");
            this.visible = false;
            this.getList();
          }
        });
      } else {
        SellerNodeAdd(this.form).then(res => {
          if (res.code == 200) {
            this.$message.success("添加成功");
            this.visible = false;
            this.getList();
          }
        });
      }
    },
    // 弹框启用状态
    statusChange() {
      this.$forceUpdate();
      this.form.status == 1 ? (this.form.status = 0) : (this.form.status = 1);
    },

    callback(key) {
      this.key = key;
      this.searchParams.type = key;
      this.getList();
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="less" scoped>
a {
  margin: 0 8px;
}
</style>
