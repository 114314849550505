<template>
  <div>
    <a-modal v-model="visible" title="选择商户" @ok="handleOk" width="70%">
      <a-row style="margin-top: 10px">
        <a-input placeholder="商户号/商户名称/手机号" v-model.trim="searchParams.keywords" style="width: 200px"></a-input>
        <a-button @click="getList" style="margin-left: 10px">查询</a-button>
        <a @click="clear" style="margin-left:10px;">重置查询</a>
      </a-row>
      <!-- 列表区域  -->
      <a-table
        style="margin-top: 20px"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :loading="loading"
        :columns="columns"
        :data-source="orderList"
        :rowKey="record => record.seller_id"
        :pagination="{
          current: searchParams.page,
          pageSize: searchParams.limit,
          total: total,
          showLessItems: true,
          showQuickJumper: true,
          showTotal: (total, range) => `总计 ${total} 条`,
          onChange: onPageChange,
        }">
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { SellerList } from "@/views/api/seller";
import moment from "moment";

const columns = [
  {
    title: "商户ID",
    dataIndex: "seller_id",
  },
  {
    title: "商户名称",
    dataIndex: "seller_name",
  },
  {
    title: "开始时间",
    dataIndex: "start_time",
    customRender: text => {
      return moment(text * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  {
    title: "结束时间",
    dataIndex: "end_time",
    customRender: text => {
      return moment(text * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  {
    title: "门店数量",
    dataIndex: "store_num",
  },
  {
    title: "机器数量",
    dataIndex: "machine_num",
  },
  {
    title: "短信签名",
    dataIndex: "sms_sign",
  },
  {
    title: "语音剩余条数",
    dataIndex: "voice_num",
  },
];
export default {
  name: "goodsModal",
  data() {
    return {
      visible: false,
      columns,
      orderList: [],
      loading: false,
      total: 0,
      searchParams: {
        page: 1,
        limit: 10,
      },
      selectedRows: [],
      selectedRowKeys: [],
      keys: [],
      exchang_id: "",
    };
  },
  computed: {},

  methods: {
    moment,
    handleOk() {
      this.visible = false;
      this.$emit("func", this.selectedRows, this.selectedRowKeys);
    },

    childMethod(keys) {
      this.visible = true;
      this.selectedRowKeys = keys;
      this.getList();
    },

    getList() {
      SellerList(this.searchParams).then(res => {
        this.orderList = res.data.list;
        this.total = res.data.count;
      });
    },

    clear() {
      this.searchParams.keywords = ''
      this.getList()
    },

    onPageChange(page, pageSize) {
      this.searchParams.page = page;
      this.searchParams.limit = pageSize;
      this.getList();
    },

    onSelectChange(keys, rows) {
      this.selectedRowKeys = keys;
      if (keys.length == rows.length) {
        this.selectedRows = rows;
      } else {
        this.selectedRows = keys.map(key => this.selectedRows.find(row => row.sku_id == key) || rows.find(row => row.sku_id == key));
      }
    },
  },
  created() {},
};
</script>
<style lang="less" scoped></style>
