<template>
  <div>
    <a-card>
      <div class="title">{{ params ? "编辑" : "添加" }}商户</div>
      <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-form-model-item label="销售类型" style="width: 40%" prop="agent_id">
          <a-radio-group v-model="agent_id" :disabled="params ? true : false" @change="typeChange">
            <a-radio :value="0">云果直销</a-radio>
            <a-radio :value="1">代理商直销</a-radio>
          </a-radio-group>

          <a-select
            v-if="agent_id == 1"
            show-search
            :filter-option="this.$filterOption"
            :disabled="params ? true : false"
            placeholder="请选择"
            v-model="form.agent_id"
            style="margin: 0 20px 20px 0; width: 150px">
            <a-select-option v-for="item in agent_ids_list" :key="item.agent_id" :value="item.agent_id"> {{ item.agent_name }} </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="续费模式" style="width: 50%" prop="renewal_money">
          <a-radio-group v-model="form.renewal_model" @change="typeChange">
            <a-radio :value="1">按店固定续费 <a-input style="width: 100px" v-if="form.renewal_model == 1" v-model="form.renewal_money" /> /店/年 </a-radio>
            <a-radio :value="2">按商户打包统一续费 <a-input style="width: 100px" v-if="form.renewal_model == 2" v-model="form.renewal_money" /> /年</a-radio>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label="收银端续费展示" v-if="agent_id == 1 || (agent_id == 0 && form.renewal_model == 2)" style="width: 40%" prop="renewal_type">
          <a-radio-group v-model="form.renewal_type">
            <a-radio :value="1" :disabled="form.renewal_model == 2">收银端提示续费并可直接续费</a-radio>
            <a-radio :value="2">收银端仅提示续费</a-radio>
          </a-radio-group>
        </a-form-model-item>

        <br />

        <a-form-model-item label="商户ID" prop="seller_id">
          <a-input v-if="!params" placeholder="请输入商户ID" v-model="form.seller_id" style="width: 60%" />
          <div v-else>{{ form.seller_id }}</div>
          <a-button v-if="!params" type="primary" @click="getSellerId" style="width: 40%">获取商户ID</a-button>
        </a-form-model-item>

        <a-form-model-item label="商户名称" prop="seller_name">
          <a-input placeholder="请输入商户名称" v-model="form.seller_name" />
        </a-form-model-item>

        <a-form-model-item v-if="!params" label="密码" prop="password">
          <a-input placeholder="请输入商户密码" v-model="form.password" />
        </a-form-model-item>

        <a-form-model-item label="手机号码" prop="mobile">
          <a-input placeholder="请输入手机号码" v-model="form.mobile" />
        </a-form-model-item>

        <a-form-model-item label="姓名" prop="rename">
          <a-input placeholder="请输入姓名" v-model="form.rename" />
        </a-form-model-item>

        <a-form-model-item label="门店数量" prop="store_num">
          <a-input placeholder="请输入门店数量" v-model="form.store_num" @change="storeNum" />
        </a-form-model-item>

        <a-form-model-item label="机器数量" prop="machine_num">
          <a-input placeholder="请输入机器数量" v-model="form.machine_num" />
        </a-form-model-item>

        <a-form-model-item label="省市区" prop="city">
          <a-select placeholder="请选择省" v-model="form.province_id" style="width: 33.3%" @change="AreaList(1)">
            <a-select-option v-for="d in province_List" :key="d.area_id">
              {{ d.area_name }}
            </a-select-option>
          </a-select>
          <a-select placeholder="请选择市" v-model="form.city_id" style="width: 33.3%" @change="AreaList(2)">
            <a-select-option v-for="d in city_List" :key="d.area_id">
              {{ d.area_name }}
            </a-select-option>
          </a-select>
          <a-select placeholder="请选择区" v-model="form.district_id" style="width: 33.3%" @change="AreaList(3)">
            <a-select-option v-for="d in district_List" :key="d.area_id" :value="d.area_id">
              {{ d.area_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="详细地址" prop="address">
          <a-input placeholder="请输入详细地址" v-model="form.address" />
        </a-form-model-item>

        <a-form-model-item label="选择时间" prop="time">
          <!-- <a-range-picker @change="onChangetime" v-model="add_time_s" show-time style="width: 100%" format="YYYY/MM/DD HH:mm:ss" /> -->
          <a-date-picker v-model="start" placeholder="请选择开始时间" :allowClear="false" @change="onStartChange" style="width: 46%" />
          <span style="width: 3%; margin: 0 1%">-</span>
          <a-date-picker v-model="end" placeholder="请选择结束时间" :allowClear="false" @change="onEndChange" style="width: 46%" />
        </a-form-model-item>

        <a-form-model-item label="语音剩余条数" prop="voice_num">
          <a-input placeholder="请输入语音剩余条数" v-model="form.voice_num" />
        </a-form-model-item>

        <br />

        <a-form-model-item v-if="form" label="开通的支付方式" prop="payment_num" style="width: 100%" :label-col="{ span: 2 }" :wrapper-col="{ span: 22 }">
          <!-- @change="onChange"  -->
          <a-checkbox-group v-model="form.payment_num">
            <!-- <a-row> -->
            <!-- <a-col :md="2" :sm="24" v-for="item in paymentList" :key="item.payment_type"> -->
            <span v-for="item in paymentList" :key="item.payment_type">
              <a-checkbox :value="item.payment_type" :disabled="form.payment_num && save_payment_num.includes(item.payment_type)">
                {{ item.payment_name }}
              </a-checkbox>
            </span>
            <!-- </a-col> -->
            <!-- </a-row> -->
          </a-checkbox-group>
        </a-form-model-item>

        <br />

        <a-form-model-item v-if="form" label="开通产品" prop="products">
          <a-checkbox-group style="width: 100%" v-model="form.products">
            <a-checkbox :value="1" :disabled="form.products && save_products.includes(1)"> 零售 </a-checkbox>
            <a-checkbox :value="2" :disabled="form.products && save_products.includes(2)"> 线上 </a-checkbox>
            <a-checkbox :value="4" :disabled="form.products && save_products.includes(4)"> 私域增长 </a-checkbox>
          </a-checkbox-group>
        </a-form-model-item>

        <br />

        <a-form-model-item label="小程序" v-if="form && form.products && form.products.includes(1)">
          <a-checkbox-group style="width: 100%" v-model="form.sub_products">
            <a-checkbox :value="1"> 会员版小程序 </a-checkbox>
          </a-checkbox-group>
        </a-form-model-item>
        <br />

        <a-form-model-item label="短信类型">
          <a-radio-group v-model="form.sms_type">
            <a-radio :value="0">无</a-radio>
            <a-radio :value="1">阿里短信</a-radio>
            <a-radio :value="2">亿美软通</a-radio>
          </a-radio-group>
        </a-form-model-item>

        <br />

        <a-form-model-item label="短信签名" prop="sms_sign" v-if="form.sms_type == 1 || form.sms_type == 2">
          <a-input placeholder="请输入短信签名" v-model="form.sms_sign" />
        </a-form-model-item>

        <br />

        <a-form-model-item label="营业执照" prop="certificates">
          <div class="clearfix">
            <a-upload :customRequest="handleChange" list-type="picture-card" :before-upload="beforeUpload" :show-upload-list="false">
              <img v-if="certificates" :src="certificates" class="avatar" />
              <div v-else>
                <div class="ant-upload-text">点击上传</div>
              </div>
            </a-upload>
          </div>
        </a-form-model-item>

        <a-form-model-item label="法人身份证(正)" prop="idcard_pros">
          <div class="clearfix">
            <a-upload :customRequest="handleChangepros" list-type="picture-card" :before-upload="beforeUpload" :show-upload-list="false">
              <img v-if="idcard_pros" :src="idcard_pros" class="avatar" />
              <div v-else>
                <div class="ant-upload-text">点击上传</div>
              </div>
            </a-upload>
          </div>
        </a-form-model-item>

        <a-form-model-item label="法人身份证(反)" prop="idcard_cons">
          <div class="clearfix">
            <a-upload :customRequest="handleChangecons" list-type="picture-card" :before-upload="beforeUpload" :show-upload-list="false">
              <img v-if="idcard_cons" :src="idcard_cons" class="avatar" />
              <div v-else>
                <div class="ant-upload-text">点击上传</div>
              </div>
            </a-upload>
          </div>
        </a-form-model-item>

        <a-form-model-item label="备注" prop="notes">
          <a-textarea v-model="form.notes"></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-card>
    <a-card class="m-t-10">
      <a-row>
        <a-col :offset="3">
          <a-button style="margin-right: 15px" @click="back()">取消</a-button>
          <a-button type="primary" @click="addEdit" :loading="loading">提交</a-button>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { paymentName, AreaList, image } from "@/views/api/common";
import { SellerAdd, SellerEdit, SellerDetail, getSellerId, agentlist } from "@/views/api/seller";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

import moment from "moment";

export default {
  data() {
    let validateTime = (rule, value, callback) => {
      if (this.form.sms_type && !this.form.sms_sign) {
        return callback(new Error("请选择开始时间"));
      } else if (!this.end) {
        return callback(new Error("请选择结束时间"));
      } else {
        return callback();
      }
    };
    let validateagent = (rule, value, callback) => {
      if (this.agent_id == 1 && !this.form.agent_id) {
        return callback(new Error("请选择代理商"));
      } else {
        return callback();
      }
    };
    let validateCity = (rule, value, callback) => {
      if (!this.form.province_id || !this.form.city_id || !this.form.district_id) {
        return callback(new Error("请选择省市区"));
      } else {
        return callback();
      }
    };
    // let validchecksignValidatorateCity = (rule, value, callback) => {
    //   if (!this.form.province_id || !this.form.city_id || !this.form.district_id) {
    //     return callback(new Error("请选择省市区"));
    //   } else {
    //     return callback();
    //   }
    // };
    // 检验手机号
    let checkMobileValidator = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入11位手机号"));
      } else {
        const reg = /^1[3456789]\d{9}$/;
        if (!reg.test(value)) {
          return callback(new Error("请输入正确的手机号"));
        } else {
          return callback();
        }
      }
    };
    // 检验姓名
    let checkNameValidator = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入姓名"));
      } else {
        const chineseRegex = /^[\u4e00-\u9fa5]+$/;
        if (!chineseRegex.test(value)) {
          return callback(new Error("姓名只能是汉字"));
        } else {
          return callback();
        }
      }
    };
    return {
      params: "",
      form: {
        seller_id: "",
        start_time: null,
        end_time: null,
        payment_num: [1, 2, 8, 16],
        sms_type: 0,
        store_num: 1,
        machine_num: 2,
        voice_num: 0,
        sub_products: [],
        products: [],
        renewal_money: "800",
        agent_id: undefined,
        renewal_model: 1,
        renewal_type: 1,
      },
      payment_disabled: [1, 2, 8, 16],
      start: null,
      end: null,
      paymentList: [],
      province_List: [],
      city_List: [],
      district_List: [],
      idcard_cons: "",
      idcard_pros: "",
      certificates: "",
      fileList: [],
      add_time_s: [],
      loading: false,

      rules: {
        seller_id: { required: true, message: "请输入商户号", trigger: "change" },
        seller_name: { required: true, message: "请输入商户名称", trigger: "change" },
        password: { required: true, message: "请输入密码", trigger: "change" },
        store_num: { required: true, message: "请输入门店数量", trigger: "change" },
        machine_num: { required: true, message: "请输入机器数量", trigger: "change" },
        address: { required: true, message: "请输入详细地址", trigger: "change" },
        products: { required: true, message: "请输入开通产品", trigger: "change" },

        mobile: { required: true, validator: checkMobileValidator, trigger: "change" },
        time: { required: true, validator: validateTime, trigger: "change" },
        city: { required: true, validator: validateCity, trigger: "change" },
        rename: { required: true, validator: checkNameValidator, trigger: "change" },
        sms_sign: { required: true, message: "请输入短信签名", trigger: "change" },
        agent_id: { required: true, validator: validateagent, trigger: "change" },
        renewal_money: { required: true, message: "请输入续费金额", trigger: "change" },
      },

      save_products: [],
      save_payment_num: [],

      agent_ids_list: [],

      agent_id: 0,
    };
  },
  computed: {},
  methods: {
    moment,
    // 商户号
    getSellerId() {
      getSellerId({}).then(res => {
        this.form.seller_id = res.data;
      });
    },

    typeChange() {
      if (this.form.renewal_model == 2) this.form.renewal_type = 2;
    },

    agentlist() {
      if (this.agent_ids_list.length != 0) return;
      agentlist({ page: 0, limit: 0, status: 1 }).then(res => {
        this.agent_ids_list = res.data.list;
        this.loading = false;
      });
    },

    // 支付方式
    paymentName() {
      paymentName({}).then(res => {
        this.paymentList = res.data;
        this.paymentList.forEach(item => {
          item.value = item.payment_type;
          item.label = item.payment_name;
        });
      });
    },

    // 详情
    SellerDetail() {
      SellerDetail({ seller_id: this.form.seller_id }).then(res => {
        if (!res.data.sub_products) res.data.sub_products = [];
        this.form = res.data;
        res.data.agent_id > 0 ? (this.agent_id = 1) : (this.agent_id = 0);
        this.idcard_cons = res.data.idcard_cons_full;
        this.idcard_pros = res.data.idcard_pros_full;
        this.certificates = res.data.certificates_full;
        this.save_products = JSON.parse(JSON.stringify(res.data.products));
        this.save_payment_num = JSON.parse(JSON.stringify(res.data.payment_num));
        this.onStartChange("", moment(res.data.start_time * 1000));
        this.onEndChange("", moment(res.data.end_time * 1000));
        AreaList({ area_id: res.data.province_id }).then(result => {
          this.city_List = result.data;
        });
        AreaList({ area_id: res.data.city_id }).then(result => {
          this.district_List = result.data;
        });
      });
    },

    // 省市区
    AreaList(area_id) {
      const area = area_id;
      if (area_id == 1) {
        area_id = this.form.province_id;
        this.city_List = [];
        this.form.city_id = undefined;
        this.district_List = [];
        this.form.district_id = undefined;
      }
      if (area_id == 2) {
        area_id = this.form.city_id;
        this.district_List = [];
        this.form.district_id = undefined;
      }
      AreaList({ area_id: area_id }).then(res => {
        if (area == 0) this.province_List = res.data;
        if (area == 1) this.city_List = res.data;
        if (area == 2) this.district_List = res.data;
      });
      if (area_id == 3) this.changeCity();
      this.$forceUpdate();
    },

    onStartChange(date, dateString) {
      this.start = moment(dateString);
      this.form.start_time = moment(dateString).unix();
    },

    onEndChange(date, dateString) {
      this.end = moment(dateString);
      this.form.end_time = moment(dateString).unix();
    },

    // 取消 - 返回
    back() {
      this.$router.go(-1);
    },

    // 添加编辑
    addEdit() {
      this.$refs.ruleForm.validate(valid => {
        console.log(valid);
        if (!valid) {
          return false;
        } else {
          if (!this.agent_id) this.form.agent_id = 0;
          this.params ? SellerEdit(this.form).then(res => this.handleSuccess(1, res)) : SellerAdd(this.form).then(res => this.handleSuccess(2, res));
        }
      });
    },

    changeCity() {
      this.$nextTick(() => {
        this.$refs.ruleForm.validate(valid => {
          if (!valid) {
            return false;
          } else {
            return true;
          }
        });
      });
    },

    // 操作成功
    handleSuccess(flag, res) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? "编辑" : "添加"}成功`);
        this.back();
      }
    },

    // 上传前钩子
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        const type = file.type;
        const isJpgOrPng = type === "image/jpeg" || type === "image/png" || type === "image/jpg";
        if (!isJpgOrPng) {
          this.$message.error("只能上传jpeg、png、jpg文件!");
          return reject(false); //如有问题通过reject 函数传一个false阻止他的上传
        } else {
          return resolve(true);
        }
      });
    },

    // 上传
    async handleChange({ file }) {
      this.loading = true;
      getBase64(file, async imageUrl => {
        var params = { name: file.name, url: imageUrl };
        await image(params).then(res => {
          if (res.code == 200) {
            this.certificates = imageUrl;
            this.form.certificates = res.data.path;
          }
          this.loading = false;
        });
      });
    },

    // 上传
    async handleChangecons({ file }) {
      this.loading = true;
      getBase64(file, async imageUrl => {
        var params = { name: file.name, url: imageUrl };
        await image(params).then(res => {
          if (res.code == 200) {
            this.idcard_cons = imageUrl;
            this.form.idcard_cons = res.data.path;
          }
          this.loading = false;
        });
      });
    },

    // 上传
    async handleChangepros({ file }) {
      this.loading = true;
      getBase64(file, async imageUrl => {
        var params = { name: file.name, url: imageUrl };
        await image(params).then(res => {
          if (res.code == 200) {
            this.idcard_pros = imageUrl;
            this.form.idcard_pros = res.data.path;
          }
          this.loading = false;
        });
      });
    },

    storeNum() {
      this.form.machine_num = this.form.store_num * 2;
    },
  },
  created() {
    this.agentlist();
    this.paymentName();
    this.AreaList(0);
    this.params = this.$route.query.row;
    if (this.params) {
      this.form = this.params;
      this.SellerDetail();
    } else {
      this.getSellerId();
      this.onStartChange("", moment());
      this.onEndChange("", moment().add(1, "y"));
    }
  },
};
</script>
<style scoped lang="less">
.ant-form-item-label {
  text-align: center !important;
}
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 4px;
}

/deep/ .ant-form-item {
  width: 33%;
  display: inline-block;
}
</style>
