<template>
  <div>
    <a-modal v-model="visible" title="选择门店" @ok="handleOk" width="80%">
      <a-row style="margin-top: 10px">
        <a-input placeholder="门店名称" v-model="searchParams.store_name" style="width: 200px"></a-input>
        <a-button @click="getList" style="margin-left: 10px">查询</a-button>
      </a-row>

      <a-table
        style="padding: 0"
        :loading="loading"
        size="small"
        :columns="columns"
        :data-source="dataList"
        :rowKey="record => record.store_id"
        tableLayout="fixed"
        :row-selection="{ selectedRowKeys: selectedRowKeys, selectedRows: selectedRows, onChange: onSelectChange }"
        :locale="{ emptyText: '请先搜索商户' }"
        :scroll="{ x: 1600 }"
        :pagination="{
          current: searchParams.page,
          pageSize: searchParams.limit,
          total: total,
          showLessItems: true,
          showQuickJumper: true,
          showTotal: (total, range) => `总计 ${total} 条`,
          onChange: onPageChange,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          onShowSizeChange: onPageChange,
        }">
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { sellerRenewstoreList } from "@/views/api/seller";
import { mixins } from "../../mixin/index";

export default {
  mixins: [mixins],

  data() {
    return {
      columns: [
        {
          title: "门店编号",
          dataIndex: "store_number",
          fixed: "left",
          width: 80,
        },
        {
          title: "门店名称",
          dataIndex: "store_name",
          fixed: "left",
        },
        {
          title: "销售类型",
          dataIndex: "agent_id",
             customRender: text => {
            return text == 0 ? "云果直销" : "代理商直销";
          },
        },
        {
          title: "省市区",
          dataIndex: "province_name",
          customRender: (text, record) => {
            return text + record.city_name + record.district_name;
          },
          width: 200,
        },
        {
          title: "门店经营类型",
          dataIndex: "store_type",
          customRender: text => {
            return text == 1 ? "直营" : "加盟";
          },
        },
        {
          title: "门店创建时间",
          dataIndex: "create_time",
        },
        {
          title: "到期日期",
          dataIndex: "expire_time",
        },
        {
          title: "续费状态",
          dataIndex: "renew_status",
          customRender: text => {
            return text == 0 ? "已到期" : text == 1 ? "未到期" : text == 2 ? "即将到期" : "";
          },
          width: 80,
        },
        {
          title: "待续费金额",
          dataIndex: "renew_money",
        },
        {
          title: "续费时间",
          dataIndex: "renew_time",
          width: 80,
        },
        {
          title: "续费后到期日期",
          dataIndex: "renew_expire_date",
        },
      ],

      dataList: [],
      total: 0,
      visible: false,

      selectedRowKeys: [],
      selectedRows: [],
      seller_id: null,
    };
  },
  computed: {},
  methods: {
    getList(is_search) {
      if (!this.seller_id) return;
      this.searchParams.seller_id = this.seller_id;
      this.loading = true;
      if (is_search) this.searchParams.page = 1;
      sellerRenewstoreList(this.searchParams).then(res => {
        this.dataList = res.data.list;
        this.total = res.data.count;
        this.loading = false;
      });
    },

    // 分页
    onPageChange(page, pageSize) {
      this.searchParams.page = page;
      this.searchParams.limit = pageSize;
      this.getList();
    },

    childMethod(seller_id,store,store_id_list) {
      this.seller_id = seller_id;

      this.getList();

      this.selectedRows = store;
      this.selectedRowKeys = store_id_list;
      this.visible = true;
    },

    handleOk() {
      this.visible = false;
      this.$emit("getStore", this.selectedRows, this.selectedRowKeys);
    },

    onSelectChange(keys, rows) {
      this.selectedRowKeys = keys;
      if (keys.length == rows.length) {
        this.selectedRows = rows;
      } else {
        this.selectedRows = keys.map(key => this.selectedRows.find(row => row.store_id == key) || rows.find(row => row.store_id == key));
      }
    },
  },
  created() {},
};
</script>
<style lang="less" scoped></style>
