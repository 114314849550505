<template>
    <div>
        <a-card style="min-height:90vh">
            <div>首页</div>
            <template>
                <a-skeleton />
            </template>
      </a-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  computed: {},
  methods: {},
  created() {},
};
</script>
<style lang="less" scoped>
</style>