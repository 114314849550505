import Vue from "vue";
import Antd from "ant-design-vue";
import App from "./App";
import "ant-design-vue/dist/antd.css";
import router from "./router";
import { message } from "ant-design-vue";
import moment from "moment";
import "moment/locale/zh-cn";
import "./global.css"; // global style
import VueLazyload from "vue-lazyload";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";

import VXETable from "vxe-table";
import "vxe-table/lib/style.css";
Vue.use(VXETable);

Vue.use(Viewer, {
  defaultOptions: {
    button: false,
    navbar: false,
    title: false,
    toolbar: false,
    fullscreen: true,
  },
});
Vue.use(VueLazyload);
moment.locale("zh-cn"); //设置语言 或 moment.lang('zh-cn');
Vue.prototype.$moment = moment; //挂载到当前vue实例对象
// 下拉搜索
Vue.prototype.$filterOption = function filterOption(input, option) {
  return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
Vue.prototype.$message = message;
Vue.config.productionTip = false;
Vue.prototype.$getdayTime = function (v) {
  let date = [];
  let weekOfday = parseInt(moment().format("E")); // 计算今天是这周第几天
  if (v == 1) {
    date.push(moment().subtract("days", 0).format("YYYY-MM-DD"));
    date.push(moment().subtract("days", 0).format("YYYY-MM-DD"));
  } else if (v == -1) {
    date.push(moment().subtract("days", -1).format("YYYY-MM-DD"));
    date.push(moment().subtract("days", -1).format("YYYY-MM-DD"));
  } else if (v == 2) {
    date.push(moment().subtract("days", 1).format("YYYY-MM-DD"));
    date.push(moment().subtract("days", 1).format("YYYY-MM-DD"));
  } else if (v == 3) {
    date.push(moment().subtract("days", 3).format("YYYY-MM-DD"));
    date.push(moment().subtract("days", 1).format("YYYY-MM-DD"));
  } else if (v == 4) {
    date.push(moment().subtract("days", 6).format("YYYY-MM-DD"));
    date.push(moment().subtract("days", 0).format("YYYY-MM-DD"));
  } else if (v == 5) {
    date.push(
      moment()
        .subtract(weekOfday - 1, "days")
        .format("YYYY-MM-DD")
    ); // 周一日期
    date.push(
      moment()
        .add(7 - weekOfday, "days")
        .format("YYYY-MM-DD")
    ); // 周日日期
  } else if (v == 6) {
    date.push(
      moment()
        .subtract(weekOfday + 6, "days")
        .format("YYYY-MM-DD")
    ); // 周一日期
    date.push(moment().subtract(weekOfday, "days").format("YYYY-MM-DD")); // 周日日期
  } else if (v == 7) {
    let start = moment().subtract("month", 0).format("YYYY-MM") + "-01";
    date.push(start);
    date.push(moment(start).add("month", 1).add("days", -1).format("YYYY-MM-DD"));
  } else if (v == 8) {
    let start = moment().subtract("month", 1).format("YYYY-MM") + "-01";
    date.push(start);
    date.push(moment(start).subtract("month", -1).add("days", -1).format("YYYY-MM-DD"));
  } else if (v == 9) {
    date.push(moment(moment().quarter(moment().quarter()).startOf("quarter").valueOf()).format("YYYY/MM/DD HH:mm:ss"));
    date.push(moment(moment().quarter(moment().quarter()).endOf("quarter").valueOf()).format("YYYY/MM/DD HH:mm:ss"));
  } else {
    date.push(
      moment(
        moment()
          .quarter(moment().quarter() - 1)
          .startOf("quarter")
          .valueOf()
      ).format("YYYY/MM/DD HH:mm:ss")
    );
    date.push(
      moment(
        moment()
          .quarter(moment().quarter() - 1)
          .endOf("quarter")
          .valueOf()
      ).format("YYYY/MM/DD HH:mm:ss")
    );
  }
  return date;
};

Vue.use(Antd);
new Vue({
  router,
  render: h => h(App),
}).$mount("#app");
