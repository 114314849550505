var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-card',[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.addSeller(null)}}},[_vm._v("新增商户")]),_c('a-table',{attrs:{"loading":_vm.loading,"columns":_vm.columns,"data-source":_vm.dataList,"rowKey":function (record) { return record.role_id; },"tableLayout":"fixed","pagination":{
            current: _vm.searchParams.page,
            pageSize: _vm.searchParams.limit,
            total: _vm.total,
            showLessItems: true,
            showQuickJumper: true,
            showTotal: function (total, range) { return ("总计 " + total + " 条"); },
            onChange: _vm.onPageChange,
            showSizeChanger: true,
	        pageSizeOptions: ['10', '20', '50'],
          onShowSizeChange: _vm.onPageChange,
      }},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return [_c('a',{staticStyle:{"margin-right":"10px"},on:{"click":function($event){return _vm.addSeller(record.role_id)}}},[_vm._v("编辑")]),_c('a-popconfirm',{attrs:{"title":"确定删除吗?","ok-text":"是","cancel-text":"否"},on:{"confirm":function($event){return _vm.confirm(record.role_id)}}},[_c('a',{staticClass:"error-color"},[_vm._v("删除")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }