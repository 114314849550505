<template>
  <div class="bg">
    <div class="contentbox">
      <div class="fl">
        <img src="../assets/2@2X.3bb96cf0.png" alt="">
      </div>
      <div class="fr">
        <div class="loginTitle">云果OS</div>
        <a-input size="large" type="text" style="margin:30px 0" placeholder="请输入手机号" v-model="mobile">
          <a-icon slot="prefix" type="mobile" :style="{ color: 'rgba(0,0,0,.25)' }" />
        </a-input>
        <a-input size="large" placeholder="请输入密码" v-model="password" type="password">
          <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
        </a-input>
        <a-button type="primary" @click="login" block>登录</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from '@/views/api/common'
export default {
  data() {
    return {
      mobile: '',
      password: ''
    };
  },
  computed: {},
  methods: {
      login() {
          login({mobile:this.mobile,password:this.password}).then(res=>{
            if (res.code == 200) {
              sessionStorage.setItem('access_token', res.data.user_token)
              this.$router.push('/home')
            }
          })
      }
  },
  created() {},
};
</script>

<style scoped>
.bg {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: relative;
  background-size: 100%;
}
.contentbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: #fff;
  border-radius: 2px;
  padding: 30px;
  height: 60vh;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  min-height: 400px;
}
.ant-btn {
  border-radius: 2px;
  height: 40px;
  margin-top: 20%;
}
.loginTitle {
  font-size: 24px;
  font-weight: 500;
  float: left;
}
.fl {
  position: absolute;
  left: 6%;
  top: 45%;
  transform: translateY(-50%);
  width: 42%;
}
.fr {
  position: absolute;
  right: 6%;
  top: 45%;
  transform: translateY(-50%);
  width: 35%;
}
img {
  width: 100%;
}
</style>
