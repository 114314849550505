// 公共接口
import request from "../utils/request";

// 订单列表
export async function storeOrder(data) {
  return request({
    url: "/orderStore/storeOrder",
    method: "post",
    data,
  });
}

// 改价
export async function changePrice(data) {
  return request({
    url: "/orderStore/changePrice",
    method: "post",
    data,
  });
}

// 短信列表
export async function smsOrder(data) {
  return request({
    url: "/orderSms/smsOrder",
    method: "post",
    data,
  });
}

// 调整短信
export async function smsNum(data) {
  return request({
    url: "/orderSms/smsNum",
    method: "post",
    data,
  });
}