<template>
  <div>
    <a-card>
      <a-button type="primary" @click="addUnit(1)">新增区域</a-button>
      <!-- 区域列表 -->
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="dataList"
        :rowKey="record => record.area_id"
        tableLayout="fixed"
        :pagination="false">
        <template slot="action" slot-scope="text, record">
          <a @click="addUnit(2, record)" style="margin-right: 10px">编辑</a>
          <a-popconfirm title="确定删除该区域吗?" ok-text="是" cancel-text="否" @confirm="confirm(record.area_id)">
            <a class="error-color">删除</a>
          </a-popconfirm>
        </template>
      </a-table>
    </a-card>
    <!-- 添加区域 -->
    <a-modal v-model="visible" :title="`${modalText}区域`" @ok="handleOk" @cancel="handleCancel">
      <a-form-model ref="ruleForm" :form="form" :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16, offset: 1 }">
        <a-form-model-item label="区域名称" prop="area_name">
          <a-input placeholder="请输入区域名称" v-model="form.area_name" prop="area_name" :maxLength="8" show-word-limit />
        </a-form-model-item>
        <a-form-model-item label="省份">
          <a-select placeholder="请选择省份(可多选)" mode="multiple" v-model="form.province_ids">
            <a-select-option v-for="d in province_List" :key="d.area_id">
              {{ d.area_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { SellerAreaList, SellerAreaAdd, SellerAreaEdit, SellerAreaDel } from "@/views/api/seller";
import { AreaList } from "@/views/api/common";

import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
      modalText: "",
      province_List: [],
      columns: [
        {
          title: "区域ID",
          dataIndex: "area_id",
        },
        {
          title: "区域名称",
          dataIndex: "area_name",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
        },
        {
          title: "操作",
          scopedSlots: {
            customRender: "action",
          },
        },
      ],
    };
  },
  computed: {},
  methods: {
    getList() {
      this.loading = true;
      SellerAreaList({}).then(res => {
        this.dataList = res.data;
        this.loading = false;
      });
    },

    // 省市区
    AreaList() {
      AreaList({ area_id: 0 }).then(res => {
        this.province_List = res.data;
        this.$forceUpdate();
      });
    },

    // 确认
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) return this.addEdit();
        return false;
      });
    },

    // 添加编辑
    addEdit() {
      this.flag == 1 ? SellerAreaAdd(this.form).then(res => this.handleSuccess(1, res)) : SellerAreaEdit(this.form).then(res => this.handleSuccess(2, res));
    },

    // 操作成功
    handleSuccess(flag, res) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? "添加" : flag == 2 ? "编辑" : flag == 3 ? "删除" : "修改状态"}成功`);
        this.visible = false;
        this.clearCheckForm();
        this.getList();
      }
    },

    // 清除form
    clearCheckForm() {
      this.$nextTick(() => {
        if (this.$refs.ruleForm) this.$refs.ruleForm.clearValidate();
      });
    },

    // 弹框关闭
    handleCancel() {
      this.visible = false;
      this.clearCheckForm();
    },

    // 新增区域
    addUnit(flag, row) {
      this.flag = flag;
      this.visible = true;
      if (row) {
        this.form = JSON.parse(JSON.stringify(row));
        this.form.province_ids = this.form.province_ids.map(Number)
        this.modalText = "编辑";
      } else {
        this.form = {}
        this.modalText = "新增";
      }
    },

    // 删除
    confirm(area_id) {
      SellerAreaDel({ area_id }).then(res => this.handleSuccess(3, res));
    },
  },
  created() {
    this.getList();
    this.AreaList();
  },
};
</script>
<style lang="less" scoped></style>
