<template>
  <div>
    <a-card style="min-height: 90vh; max-height: 90vh; overflow-y: scroll">
      <a-row>
        <a-col :md="7">
          <div class="ant-tree-border">
            <div class="ant-tree-group-title">图片库</div>
            <a-tree :replace-fields="replaceFields" :tree-data="treeData" default-expand-all>
              <template slot="custom" slot-scope="item">
                <div @click="clickTree(item)">
                  <span>{{ item.cate_name }}({{ item.file_num }})</span>
                  <span class="but_operation">
                    <span class="but_type" @click="append(item, 'edit')">编辑</span>
                    <span class="but_type" @click="append(item, 'addson')">新建子分组</span>
                  </span>
                </div>
              </template>
            </a-tree>
          </div>
        </a-col>

        <!-- 图片列表 -->
        <a-col :md="16">
          <div class="a-list-inner-content">
            <div style="margin-bottom: 20px">
              <a-space>
                <a-button type="primary" @click="append(1, 'new')">新增图片分组</a-button>
                <a-button type="primary" @click="showUploadModal(1)">本地上传</a-button>
              </a-space>
            </div>
            <a-row>
              <div class="antd-pages-my-files" v-if="goodsGroup.length">
                <template>
                  <a-checkbox-group class="checkbox-group">
                    <div class="a-file-item a-border-item" v-for="(item, index) in goodsGroup" :key="item.key" @mouseover="mouseover(index)" @mouseleave="mouseLeave($event)">
                      <div v-if="item.url" class="a-list-full">
                        <img style="width: 100px" slot="cover" alt="example" :src="item.url" @click="showData(item)" />
                      </div>
                      <div v-else class="a-file-full-img" @dblclick="showSon(item)"><img src="@/assets/img/file-icon.png" /></div>
                      <div class="ant-my-nowrap">{{ item.name }}</div>
                      <div :class="mouse && index == current ? 'a-file-operate a-file-operate-hover' : 'a-file-operate'">
                        <span v-if="item.url" @click="edit(item)">编辑</span>
                      </div>
                    </div>
                  </a-checkbox-group>
                </template>
              </div>
              <a-empty v-else />
            </a-row>
          </div>
        </a-col>
      </a-row>

      <!-- 上传图片 -->
      <a-modal v-model="uploadModal" title="上传图片" centered @ok="FolderFile_handel" @cancel="handleCancel" :confirm-loading="confirmLoading" width="65%">
        <a-form :form="FolderFile_form" :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }">
          <a-form-item label="选择图片分组" :wrapperCol="{ span: 8 }">
            <a-cascader
              v-model="FolderFile_form.cate_id"
              :options="treeData"
              @change="cateListOnChange"
              placeholder="请选择分类"
              :field-names="{
                label: 'cate_name',
                value: 'folder_id',
                children: 'node',
              }" />
          </a-form-item>
          <a-form-item label="商品图片">
            <div class="clearfix">
              <div class="ant-upload-list ant-upload-list-picture" @mouseleave="up_current = -1">
                <div v-for="(item, i) in fileList" :key="i" style="position: relative; height: 140px">
                  <div>
                    <div class="mb" v-if="i == up_current">
                      <a-icon type="eye" @click="handlePreview(item)" />
                      <a-icon type="delete" @click="clearflieList(i)" />
                    </div>
                    <img :src="item.url" class="flie-list" @mouseover="up_current = i" />
                    <div v-for="(item, index) in item.keywords" :key="index" style="float: left">
                      <a-input placeholder="请输入关键字" v-model.trim="item.value" style="width: 80%" @change="$forceUpdate()" />
                      <a class="error-color m-l-15" style="color: red" @click="delValue(i, index)"> 删除</a>
                    </div>
                  </div>
                  <a @click="addValue(i)" style="line-height: 32px; float: left; text-align: center; min-width: 120px">添加关键字</a>
                </div>
              </div>
              <a-upload list-type="picture-card" :file-list="[]" @preview="handlePreview" :customRequest="handleChange" :before-upload="beforeUpload">
                <div v-if="fileList.length < 10">
                  <a-icon type="plus" />
                </div>
              </a-upload>
              <!-- <div class="a-upload-desc">图片格式支持JPG,PNG,JPEG等格式,一次最多上传10张图片</div> -->
              <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </div>
          </a-form-item>
        </a-form>
      </a-modal>

      <!-- 编辑图片关键字 -->
      <a-modal v-model="editModal" title="编辑关键字" centered @ok="FolderFile_handel" @cancel="handleCancel" :confirm-loading="confirmLoading" width="65%">
        <a-form :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }">
          <a-form-item label="商品图片/关键字">
            <div class="clearfix">
              <div class="ant-upload-list ant-upload-list-picture">
                <div v-for="(item, i) in fileList" :key="i" style="position: relative; height: 140px">
                  <div>
                    <img :src="item.url" class="flie-list" />
                    <div v-for="(item, index) in item.keywords" :key="index" style="float: left">
                      <a-input placeholder="请输入关键字" v-model.trim="item.value" style="width: 80%" @change="$forceUpdate()" />
                      <a class="error-color m-l-15" style="color: red" @click="delValue(i, index)"> 删除</a>
                    </div>
                  </div>
                  <a @click="addValue(i)" style="line-height: 32px; float: left; text-align: center; min-width: 120px">添加关键字</a>
                </div>
              </div>
              <!-- <div class="a-upload-desc">图片格式支持JPG,PNG,JPEG等格式,一次最多上传10张图片</div> -->
            </div>
          </a-form-item>
        </a-form>
      </a-modal>

      <!-- 新增文件分类 -->
      <a-modal v-model="treeModal" :title="`${title_name}文件分组`" centered @ok="FolderCateAdd" @cancel="handleAddsonCancel">
        <a-form :form="FolderCate_form" :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }">
          <a-form-item label="选择文件分组">
            <a-cascader
              :disabled="select_flag"
              v-model="FolderCate_form.parent_id"
              :options="treeData"
              change-on-select
              @change="cateListOnChange"
              placeholder="不选择默认顶级分组"
              :field-names="{
                label: 'cate_name',
                value: 'folder_id',
                children: 'node',
              }" />
          </a-form-item>
          <a-form-item label="分组名称">
            <a-input placeholder="请输入分组名称" v-model="FolderCate_form.cate_name" />
          </a-form-item>
        </a-form>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import { FolderCateList, FolderFileList, FolderCateAdd, FolderCateEdit, FolderFileEdit, FolderFileAdd } from "@/views/api/system";

// 转base64
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default {
  data() {
    return {
      up_current: -1,
      select_flag: false,
      folder_id: 0,
      title_name: "",
      visible: false,
      confirmLoading: false,
      FolderFile_flag: null, // 图片编辑/新增
      // cate_id: 0,
      cate_id: 0,
      editFile_form: {},
      FolderFile_form: {
        cate_type: 1,
      },
      FolderCate_form: {
        cate_type: 1, // 1.图片 2.文件
        parent_id: [],
        cate_name: "",
      },
      expandedKeys: ["0-0-0"], //（受控）展开指定的树节点
      autoExpandParent: true, // 是否自动展开父节点
      selectedKeys: [],
      treeData: [],
      replaceFields: {
        children: "node",
        title: "cate_name",
        key: "folder_id",
      },
      searchParams: {
        page: 1,
        limit: 10,
      },
      params: {},
      goodsGroup: [],
      checkAll: false, //全选
      showBatch: true, //控制批量操作按钮显示状态
      ifShowState: false, //批量操作(控制多选按钮显示状态)
      uploadModal: false, //上传图片 取消按钮
      previewVisible: false, //图片预览模态框
      previewImage: "", //图片预览
      fileList: [],
      treeModal: false, //新增子分类模态框
      mouse: false,
      current: 0,
      showMb: false,
      masData: "",
      edituploadModal: false, //编辑图片 取消按钮
      img_item: {},
      errorImg: "",
      editModal: false,
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  created() {
    this.FolderCateList();
    this.FolderFileList(0);
  },
  methods: {
    // 图片分类列表
    FolderCateList() {
      FolderCateList({}).then(res => this.addSlots(res.data));
    },

    // 图片列表
    FolderFileList(cate_id) {
      FolderFileList({ cate_id }).then(res => (this.goodsGroup = res.data));
    },

    addSlots(treeData) {
      treeData.forEach(item => {
        item.scopedSlots = { title: "custom" };
        if (item.node) this.addSlots(item.node);
      });
      this.treeData = treeData;
    },

    // 分类列表选择
    cateListOnChange(value) {
      this.FolderFile_form.cate_id.push(value[value.length - 1]);
    },

    // 创建--文件分类
    FolderCateAdd() {
      const { cate_name, parent_id, folder_id, flag } = this.FolderCate_form;
      if (flag == "edit") {
        FolderCateEdit({ folder_id: folder_id, cate_name: cate_name, cate_type: 1, parent_id: parent_id[parent_id.length - 1] }).then(res => this.handel_success(res, 2));
      } else {
        FolderCateAdd({ cate_name: cate_name, cate_type: 1, parent_id: parent_id[parent_id.length - 1] }).then(res => this.handel_success(res, 1));
      }
    },

    // 操作成功
    handel_success(res, flag) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? "添加" : flag == 2 ? "编辑" : "删除"}成功`);
        this.fileList = [];
        this.treeModal = false;
        this.uploadModal = false;
        this.editModal = false;
        this.FolderFileList(this.folder_id);
        this.FolderCateList();
      }
    },

    // 文件夹双击
    showSon(item) {
      console.log(item);
      this.selectedKeys = item.parent_ids.map(Number);
      this.FolderFile_form.cate_id = item.parent_ids.map(Number);
      this.$forceUpdate();
      this.FolderFileList(item.folder_id);
    },

    // 文件夹新增子分组
    append(item, handel_flag) {
      this.FolderCate_form.cate_name = "";
      if (handel_flag == "edit") {
        this.title_name = "编辑";
        this.select_flag = true;
      } else {
        this.title_name = "新增";
        this.select_flag = false;
      }
      if (handel_flag == "addson") {
        this.FolderCate_form.parent_id = item.parent_ids.map(Number);
        console.log(this.FolderCate_form.parent_id);
        this.FolderCate_form.flag = "add";
        this.FolderCate_form.folder_id = item.folder_id;
      } else if (handel_flag == "edit") {
        this.FolderCate_form.parent_id = item.parent_ids.map(Number).slice(0, -1);
        this.FolderCate_form.flag = "edit";
        this.FolderCate_form.cate_name = item.cate_name;
        this.FolderCate_form.folder_id = item.folder_id;
      } else if (handel_flag == "new") {
        this.FolderCate_form.parent_id = [];
        this.FolderCate_form.folder_id;
        this.FolderCate_form.flag = "add";
      }
      this.$forceUpdate();
      this.treeModal = true;
    },

    // 新增/编辑文件
    FolderFile_handel() {
      this.FolderFile_flag == 1 ? this.FolderFileAdd() : this.FolderFileEdit();

    },

    // 编辑按钮
    edit(item) {
      console.log(item);
      var keywords = [];
      if (item.keywords) {
        item.keywords.forEach(itemm => {
          keywords.push({ value: itemm });
        });
      }
      this.fileList[0] = {};
      this.fileList[0].keywords = keywords;
      this.fileList[0].url = item.url;
      this.fileList[0].name = item.name;
      this.editFile_form.file_id = item.file_id;
      this.editFile_form.cate_id = item.cate_id;
      this.FolderFile_flag = 2;
      this.editModal = true;
    },

    // 上传编辑图片
    FolderFileEdit() {
      const { file_id } = this.editFile_form;
      var addArr = [];
      console.log(this.fileList);
      this.fileList.forEach((item, i) => {
        var obj = {};
        obj.name = item.name;
        obj.url = item.url;
        obj.keywords = [];
        item.keywords.forEach(itemm => {
          obj.keywords.push(itemm.value);
        });
        addArr[i] = obj;
      });
      FolderFileEdit({
        keywords: addArr[0].keywords,
        file_id: file_id,
      }).then(res => this.handel_success(res, 2));
    },

    // 上传图片
    FolderFileAdd() {
      if (!this.FolderFile_form.cate_id) return this.$message.error("请选择分类!");
      var addArr = [];
      this.confirmLoading = true;
      this.fileList.forEach((item, i) => {
        var obj = {};
        obj.name = item.name;
        obj.url = item.url;
        obj.keywords = [];
        item.keywords.forEach(itemm => {
          obj.keywords.push(itemm.value);
        });
        addArr[i] = obj;
      });
      const params = {
        files: addArr,
        type: 1,
        cate_id: this.FolderFile_form.cate_id[this.FolderFile_form.cate_id.length - 1],
      };
      FolderFileAdd(params)
        .then(res => {
          this.confirmLoading = false;
          this.handel_success(res, 1);
          this.FolderFileList(this.folder_id);
          if (res.code == 400) this.confirmLoading = false;
        })
        .catch(() => (this.confirmLoading = false));
    },

    // 变更
    // handleChange({ fileList }) {
    //   console.log(fileList);
    //   this.fileList = fileList;
    // },

    // 上传前钩子
    beforeUpload(file) {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("只能上传jpeg及png文件!");
      }
      return isJpgOrPng;
    },

    // customRequest() {
    // },

    // 展开/收起节点时触发
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },

    // 上传图片按钮
    showUploadModal() {
      this.FolderFile_form = {}
      this.fileList = []
      this.FolderFile_flag = 1
      // if (num == 2) this.FolderFile_form.cate_id = [item.cate_id];
      // this.editFile_form = item;
      // num == 1 ? (this.FolderFile_flag = 1) : (this.FolderFile_flag = 2);
      this.uploadModal = true;
    },

    // 预览图片右上角关闭按钮
    handleCancel() {
      this.previewVisible = false;
    },

    // 预览图片
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        console.log(file);
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },

    // 鼠标移入事件
    mouseover(index) {
      this.mouse = true;
      this.current = index;
    },

    // 鼠标移出事件
    mouseLeave() {
      this.mouse = false;
      this.current = null;
    },

    // 新增子分组关闭
    handleAddsonCancel() {
      this.treeModal = false;
    },

    // 点击左侧菜单
    clickTree(e) {
      this.selectedKeys = e.parent_ids;
      this.FolderFile_form.cate_id = e.parent_ids.map(Number);
      this.FolderCate_form.parent_id = e.parent_ids.map(Number).slice(0, -1);
      this.FolderFileList(e.folder_id);
      this.folder_id = e.folder_id;
    },

    // 添加规格值
    addValue(i) {
      this.fileList[i].keywords.push({ value: "" });
      this.$forceUpdate();
    },

    // 删除规格值
    delValue(ind, index) {
      console.log(this.fileList);
      this.fileList[ind].keywords = this.fileList[ind].keywords.filter((item, i) => i != index);
      this.$forceUpdate();
    },

    clearflieList(index) {
      this.fileList = this.fileList.filter((item, i) => i != index);
      console.log(this.fileList);
    },

    handleChange({ file }) {
      console.log(file);
      // // 添加地址到数组
      getBase64(file, async imageUrl => {
        var params = { name: file.name, url: imageUrl, keywords: [{ value: "" }] };
        console.log(params);

        this.$nextTick(() => {
          this.fileList.push(params);
          if (this.fileList.length > 10) {
            this.fileList = JSON.parse(JSON.stringify(this.fileList.splice(0, 10)));
            console.log(this.fileList);
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.viewer-canvas {
  width: 100% !important;
  height: 100% !important;
}
.imgBox {
  position: relative;
  width: 120px;
  height: 120px;
  img {
    height: 120px;
    width: 120px;
    border-radius: 3px;
    /deep/:hover {
      cursor: pointer;
    }
  }
}
.ant-my-nowrap {
  position: absolute;
  bottom: 0;
  width: 120px;
  text-align: center;
  background-color: #f4f6fa;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
}
/deep/.ant-tree-node-content-wrapper {
  width: 90%;
}
.ant-tree-title {
  width: 100%;
}
.title {
  float: left;
}
.ant-tree-group-title {
  background: #f4f6fa;
  line-height: 42px;
  padding-left: 20px;
}
.ant-tree-border {
  border: 1px solid #f5f5f5;
}
.ant-card-body {
  .ant-tree {
    line-height: 2;
    li {
      position: relative;
    }
  }
}
.a-list-inner-content {
  width: 96%;
  margin: 0 auto;
}

.a-file-item-previewCover {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.3s;
  img {
    display: block;
    width: auto;
    height: auto;
    // max-width: 50%;
    // max-height: 50%;
  }
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
.a-upload-desc {
  line-height: 20px;
  font-size: 12px;
}
.but_type {
  float: right;
  color: #1890ff;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
}

/deep/ .ant-form-item-label {
  text-align: center;
}
.bg_box {
  float: left;
  margin: 0 10px 0 0;
  cursor: pointer;
}
.active {
  border: 3px solid #3bd4b7;
}
.else_active {
  border: 3px solid #fff;
}

.ant-upload-list {
  float: left;
  display: inline-block;
}
/deep/.ant-upload-picture-card-wrapper {
  display: block;
  width: 100px;
}
/deep/.anticon-eye {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(-120%, -50%);
  font-size: 18px;
}
/deep/.anticon-delete {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(40%, -50%);
  font-size: 16px;
}
.flie-list {
  float: left;
  width: 100px;
  height: 100px;
  border-radius: 4px;
  margin: 0 10px 10px 0;
}
.flie-list:hover {
  width: 100px;
  height: 100px;
  border-radius: 4px;
  margin-right: 10px;
}
.mb {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
  width: 100px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.46);
  cursor: pointer;
}

.clearfix {
  display: inline-block;
}

.ant-tree-title {
  width: 100%;
}
.title {
  float: left;
}
.ant-tree-group-title {
  background: #f4f6fa;
  line-height: 42px;
  padding-left: 20px;
}
.ant-tree-border {
  border: 1px solid #f5f5f5;
}
.ant-card-body {
  .ant-tree {
    line-height: 2;
    li {
      position: relative;
    }
  }
}
.a-list-inner-content {
  width: 96%;
  margin: 0 auto;
}
.antd-pages-my-files {
  .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    .a-border-item {
      border: 1px solid #f4f6fa;
    }
    .a-file-item {
      background: #fff;
      position: relative;
      text-align: center;
      width: 140px;
      height: 140px;
      overflow: hidden;
      margin: 8px 16px 8px 0;
      border-radius: 4px;
      .a-file-full-img {
        width: 100%;
        margin-top: 14px;
        img {
          width: 65%;
        }
      }
      .a-list-full {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .ant-my-nowrap {
        white-space: nowrap;
        overflow: hidden;
        padding: 2px 0;
        background: #f4f6fa;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;
      }
      .a-file-operate {
        background: rgba(0, 0, 0, 0.6);
        position: absolute;
        bottom: -26px;
        width: 100%;
        color: #fff;
        display: flex;
        line-height: 26px;
        transition: all 0.3s;
        cursor: pointer;
        span {
          flex: 1;
        }
      }
      .a-file-operate-hover {
        bottom: 25px;
      }
    }
  }
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
.a-upload-desc {
  line-height: 20px;
  font-size: 12px;
}
.isActive {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(34, 34, 34, 0.5);
  width: 100%;
  height: 100%;
  text-align: center;
  .anticon {
    line-height: 120px;
    font-size: 36px;
    color: #00b5a1;
  }
}

/deep/.ant-empty {
  margin-top: 200px;
}
</style>
