<template>
  <div>
    <a-card>
      <a-button type="primary" @click="addWxPath(1)">新增导航路径</a-button>
      <!-- 导航路径列表 -->
      <a-table :loading="loading" :columns="columns" :data-source="dataList" :rowKey="(record) => record.path_id" tableLayout="fixed" :pagination="{
            current: searchParams.page,
            pageSize: searchParams.limit,
            total: total,
            showLessItems: true,
            showQuickJumper: true,
            showTotal: (total, range) => `总计 ${total} 条`,
            onChange: onPageChange,
            showSizeChanger: true,
	          pageSizeOptions: ['10', '20', '50'],
            onShowSizeChange: onPageChange,
      }">
        <template slot="action" slot-scope="text, record">
          <a @click="addWxPath(2,record)" style="margin-right:10px;">编辑</a>
          <a-popconfirm title="确定删除该导航路径吗?" ok-text="是" cancel-text="否" @confirm="confirm(record.path_id)">
            <a class="error-color">删除</a>
          </a-popconfirm>
        </template>
      </a-table>
    </a-card>
    <!-- 添加导航路径 -->
    <a-modal v-model="visible" :title='`${modalText}导航路径`' @ok="handleOk" @cancel="handleCancel">
      <a-form-model ref="ruleForm" :form="form" :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16, offset: 1 }">
        <a-form-model-item label="导航路径名称" prop='name'>
          <a-input placeholder="请输入导航路径名称" v-model="form.name" show-word-limit />
        </a-form-model-item>
        <a-form-model-item label="路径" prop='path'>
          <a-input placeholder="请输入路径" v-model="form.path" show-word-limit />
        </a-form-model-item>
        <a-form-model-item label="类型">
          <a-select placeholder="请选择类型" v-model="form.path_type">
            <a-select-option value="navigateTo">navigateTo</a-select-option>
            <a-select-option value="redirectTo">redirectTo</a-select-option>
            <a-select-option value="switchTab">switchTab</a-select-option>
            <a-select-option value="reLaunch">reLaunch</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { WxPathList,WxPathAdd,WxPathEdit,WxPathDel } from '@/views/api/system'
import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
      form: {
        status: 0
      },
      modalText: '',
      columns: [
      {
        title: '导航路径ID',
        dataIndex: 'path_id',
      },
      {
        title: '导航路径名称',
        dataIndex: 'name',
      },
      {
        title: '路径',
        dataIndex: 'path',
      },
      {
        title: '类型',
        dataIndex: 'path_type',
      },
      {
        title: '操作',
        scopedSlots: {
          customRender: 'action'
        }
      }],
    };
  },
  computed: {},
  methods: {
    getList() {
      this.loading = true
      WxPathList(this.searchParams).then(res=>{
        this.dataList = res.data.list
        this.total = res.data.count
        this.loading = false
      })
    },
    // 确认
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) return this.addEdit();
        return false;
      });
    },
    // 添加编辑
    addEdit() {
      this.flag == 1 ? WxPathAdd(this.form).then((res) => this.handleSuccess(1,res)) : WxPathEdit(this.form).then((res) => this.handleSuccess(2,res));
    },
    // 操作成功
    handleSuccess(flag,res) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? '添加' : flag == 2 ? '编辑' : flag == 3 ? '删除' : '修改状态'}成功`);
        this.visible = false;
        this.getList()
      }
    },
    // 弹框关闭
    handleCancel() {
      this.visible = false;
    },
    // 新增导航路径
    addWxPath(flag,row) {
      this.form = {}
      this.flag = flag;
      this.visible = true;
      if (row) {
        this.form = JSON.parse(JSON.stringify(row));
        this.modalText = "编辑";
      } else {
        this.form.status = 1
        this.modalText = "新增";
      }
    },
    // 删除
    confirm(path_id) {
      WxPathDel({ path_id }).then((res) => this.handleSuccess(3,res));
    },
    // 弹框启用状态
    statusChange(checked) {
      this.$forceUpdate()
      checked ? this.form.status = 1 : this.form.status = 0
    },
  },
  created() {
    this.getList()
  },
};
</script>
<style lang="less" scoped>
</style>