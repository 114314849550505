<template>
  <div>
    <a-card>
      <div class="title">{{ params ? "编辑" : "添加" }}版本</div>
      <!-- 添加版本升级 -->
      <a-form-model ref="ruleForm" :form="form" :model="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 8, offset: 1 }">
        <a-form-model-item label="版本号" prop="version_code">
          <a-input placeholder="请输入版本号" v-model="form.version_code" />
        </a-form-model-item>
        <a-form-model-item label="升级内容" prop="update_desc">
          <a-textarea placeholder="请输入升级内容" v-model="form.update_desc" />
        </a-form-model-item>
        <a-form-model-item label="小程序模板ID" prop="template_id">
          <a-input placeholder="请输入小程序模板ID" v-model="form.template_id" />
        </a-form-model-item>
        <a-form-model-item label="发布类型">
          <a-radio-group v-model="publish_type" @change="typeChange">
            <a-radio :value="0">不限制</a-radio>
            <a-radio :value="1">发布区域</a-radio>
            <a-radio :value="2">发布商户</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-if="publish_type == 1" label="指定区域" prop="publish_area" :wrapper-col="{ span: 10, offset: 1 }">
          <a-checkbox-group v-model="form.publish_area">
            <span v-for="item in area_list" :key="item.area_id">
              <a-checkbox :value="item.area_id" style="margin: 10px 15px 10px 0">
                {{ item.area_name }}
              </a-checkbox>
            </span>
          </a-checkbox-group>
        </a-form-model-item>
        <a-form-model-item v-if="publish_type == 2" label="指定商户" prop="publish_seller">
          <a-input placeholder="请点击选择/查看" show-word-limit @click="showModal" />
        </a-form-model-item>
        <a-form-model-item label="hide" prop="seller_id" ref="seller_id" class="hide" v-if="sellerSource.length > 0">
          <a-table bordered :data-source="sellerSource" :columns="seller_columns" :pagination="false" :scroll="{ y: 266 }">
            <template slot="action" slot-scope="text, record">
              <a-popconfirm v-if="sellerSource.length" title="是否确认删除?" @confirm="() => sellerOnDelete(record.seller_id)">
                <a href="javascript:;">删除</a>
              </a-popconfirm>
            </template>
          </a-table>
        </a-form-model-item>
        <a-form-model-item label="版本类型">
          <a-radio-group v-model="form.type">
            <a-radio :value="1">企微插件会员版</a-radio>
            <a-radio :value="2">定位版</a-radio>
            <a-radio :value="4">会员版(定位)</a-radio>
            <a-radio :value="8">全能版</a-radio>
            <a-radio :value="16">乐田专享版</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="启用状态">
          <a-radio-group v-model="form.status">
            <a-radio :value="0">禁用</a-radio>
            <a-radio :value="1">启用</a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-card>

    <a-card class="m-t-10">
      <a-row>
        <a-col :offset="3">
          <a-button type="primary" @click="handleOk" :loading="loading">提交</a-button>
          <a-button style="margin-left: 15px" @click="back()">取消</a-button>
        </a-col>
      </a-row>
    </a-card>

    <seller-modal ref="sellerModal" @func="getsellerFormSon"></seller-modal>
  </div>
</template>

<script>
import { VersionXcxadd, VersionXcxedit, VersionXcxinfo } from "@/views/api/system";
import { SellerAreaList } from "@/views/api/seller";
import sellerModal from "./sellerModal";

export default {
  components: { sellerModal },
  data() {
    return {
      visible: false,
      loading: false,
      params: "",
      form: {
        publish_area: [],
        publish_seller: [],
        status: 1,
        force_update: 1,
      },
      unit_list: [],
      url: "",
      area_list: [],
      key: [],
      file: [],
      publish_type: 0,
      sellerSource: [],
      seller_columns: [
        {
          title: "商户ID",
          dataIndex: "seller_id",
        },
        {
          title: "商户名称",
          dataIndex: "seller_name",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  computed: {},
  methods: {
    SellerAreaList() {
      SellerAreaList({}).then(res => {
        this.area_list = res.data;
      });
    },

    VersionXcxinfo() {
      VersionXcxinfo({ version_id: this.version_id }).then(res => {
        this.form = res.data;
        this.form.publish_area = res.data.publish_area.map(Number);
        this.form.publish_seller = res.data.publish_seller.map(Number);
        this.form.publish_area.length > 0 ? (this.publish_type = 1) : this.form.publish_seller.length > 0  ? (this.publish_type = 2) : this.publish_type = 0;
        this.key = res.data.publish_seller.map(Number);
        if (res.data.seller_list && res.data.seller_list.length > 0) this.sellerSource = res.data.seller_list;
      });
    },

    // 取消 - 返回
    back() {
      this.$router.go(-1);
    },

    typeChange() {
      if (this.publish_type == 0) {
        this.form.publish_area = [];
        this.form.publish_seller = [];
        this.sellerSource = [];
        this.key = [];
        this.form.publish_seller = [];
      }
      if (this.publish_type == 1) {
        this.form.publish_seller = [];
        this.sellerSource = [];
        this.key = [];
        this.form.publish_seller = [];
      } else {
        this.form.publish_area = [];
      }
    },

    // 添加编辑
    handleOk() {
      // if (this.area_list.length == this.form.publish_area.length) this.form.publish_area = [];
      // if (this.publish_type == 0)  {
      //   this.form.publish_area = []
      //   this.form.publish_seller = []
      // }
      this.flag == 1 ? VersionXcxadd(this.form).then(res => this.handleSuccess(1, res)) : VersionXcxedit(this.form).then(res => this.handleSuccess(2, res));
    },

    // 操作成功
    handleSuccess(flag, res) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? "添加" : flag == 2 ? "编辑" : ""}成功`);
        this.visible = false;
        this.back();
      }
    },

    // 显示商户组件
    showModal() {
      this.$refs.sellerModal.childMethod(this.key);
    },

    getsellerFormSon(row, key) {
      console.log(row, key);
      this.sellerSource = row;
      this.key = key;
      this.form.publish_seller = key;
    },

    sellerOnDelete(key) {
      this.form.publish_seller = this.form.publish_seller.filter(item => item != key);
      this.key = this.key.filter(item => item != key);
      this.sellerSource = this.sellerSource.filter(item => item.seller_id != key);
      this.$forceUpdate();
    },

    before() {
      return true;
    },

    remove() {
      return false;
    },
  },
  created() {
    this.SellerAreaList();
    if (this.$route.query.version_id) {
      this.flag = 2;
      this.version_id = this.$route.query.version_id;
      this.VersionXcxinfo();
    } else {
      this.flag = 1;
    }
  },
};
</script>

<style lang="less" scoped>
.ant-form-item-label {
  text-align: center !important;
}
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 4px;
}
.pic {
  width: 150px;
  height: 150px;
}
/deep/.anticon-delete {
  display: none;
}
/deep/.ant-upload-list-text {
  display: none;
}
/deep/ .hide {
  label {
    display: none;
  }
}
</style>
