var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-card',[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.addbank(1)}}},[_vm._v("新增开户行")]),_c('a-table',{attrs:{"loading":_vm.loading,"columns":_vm.columns,"data-source":_vm.dataList,"rowKey":function (record) { return record.bank_id; },"tableLayout":"fixed","pagination":{
            current: _vm.searchParams.page,
            pageSize: _vm.searchParams.limit,
            total: _vm.total,
            showLessItems: true,
            showQuickJumper: true,
            showTotal: function (total, range) { return ("总计 " + total + " 条"); },
            onChange: _vm.onPageChange,
            showSizeChanger: true,
	          pageSizeOptions: ['10', '20', '50'],
            onShowSizeChange: _vm.onPageChange,
      }},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return [_c('a',{staticStyle:{"margin-right":"10px"},on:{"click":function($event){return _vm.addbank(2,record)}}},[_vm._v("编辑")]),_c('a-popconfirm',{attrs:{"title":"确定删除该开户行吗?","ok-text":"是","cancel-text":"否"},on:{"confirm":function($event){return _vm.confirm(record.bank_id)}}},[_c('a',{staticClass:"error-color"},[_vm._v("删除")])])]}}])})],1),_c('a-modal',{attrs:{"title":(_vm.modalText + "开户行")},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-form-model',{ref:"ruleForm",attrs:{"form":_vm.form,"model":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 16, offset: 1 }}},[_c('a-form-model-item',{attrs:{"label":"开户行名称","prop":"bank_name"}},[_c('a-input',{attrs:{"placeholder":"请输入开户行名称","show-word-limit":""},model:{value:(_vm.form.bank_name),callback:function ($$v) {_vm.$set(_vm.form, "bank_name", $$v)},expression:"form.bank_name"}})],1),_c('a-form-model-item',{attrs:{"label":"启用状态"}},[(_vm.visible)?_c('a-switch',{attrs:{"checked":_vm.form.status == 0 ? false : true},on:{"change":_vm.statusChange}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }