<template>
    <div>
        <a-card>
            <a-button type="primary" @click="addbank(1)">新增开户行</a-button>
            <!-- 开户行列表 -->
            <a-table :loading="loading" :columns="columns" :data-source="dataList" :rowKey="(record) => record.bank_id" tableLayout="fixed" :pagination="{
            current: searchParams.page,
            pageSize: searchParams.limit,
            total: total,
            showLessItems: true,
            showQuickJumper: true,
            showTotal: (total, range) => `总计 ${total} 条`,
            onChange: onPageChange,
            showSizeChanger: true,
	          pageSizeOptions: ['10', '20', '50'],
            onShowSizeChange: onPageChange,
      }">
                <template slot="action" slot-scope="text, record">
                    <a @click="addbank(2,record)" style="margin-right:10px;">编辑</a>
                    <a-popconfirm title="确定删除该开户行吗?" ok-text="是" cancel-text="否" @confirm="confirm(record.bank_id)">
                        <a class="error-color">删除</a>
                    </a-popconfirm>
                </template>
            </a-table>
        </a-card>
        <!-- 添加开户行 -->
        <a-modal v-model="visible" :title='`${modalText}开户行`' @ok="handleOk" @cancel="handleCancel">
            <a-form-model ref="ruleForm" :form="form" :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16, offset: 1 }">
                <a-form-model-item label="开户行名称" prop='bank_name'>
                    <a-input placeholder="请输入开户行名称" v-model="form.bank_name" show-word-limit />
                </a-form-model-item>
                <a-form-model-item label="启用状态">
                    <a-switch v-if="visible" :checked="form.status == 0 ? false : true" @change="statusChange" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import { bankList,bankAdd,bankEdit,bankDel } from '@/views/api/system'
import { mixins } from "../../mixin/index";
export default {
  mixins: [mixins],
  components: {},
  data() {
    return {
      form: {
        status: 0
      },
      modalText: '',
      columns: [
      {
        title: '开户行ID',
        dataIndex: 'bank_id',
      },
      {
        title: '开户行名称',
        dataIndex: 'bank_name',
      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
      },
      {
        title: '状态',
        dataIndex: 'status',
        customRender: (text) => {
        return text == 1 ? "开启" : '关闭';
        },
      },
      {
        title: '操作',
        scopedSlots: {
          customRender: 'action'
        }
      }],
    };
  },
  computed: {},
  methods: {
    getList() {
      this.loading = true
      bankList(this.searchParams).then(res=>{
        this.dataList = res.data
        this.loading = false
      })
    },
    // 确认
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) return this.addEdit();
        return false;
      });
    },
    // 添加编辑
    addEdit() {
      this.flag == 1 ? bankAdd(this.form).then((res) => this.handleSuccess(1,res)) : bankEdit(this.form).then((res) => this.handleSuccess(2,res));
    },
    // 操作成功
    handleSuccess(flag,res) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? '添加' : flag == 2 ? '编辑' : flag == 3 ? '删除' : '修改状态'}成功`);
        this.visible = false;
        this.getList()
      }
    },
    // 弹框关闭
    handleCancel() {
      this.visible = false;
    },
    // 新增开户行
    addbank(flag,row) {
      this.form = {}
      this.flag = flag;
      this.visible = true;
      if (row) {
        this.form = JSON.parse(JSON.stringify(row));
        this.modalText = "编辑";
      } else {
        this.form.status = 1
        this.modalText = "新增";
      }
    },
    // 删除
    confirm(bank_id) {
      bankDel({ bank_id }).then((res) => this.handleSuccess(3,res));
    },
    // 弹框启用状态
    statusChange(checked) {
      this.$forceUpdate()
      checked ? this.form.status = 1 : this.form.status = 0
    },
  },
  created() {
    this.getList()
  },
};
</script>
<style lang="less" scoped>
</style>