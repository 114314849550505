<template>
  <div>
    <a-card>
      <div class="title">{{ params ? "编辑" : "添加" }}商品</div>
      <a-form-model
        ref="ruleForm"
        :form="form"
        :model="form"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 8, offset: 1 }">
        <a-form-model-item label="商品名称" prop="goods_name">
          <a-input placeholder="请输入商品名称" v-model="form.goods_name" prop="goods_name" />
        </a-form-model-item>
        <a-form-model-item label="商品图片" prop="goods_img">
          <img v-if="url" :src="url" class="pic" />
          <div><a-button @click="showimgbox" type="primary">选择/更换图片</a-button></div>
        </a-form-model-item>
        <a-form-model-item label="售卖单位" prop="unit_id">
          <a-select placeholder="请选择单位" v-model="form.unit_id">
            <a-select-option v-for="d in unit_list" :key="d.unit_id">
              {{ d.unit_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="销售价" prop="sale_price">
          <a-input placeholder="请输入销售价" v-model="form.sale_price" prop="sale_price" />
        </a-form-model-item>
        <a-form-model-item label="成本价" prop="cost_price">
          <a-input placeholder="请输入成本价" v-model="form.cost_price" prop="cost_price" />
        </a-form-model-item>
        <a-form-model-item label="会员价" prop="vip_price">
          <a-input placeholder="请输入会员价" v-model="form.vip_price" prop="vip_price" />
        </a-form-model-item>
        <a-form-model-item label="商品条码" prop="barcode">
          <a-input placeholder="请输入商品条码" v-model="form.barcode" prop="barcode" />
        </a-form-model-item>
        <a-form-model-item label="商品状态" prop="status">
          <a-switch :checked="form.status == 1 ? true : false" @change="statusChange()" />
        </a-form-model-item>
      </a-form-model>
    </a-card>

    <a-card class="m-t-10">
      <a-row>
        <a-col :offset="3">
          <a-button type="primary" @click="addEdit">提交</a-button>
          <a-button style="margin-left: 15px" @click="back()">取消</a-button>
        </a-col>
      </a-row>
    </a-card>

    <a-modal
      v-model="visible"
      title="商品图库"
      @ok="handleOk"
      width="70%"
      :dialog-style="{ top: '20px' }"
      style="max-height: 500px">
      <folder @func="getSonMsg"></folder>
    </a-modal>
  </div>
</template>

<script>
import { goodsAdd, goodsEdit, getGoodsUnitName, goodsDetail } from "@/views/api/seller";
import folder from "./folder.vue";

export default {
  components: { folder },
  data() {
    return {
      visible: false,
      params: "",
      form: {},
      unit_list: [],
      url: "",
    };
  },
  computed: {},
  methods: {
    // 详情
    goodsDetail() {
      const params = { goods_id: this.$route.query.goods_id };
      goodsDetail(params).then(res => {
        this.form = res.data;
        this.url = res.data.goods_img_url;
      });
    },

    statusChange() {
      this.form.status == 1 ? (this.form.status = 0) : (this.form.status = 1);
    },

    getGoodsUnitName() {
      getGoodsUnitName({}).then(res => {
        this.unit_list = res.data;
      });
    },

    // 取消 - 返回
    back() {
      this.$router.go(-1);
    },

    // 添加编辑
    addEdit() {
      this.goods_id
        ? goodsEdit(this.form).then(res => this.handleSuccess(1, res))
        : goodsAdd(this.form).then(res => this.handleSuccess(2, res));
    },

    // 操作成功
    handleSuccess(flag, res) {
      if (res.code == 200) {
        this.$message.success(`${flag == 1 ? "编辑" : "添加"}成功`);
        this.back();
      }
    },

    showimgbox() {
      this.visible = true;
    },

    handleOk() {
      this.visible = false;
    },

    getSonMsg(data) {
      this.url = data.url;
      this.form.goods_img = data.file_id;
    },
  },
  created() {
    this.getGoodsUnitName();
    this.goods_id = this.$route.query.goods_id;
    if (this.$route.query.goods_id) {
      this.goodsDetail();
    }
  },
};
</script>
<style scoped>
.ant-form-item-label {
  text-align: center !important;
}
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 4px;
}
.pic {
  width: 150px;
  height: 150px;
}
</style>
